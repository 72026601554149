import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../../../context/useAuth';
import { SERVER_URL } from '../../../../config/index';
import Table from './Table';
import Loading from '../../../../components/loading/Loading';
import { Main, Section } from '../../../../components/content';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import { useParams } from 'react-router-dom';

const UserSearchLogs = () => {
  const { user } = useAuth();
  const { userId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const limitPage = 10;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTableData = async () => {
      const { token } = user;
      setLoading(true);

      try {
        const response = await axios.get(
          `${SERVER_URL}/${AXIOS_API_CALL.searchByUser}/${userId}/${currentPage}/${limitPage}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        setList(response?.data?.searchLog);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getTableData();
  }, [user, userId, currentPage]);

  // Table Pagination
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pageCount: list?.searchListTotalPages,
    totalCount: list?.searchListLength
  };

  return (
    <>
      {list?.userId && (
        <h2 className="page-title">
          Pretrage korniska {list?.userId?.firstName} {list?.userId?.lastName}
          {list?.userId?.companyName}
        </h2>
      )}

      <Section className="section section__wrapper section__employees">
        <Main className="section__content relative min-h-table-content">
          {loading ? (
            <Loading />
          ) : (
            <Table data={list.searchList} pagination={paginationProps} />
          )}
        </Main>
      </Section>
    </>
  );
};

export default UserSearchLogs;
