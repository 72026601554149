import { Input, Form, Button, notification, Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import { SERVER_URL } from '../../../../config/index';
import { useAuth } from '../../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { PERMISSIONS } from '../../../../utils/permissions';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';

const UpdateBlogForm = ({ data }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  const initialValues = data;

  const [desc, setDescription] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blogImageId, setBlogImageId] = useState(
    data && data.image ? data.image._id : null
  );
  const [blogImage, setBlogImage] = useState(
    data && data.image ? data.image : null
  );
  const [images, setImages] = useState([]);

  const getTextValue = value => {
    if (value !== desc) {
      setDescription(value);
    }
  };

  const handleSelectImage = async item => {
    try {
      setBlogImageId(item._id);
      setBlogImage(item);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async values => {
    values.desc = desc;

    if (blogImageId) {
      values.image = blogImageId;
    }

    await Axios.put(`${SERVER_URL}/blog/${data._id}`, values, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    notification.success({
      message: 'Blog is updated.',
      placement: 'topRight'
    });

    navigate('/admin/grocery/blogs');
  };

  const fetchImages = async () => {
    await Axios.get(`${SERVER_URL}/images/`, {
      withCredentials: false,
      headers: {
        department: PERMISSIONS.grocery,
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      if (res.status === 200) {
        setImages(res?.data);
      }
    });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleDeleteSelectedImage = () => {
    setBlogImage(null);
    setBlogImageId(null);
  };

  return (
    <div>
      <Form
        initialValues={initialValues}
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
          AŽURIRAJ BLOG
        </h2>

        <div
          className="nav-links"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px'
          }}
        >
          <p>AŽURIRAJ BLOG</p>
          <Link to="/admin/grocery/blogs">SVI BLOGOVI</Link>
        </div>

        <div className="blog-row">
          <label>Naslov:</label>
          <Form.Item name="title">
            <Input
              type="text"
              placeholder="title"
              className="input-blog-page"
            />
          </Form.Item>
        </div>

        <div className="blog-row">
          <label>Meta Naslov:</label>
          <Form.Item name="metaTitle">
            <Input
              type="text"
              placeholder="metaTitle"
              className="input-blog-page"
            />
          </Form.Item>
        </div>

        <div className="blog-row">
          <label>Meta Opis:</label>
          <Form.Item name="metaDesc">
            <Input
              type="text"
              placeholder="metaDesc"
              className="input-blog-page"
            />
          </Form.Item>
        </div>

        <div className="blog-row">
          <label>Odaberi Sliku:</label>
          <Form.Item name="image">
            <Button
              style={{ minHeight: '10px', borderRadius: '10px' }}
              onClick={() => setIsModalVisible(true)}
              type="primary"
            >
              Dodaj Sliku
            </Button>
          </Form.Item>
        </div>

        <div className="selected-blogimage-container">
          {blogImage && blogImage.url && blogImage.originalname && (
            <div
              onClick={handleDeleteSelectedImage}
              className="blogimage-container"
            >
              <label>Odabrana Slika:</label>
              <img
                className="blogimage"
                src={blogImage.url}
                alt={blogImage.originalname}
              />
              <p className="blogimage-deleteicon">
                <DeleteOutlined />
              </p>
            </div>
          )}
        </div>

        <div className="blog-row">
          <label>Sadržaj: </label>
          <Form.Item name="desc">
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
              onEditorChange={(newValue, editor) =>
                getTextValue(editor.getContent())
              }
              init={{
                height: 600,
                menubar: true,
                plugins: 'table'
              }}
            />
          </Form.Item>
        </div>

        <Modal
          width="50%"
          title="Select image from gallery"
          open={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          className="image-gallery-modal"
        >
          <div className="image-grid">
            {images &&
              images.length > 0 &&
              images.map((item, index) => (
                <div key={item._id} className="image-container">
                  <img
                    onClick={() => handleSelectImage(item)}
                    src={item.url}
                    alt={item.originalname}
                    className="image-item"
                  />
                </div>
              ))}
          </div>
        </Modal>

        <Button
          htmlType="submit"
          className="btn btn-primary pl-4 pr-4 d-flex items-center align-center addnewbutton"
        >
          AŽURIRAJ BLOG
        </Button>
      </Form>
    </div>
  );
};

export default UpdateBlogForm;
