import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useProducts } from '../../../../../../context/products';

const apiKey = 'nrit0ml1nje9ays9nktjy5xo8c0bywenkio5ibxfieoanvx8';
export const Description = (props) => {
  const { form, setForm, productEdit } = useProducts();
  const { label, className } = props;

  const [description, setDescription] = useState('');
  const getTextValue = (value) => {
    if (value !== description) {
      setDescription(value);
    }
  };

  useEffect(() => {
    if (description && description.length > 0) {
      setForm((prevState) => {
        return { ...prevState, productContent: description };
      });
    }
  }, [description, setForm]);

  useEffect(() => {
    if (productEdit && form.productContent !== '') {
      setDescription(form.productContent);
    }
  }, []);

  return (
    <div className="custom-select">
      <span className="label">
        <b>{label}:</b>
      </span>

      <div className="select select-result">
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
          onEditorChange={(newValue, editor) => getTextValue(editor.getContent())}
          init={{
            height: 600,
            menubar: true,
            plugins: 'table',
          }}
          value={description}
        />
      </div>
    </div>
  );
};
