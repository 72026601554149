import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { stateUS } from '../../../helpers/state';

const Table = props => {
  // TABLE
  const { data, handleCheckedRows, handleDisabledButton, handleToggle } = props;
  // PAGINATION
  const { pagination } = props;
  // CUSTOM FOR ACTIONS
  const {
    updateActions,
    statusActions,
    deleteActions,
    location,
    groceryPermissions,
    getGroceryPermissions
  } = props;

  // CUSTOM FOR FILTERS
  const { handleStatusFilter } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // HANDLE CHECKED ALL
  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map(li => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // HANDLE CHECKED CURRENT
  const handleClick = e => {
    const { id, checked } = e.target;
    setIsCheckAll(true);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleDisabledButton(isCheckAll);
  }, [isCheckAll, handleDisabledButton]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [handleCheckedRows, isCheck]);

  const filterState = state => {
    const result = stateUS.filter(item => item.abbreviation === state);
    return result[0].name;
  };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>
              {Object.values(groceryPermissions).some(key =>
                [PERMISSIONS.updateCustomers].includes(key.name)
              ) && (
                <Checkbox
                  type="checkbox"
                  name="select-all-customers"
                  id="selectAll"
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                />
              )}
            </th>
            <th>Ime</th>
            <th>Prezime</th>
            <th>Email</th>
            <th>Telefon</th>
            <th>Adresa</th>
            <th>
              <Popover
                className="thead-actions"
                placement="bottom"
                trigger="click"
                content={() => (
                  <div className="th-actions">
                    <button
                      className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0"
                      type="button"
                      onClick={() => handleStatusFilter('')}
                    >
                      <label className="text" htmlFor="all">
                        <span>Svi Statusi</span>
                      </label>
                    </button>

                    <button
                      className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0"
                      type="button"
                      onClick={() => handleStatusFilter('ACTIVE')}
                    >
                      <label className="text" htmlFor="active">
                        <span>Aktivan</span>
                      </label>
                    </button>

                    <button
                      className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0"
                      type="button"
                      onClick={() => handleStatusFilter('INACTIVE')}
                    >
                      <label className="text" htmlFor="inactive">
                        <span>Deaktiviran</span>
                      </label>
                    </button>
                  </div>
                )}
              >
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                  className="btn block"
                  type="button"
                >
                  <strong>Status</strong>
                  <span
                    className="icon"
                    style={{ display: 'block', width: '30px', height: '30px' }}
                  >
                    <img
                      className="d-block pl-1 pr-1"
                      style={{
                        display: 'block',
                        width: '30px',
                        height: '30px'
                      }}
                      alt="Dots"
                      src="/assets/icons/arrow-down-table-filters.svg"
                    />
                  </span>
                </button>
              </Popover>
            </th>
            <th>Radnje</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              return (
                <tr key={item._id}>
                  <td data-cy={`checkbox-item-customers-${idx}`}>
                    {Object.values(groceryPermissions).some(key =>
                      [PERMISSIONS.updateCustomers].includes(key.name)
                    ) && (
                      <Checkbox
                        key={item._id}
                        type="checkbox"
                        name={item._id}
                        id={item._id}
                        handleClick={handleClick}
                        isChecked={isCheck.includes(item._id)}
                      />
                    )}
                  </td>
                  <td>
                    {item?.firstName} {item?.companyName}
                  </td>
                  <td>{item?.lastName}</td>
                  <td>
                    <a className="link" href={`mailto:${item.email}`}>
                      {item.email}
                    </a>
                  </td>
                  <td>
                    <a href={`tel:${item.phone}`} className="link">
                      {item.phone}
                    </a>
                  </td>
                  <td>
                    <Popover
                      className="tbody-actions tbody-actions-address"
                      placement="bottom"
                      trigger="click"
                      content={() => (
                        <div
                          className="actions"
                          style={{
                            width: '280px',
                            maxWidth: '280px',
                            maxHeight: '240px',
                            overflowY: 'auto'
                          }}
                          data-cy=""
                        >
                          <div className="addresses">
                            {console.log(item?.address)}
                            {item?.address ? (
                              <div className="item" key={item._id}>
                                {item?.address?.street?.name}{' '}
                                {item?.address?.streetNumber},{item.city} <br />
                                {item?.address?.place?.name} (
                                {item?.address?.place.zip})
                              </div>
                            ) : (
                              <Message className="content-message">
                                <p className="text-center mb-0">
                                  Nema podataka...
                                </p>
                              </Message>
                            )}
                          </div>
                        </div>
                      )}
                    >
                      <button
                        className="btn d-flex items-center justify-center gap-x-2"
                        type="button"
                        data-cy="actions-toggle-customers"
                      >
                        <span className="text d-block">
                          {item?.address
                            ? item?.address?.place?.name
                            : 'No address'}
                        </span>
                        <span className="icon">
                          <img
                            className="d-block pl-1 pr-1"
                            alt="Arrow"
                            src="/assets/icons/arrow-down-table-filters.svg"
                          />
                        </span>
                      </button>
                    </Popover>
                  </td>

                  <td>
                    {item.status === 'ACTIVE' ? (
                      <span className="active">AKTIVAN</span>
                    ) : (
                      <span className="inactive">DEAKTIVIRAN</span>
                    )}
                  </td>

                  <td className="y-top">
                    {Object.values(groceryPermissions).some(key =>
                      [
                        PERMISSIONS.updateCustomers,
                        PERMISSIONS.deleteCustomers
                      ].includes(key.name)
                    ) && (
                      <Popover
                        className="tbody-actions"
                        placement="left"
                        trigger="click"
                        content={() => (
                          <div
                            className="actions"
                            data-cy="actions-popover-customers"
                          >
                            {location &&
                              location.pathname.includes(
                                `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`
                              ) && (
                                <>
                                  {Object.values(groceryPermissions).some(key =>
                                    [PERMISSIONS.updateCustomers].includes(
                                      key.name
                                    )
                                  ) &&
                                    item.status ===
                                      'WAITING_FOR_ACTIVATION' && (
                                        // THIS CONDITION NEEDS TO BE REMOVED WHEN DEACTIVATION IS READY ON BE 
                                      <div className="actions-group">
                                        {/* {getGroceryPermissions(
                                        groceryPermissions,
                                        PERMISSIONS.updateCustomers
                                      ) && (
                                        <button
                                          className="btn btn-actions"
                                          type="button"
                                          data-cy="actions-edit-customer"
                                          onClick={() =>
                                            handleToggle({
                                              name: 'update',
                                              state: updateActions,
                                              data: item._id
                                            })
                                          }
                                        >
                                          <span className="icon">
                                            <img
                                              alt="Delete"
                                              src="/assets/icons/edit-user.svg"
                                            />
                                          </span>
                                          <span className="text">
                                            <strong>IZMENI</strong>
                                          </span>
                                        </button>
                                      )} */}

                                        {/* {getGroceryPermissions(
                                        groceryPermissions,
                                        PERMISSIONS.readCustomers
                                      ) && (
                                        <Link
                                          to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.customers}/${item._id}`}
                                          className="btn btn-actions"
                                          type="button"
                                          data-cy="actions-read-customers-details"
                                        >
                                          <span className="icon d-flex align-center">
                                            <img
                                              alt="Details"
                                              src="/assets/icons/details.svg"
                                            />
                                          </span>
                                          <span className="text">
                                            <strong>DETALJI</strong>
                                          </span>
                                        </Link>
                                      )} */}

                                        {getGroceryPermissions(
                                          groceryPermissions,
                                          PERMISSIONS.updateCustomers
                                        ) && (
                                          <button
                                            className="btn btn-actions"
                                            type="button"
                                            data-cy={`${
                                              item?.status === 'ACTIVE'
                                                ? 'actions-deactivate-customer'
                                                : 'actions-activate-customer'
                                            }`}
                                            onClick={() =>
                                              handleToggle({
                                                name: 'status',
                                                state: statusActions,
                                                data: {
                                                  id: item._id,
                                                  status: item.status
                                                }
                                              })
                                            }
                                          >
                                            {item?.status ===
                                              'WAITING_FOR_ACTIVATION' && (
                                              // <>
                                              //   <span className="icon">
                                              //     <img
                                              //       alt="Delete"
                                              //       src="/assets/icons/deactivate-user.svg"
                                              //     />
                                              //   </span>
                                              //   <span className="text">
                                              //     <strong>DEAKTIVIRAJ</strong>
                                              //   </span>
                                              // </>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  gap: '.5rem'
                                                }}
                                              >
                                                <span className="icon">
                                                  <img
                                                    alt="Delete"
                                                    src="/assets/icons/activate-user.svg"
                                                    height={20}
                                                    width={20}
                                                    style={{
                                                      height: 20,
                                                      width: 20
                                                    }}
                                                  />
                                                </span>
                                                <span className="text">
                                                  <strong>AKTIVIRAJ</strong>
                                                </span>
                                              </div>
                                            )}
                                          </button>
                                        )}
                                      </div>
                                    )}

                                  {getGroceryPermissions(
                                    groceryPermissions,
                                    PERMISSIONS.deleteCustomers
                                  ) && (
                                    <button
                                      className="btn btn-actions"
                                      type="button"
                                      data-cy="actions-delete-customer"
                                      onClick={() =>
                                        handleToggle({
                                          name: 'delete',
                                          state: deleteActions,
                                          data: {
                                            id: item._id,
                                            email: item.email
                                          }
                                        })
                                      }
                                    >
                                      <span className="icon">
                                        <img
                                          alt="Delete"
                                          src="/assets/icons/delete.svg"
                                        />
                                      </span>
                                      <span className="text">
                                        <strong>IZBRIŠI</strong>
                                      </span>
                                    </button>
                                  )}
                                </>
                              )}
                          </div>
                        )}
                      >
                        <button
                          className="btn"
                          type="button"
                          data-cy="actions-toggle-customers"
                        >
                          <span className="icon">
                            <img
                              className="d-block pl-1 pr-1"
                              alt="Dots"
                              src="/assets/icons/dots-y.svg"
                            />
                          </span>
                        </button>
                      </Popover>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8} style={{ width: '100%' }}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>
                    {contentMessage.dataNotFound}
                  </h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
