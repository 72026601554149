import React, { useState, useEffect, useReducer } from 'react';
import {
  Popover,
  Button,
  notification,
  Drawer,
  Form,
  Input,
  Select,
  Checkbox,
  Modal
} from 'antd';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import Axios from 'axios';
import {
  DownOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  FileAddOutlined
} from '@ant-design/icons';
import { Tree } from 'antd';
import { useRef } from 'react';
import { PERMISSIONS } from '../../../utils/permissions';

const Table = props => {
  const [formCategory] = Form.useForm();
  const [formTierTwoCategory] = Form.useForm();
  const [formTierThreeCategory] = Form.useForm();
  const { user } = useAuth();
  const { token } = user;
  const [data, setData] = useState([]);
  const [selectedNode, setSelectedNode] = useState();
  const [openCategory, setOpenCategory] = useState(false);
  const [openTierTwoCategory, setOpenTierTwoCategory] = useState(false);
  const [openTierThreeCategory, setOpenTierThreeCategory] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [isVisible, setIsModalVisible] = useState(false);
  const [fieldsArr, setFieldsArr] = useState([]);
  const [attributeTypes, setAttributeTypes] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [reRenderDrawer, setReRenderDrawer] = useState(false);
  const [highlightedCategory, setHighlightedCategory] = useState(
    selectedNode?.highlightedCategory
  );
  const [image, setImage] = useState(undefined);
  const [images, setImages] = useState([]);
  const [imageId, setImageId] = useState({});
  const [popularCategory, setPopularCategory] = useState(
    selectedNode?.popularCategory
  );
  const [popularImage, setPopularImage] = useState(
    selectedNode && selectedNode.popularCategoryImage
      ? selectedNode.popularCategoryImage
      : {}
  );
  const [popularImageId, setPopularImageId] = useState(
    selectedNode && selectedNode.popularCategoryImage
      ? selectedNode.popularCategoryImage._id
      : {}
  );
  const [highlightedImage, setHighlightedImage] = useState({});
  const [highlightedImageId, setHighlightedImageId] = useState(undefined);
  const [svgIconId, setSVGIconId] = useState(undefined);
  const [svgIcon, setSVGIcon] = useState({});
  const [imageType, setImageType] = useState('');
  const [isConfDelModalVisible, setIsConfDelModalVisible] = useState(false);
  const fileInputRef = useRef(null);
  const fileInputRefTwo = useRef(null);
  const svgImageRef = useRef(null);

  const fetchImages = async () => {
    await Axios.get(`${SERVER_URL}/images/`, {
      withCredentials: false,
      headers: {
        department: PERMISSIONS.grocery,
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      if (res.status === 200) {
        setImages(res?.data);
      }
    });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const showDrawerCategory = () => {
    setHighlightedImage(
      selectedNode && selectedNode.highlightedCategoryImage
        ? selectedNode.highlightedCategoryImage
        : {}
    );
    setHighlightedImageId(
      selectedNode && selectedNode.highlightedCategoryImage
        ? selectedNode.highlightedCategoryImage._id
        : {}
    );

    setPopularImage(
      selectedNode && selectedNode.popularCategoryImage
        ? selectedNode.popularCategoryImage
        : {}
    );
    setPopularImageId(
      selectedNode && selectedNode.popularCategoryImage
        ? selectedNode.popularCategoryImage._id
        : {}
    );
    setOpenCategory(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelectImage = item => {
    if (imageType === 'popular') {
      setPopularImage(item);
      setPopularImageId(item._id);
    } else if (imageType === 'highlighted') {
      setHighlightedImage(item);
      setHighlightedImageId(item._id);
    }
    setIsModalVisible(false);
  };

  const onCloseCategory = () => {
    setOpenCategory(false);
    setFieldsArr([]);
    formCategory.resetFields();
  };

  const showDrawerTierTwoCategory = () => {
    setPopularImage(
      selectedNode && selectedNode.popularCategoryImage
        ? selectedNode.popularCategoryImage
        : {}
    );
    setPopularImageId(
      selectedNode && selectedNode.popularCategoryImage
        ? selectedNode.popularCategoryImage._id
        : {}
    );
    setOpenTierTwoCategory(true);
  };

  const onCloseTierTwoCategory = () => {
    setOpenTierTwoCategory(false);

    formTierTwoCategory.resetFields();
  };

  const showDrawerTierThreeCategory = () => {
    setPopularImage(
      selectedNode && selectedNode.popularCategoryImage
        ? selectedNode.popularCategoryImage
        : {}
    );
    setPopularImageId(
      selectedNode && selectedNode.popularCategoryImage
        ? selectedNode.popularCategoryImage._id
        : {}
    );
    setOpenTierThreeCategory(true);
  };

  const onCloseTierThreeCategory = () => {
    setOpenTierThreeCategory(false);
    formTierThreeCategory.resetFields();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setReRenderDrawer(!reRenderDrawer);
  }, [selectedNode]);

  const handleShowModal = (state, type) => {
    setIsModalVisible(true);
    setImageType(type);
  };

  const fetchData = async () => {
    let res = await Axios.get(`${SERVER_URL}/categories-get-all`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    for (let i = 0; i < res.data.length; i++) {
      res.data[i].title = res.data[i].name;
      res.data[i].children = res.data[i].tierTwoCategories;
      res.data[i].level = 'zero';

      for (let j = 0; j < res.data[i].children.length; j++) {
        res.data[i].children[j].title = res.data[i].children[j].name;
        res.data[i].children[j].children =
          res.data[i].children[j].tierThreeCategories;
        res.data[i].children[j].level = 'first';

        for (let k = 0; k < res.data[i].children[j].children.length; k++) {
          res.data[i].children[j].children[k].title =
            res.data[i].children[j].children[k].name;
          res.data[i].children[j].children[k].level = 'second';
        }
      }
    }

    setData(res.data);

    let res2 = await Axios.get(`${SERVER_URL}/attribute-types`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    setAttributeTypes(res2.data);
  };

  const onSelect = async (selectedKeys, info) => {
    let type;

    if (info && info.node && info.node.level) {
      if (info.node.level === 'zero') {
        type = 'category';
      } else if (info.node.level === 'first') {
        type = 'tiertwocategory';
      } else if (info.node.level === 'second') {
        type = 'tierthreecategory';
      }

      let attributeTypes = info.selectedNodes[0].attributes;

      for (let i = 0; i < attributeTypes.length; i++) {
        const res = await Axios.get(
          `${SERVER_URL}/attribute-type/${attributeTypes[i].type._id}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        attributeTypes[i].attributesList = res.data;
      }

      let selectedNodeData = info.selectedNodes[0];

      selectedNodeData.attributes = attributeTypes;

      setSelectedNode({ ...selectedNodeData, type });
      setHighlightedCategory(info.selectedNodes[0].highlightedCategory);
      setPopularCategory(info.selectedNodes[0].popularCategory);
    } else {
      setSelectedNode(null);
    }
  };

  const onFinishCategory = async values => {
    const formValues = formCategory.getFieldsValue();

    for (let i = 0; i < formValues?.attributeTypes?.length; i++) {
      if (!formValues.attributeTypes[i]) {
        notification.error({
          message: 'Type can not be empty.',
          placement: 'topRight'
        });
        return;
      }

      if (!formValues.attributeValues[i]) {
        notification.error({
          message: 'Value can not be empty.',
          placement: 'topRight'
        });
        return;
      }
    }

    try {
      if (isNew) {
        let responsethree;
        if (
          svgImageRef &&
          svgImageRef.current &&
          svgImageRef.current.files[0]
        ) {
          const formData = new FormData();

          formData.append('file', svgImageRef.current.files[0]);

          responsethree = await Axios.post(
            `${SERVER_URL}/upload-blog-image`,
            formData,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` }
            }
          );

          setSVGIconId(responsethree.data.id);
          setSVGIcon(responsethree.data.image);
        }
        await Axios.post(
          `${SERVER_URL}/create-category`,
          {
            ...values,
            svgIcon:
              responsethree && responsethree.data && responsethree.data.id
                ? responsethree.data.id
                : undefined
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        notification.success({
          message: 'Kategorija kreirana.',
          placement: 'topRight'
        });
      } else {
        let response;
        let responsetwo;
        let responsethree;
        if (
          svgImageRef &&
          svgImageRef.current &&
          svgImageRef.current.files[0]
        ) {
          const formData = new FormData();

          formData.append('file', svgImageRef.current.files[0]);

          responsethree = await Axios.post(
            `${SERVER_URL}/upload-blog-image`,
            formData,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` }
            }
          );

          setSVGIconId(responsethree.data.id);
          setSVGIcon(responsethree.data.image);
        }

        await Axios.put(
          `${SERVER_URL}/category/${selectedNode._id}`,
          {
            ...values,
            highlightedCategory,
            selectedNode,
            popularCategory,
            highlightedCategoryImage: highlightedImageId,
            popularCategoryImage: popularImageId,
            svgIcon:
              responsethree && responsethree.data && responsethree.data.id
                ? responsethree.data.id
                : undefined
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        notification.success({
          message: 'Category is updated.',
          placement: 'topRight'
        });
      }

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {
      notification.error({
        message: error.response.data.message,
        placement: 'topRight',
        duration: 2
      });
    }
  };

  const handleEditCategory = () => {
    // console.log(selectedNode);

    if (selectedNode.type === 'category') {
      setIsNew(false);
      formCategory.setFieldsValue({
        name: selectedNode.name,
        metaTitle: selectedNode.metaTitle,
        metaDesc: selectedNode.metaDesc
      });
      showDrawerCategory();
    } else if (selectedNode.type === 'tiertwocategory') {
      setIsNew(false);
      formTierTwoCategory.setFieldsValue({
        name: selectedNode.name,
        metaTitle: selectedNode.metaTitle,
        metaDesc: selectedNode.metaDesc
      });

      showDrawerTierTwoCategory();
    } else if (selectedNode.type === 'tierthreecategory') {
      setIsNew(false);
      formTierThreeCategory.setFieldsValue({
        name: selectedNode.name,
        metaTitle: selectedNode.metaTitle,
        metaDesc: selectedNode.metaDesc
      });

      showDrawerTierThreeCategory();
    }
  };

  const handleCreateSubCategory = () => {
    setIsNew(true);
    if (selectedNode.type === 'category') {
      showDrawerTierTwoCategory();
    }
    if (selectedNode.type === 'tiertwocategory') {
      showDrawerTierThreeCategory();
    }
  };

  const onFinishTierTwoCategory = async values => {
    try {
      if (isNew) {
        values.category = selectedNode._id;

        await Axios.post(
          `${SERVER_URL}/create-tiertwo-category`,
          { ...values },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        notification.success({
          message: 'Sub-category is created.',
          placement: 'topRight'
        });
      } else {
        await Axios.put(
          `${SERVER_URL}/tiertwo-category/${selectedNode._id}`,
          {
            ...values,
            selectedNode,
            popularCategory,
            popularCategoryImage: popularImageId
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        notification.success({
          message: 'Sub-category is updated.',
          placement: 'topRight'
        });
      }

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {
      notification.error({
        message: error.response.data.message,
        placement: 'topRight',
        duration: 2
      });
    }
  };

  const onFinishTierThreeCategory = async values => {
    try {
      if (isNew) {
        values.category = selectedNode._id;
        await Axios.post(`${SERVER_URL}/create-tierthree-category`, values, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        });

        notification.success({
          message: 'Sub-category is created.',
          placement: 'topRight'
        });
      } else {
        await Axios.put(
          `${SERVER_URL}/tierthree-category/${selectedNode._id}`,
          {
            ...values,
            popularCategory,
            selectedNode,
            popularCategoryImage: popularImageId
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        notification.success({
          message: 'Sub-category is updated.',
          placement: 'topRight'
        });
      }

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {
      notification.error({
        message: error.response.data.message,
        placement: 'topRight',
        duration: 2
      });
    }
  };

  const handleDeleteCategory = async () => {
    try {
      if (selectedNode.type === 'category') {
        await Axios.delete(`${SERVER_URL}/category/${selectedNode._id}`);

        notification.success({
          message: 'Category deleted successful.',
          placement: 'topRight'
        });
      } else if (selectedNode.type === 'tiertwocategory') {
        await Axios.delete(
          `${SERVER_URL}/tiertwo-category/${selectedNode._id}`
        );

        notification.success({
          message: 'Sub-category deleted successful.',
          placement: 'topRight'
        });
      } else if (selectedNode.type === 'tierthreecategory') {
        await Axios.delete(
          `${SERVER_URL}/tierthree-category/${selectedNode._id}`
        );

        notification.success({
          message: 'Sub-category deleted successful.',
          placement: 'topRight'
        });
      }

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {
      notification.error({
        message: error.response.data,
        placement: 'topRight'
      });
      console.log(error);
    }
  };

  const handleChangeAttributeType = (e, index) => {
    for (let i = 0; i < attributeTypes.length; i++) {
      if (attributeTypes[i]._id.toString() === e.toString()) {
        let field = fieldsArr;

        field[index].type = attributeTypes[i];

        setFieldsArr(field);
      }
    }

    setReRender(!reRender);
  };

  const handleRemoveFromAddingCategory = index => {
    const data = formCategory.getFieldsValue();
    let attArr = data.attributeTypes;
    let valArr = data.attributeValues;

    attArr.splice(index, 1); // 2nd parameter means remove one item only
    valArr?.splice(index, 1); // 2nd parameter means remove one item only

    formCategory.setFieldsValue({
      attributeValues: valArr,
      attributeTypes: attArr
    });

    setFieldsArr(fieldsArr.slice(0, -1));
  };

  const handleRemoveFromAddingTierTwoCategory = index => {
    const data = formTierTwoCategory.getFieldsValue();
    let attArr = data.attributeTypes;
    let valArr = data.attributeValues;

    attArr.splice(index, 1); // 2nd parameter means remove one item only
    valArr?.splice(index, 1); // 2nd parameter means remove one item only

    formTierTwoCategory.setFieldsValue({
      attributeValues: valArr,
      attributeTypes: attArr
    });

    setFieldsArr(fieldsArr.slice(0, -1));
  };

  const handleRemoveFromAddingTierThreeCategory = index => {
    const data = formTierThreeCategory.getFieldsValue();
    let attArr = data.attributeTypes;
    let valArr = data.attributeValues;

    attArr.splice(index, 1); // 2nd parameter means remove one item only
    valArr?.splice(index, 1); // 2nd parameter means remove one item only

    formTierThreeCategory.setFieldsValue({
      attributeValues: valArr,
      attributeTypes: attArr
    });

    setFieldsArr(fieldsArr.slice(0, -1));
  };

  const handleRemoveAttributeCategory = async index => {
    try {
      await Axios.delete(
        `${SERVER_URL}/category-attribute/${selectedNode._id}/${index}`
      );

      notification.success({
        message: 'Atribut kategorije uspesno obrisan.',
        placement: 'topRight'
      });

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {}
  };

  const handleRemoveAttributeTierTwoCategory = async index => {
    try {
      await Axios.delete(
        `${SERVER_URL}/tiertwo-category-attribute/${selectedNode._id}/${index}`
      );

      notification.success({
        message: 'Atribut kategorije uspesno obrisan.',
        placement: 'topRight'
      });

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {}
  };

  const handleRemoveAttributeTierThreeCategory = async index => {
    try {
      await Axios.delete(
        `${SERVER_URL}/tierthree-category-attribute/${selectedNode._id}/${index}`
      );

      notification.success({
        message: 'Atribut kategorije izbrisan.',
        placement: 'topRight'
      });

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {}
  };

  const handleChangeForExistingAttributes = (values, index) => {
    let selectedNodeVariable = selectedNode;
    let valuesVariable = values;

    for (let i = 0; i < valuesVariable.length; i++) {
      for (
        let j = 0;
        j <
        selectedNodeVariable.attributes[index].attributesList.attributes.length;
        j++
      ) {
        if (
          selectedNodeVariable.attributes[index].attributesList.attributes[j]
            ._id === valuesVariable[i]
        ) {
          valuesVariable[i] =
            selectedNodeVariable.attributes[index].attributesList.attributes[j];
        }
      }
    }

    selectedNodeVariable.attributes[index].values = valuesVariable;

    setSelectedNode(selectedNodeVariable);
    setReRender(!reRender);
  };

  const handleRemoveSVG = async id => {
    try {
      await Axios.put(`${SERVER_URL}/category-svg/${id}`);

      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      notification.error({ message: error, placement: 'topRight' });
    }
  };

  const handleShowDelModal = () => {
    setIsConfDelModalVisible(true);
  };

  const handleCancelDelConfModal = () => {
    setIsConfDelModalVisible(false);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          margin: '0px 0 20px 0'
        }}
      >
        {selectedNode && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {selectedNode.type !== 'tierthreecategory' && (
              <Button
                type="primary"
                className="btn btn-primary "
                onClick={e => {
                  handleCreateSubCategory();
                }}
                style={{ marginRight: '5px' }}
              >
                Kreiraj Pod-Kategoriju
              </Button>
            )}
            <Button
              type="primary"
              className="btn btn-primary "
              onClick={e => {
                handleEditCategory();
              }}
              style={{ marginRight: '5px' }}
            >
              Izmeni Izabranu Kategoriju
            </Button>

            <Button
              onClick={() => {
                handleShowDelModal();
              }}
              type="primary"
              className="btn btn-primary "
              style={{ marginRight: '5px' }}
            >
              Izbriši Izabranu Kategoriju
            </Button>
          </div>
        )}
        <Button
          style={{ width: '33%' }}
          onClick={() => {
            setIsNew(true);
            showDrawerCategory();
            setSelectedNode();
          }}
          className="btn btn-primary"
        >
          <span>Kreiraj Kategoriju</span>
        </Button>
      </div>

      <Drawer
        zIndex={1000}
        style={{ color: 'black' }}
        width={900}
        title={`${isNew ? 'Kreiraj' : 'Izmeni'} Kategoriju`}
        placement="right"
        onClose={onCloseCategory}
        open={openCategory}
      >
        <Form
          onFinish={values => onFinishCategory(values)}
          layout="vertical"
          form={formCategory}
        >
          <Form.Item name="name" label="Naziv">
            <Input
              type="text"
              placeholder="Naziv"
              className="input-blog-page"
            />
          </Form.Item>
          <Form.Item name="metaTitle" label="Meta Naziv">
            <Input
              type="text"
              placeholder="Meta Naziv"
              className="input-blog-page"
            />
          </Form.Item>
          <Form.Item name="metaDesc" label="Meta Opis">
            <Input
              type="text"
              placeholder="Meta Opis"
              className="input-blog-page"
            />
          </Form.Item>

          <div style={{ marginBottom: '10px' }}>
            SVG ikonica kategorije:
            <input
              className="placeholder-style"
              type="file"
              ref={svgImageRef}
              accept=".svg, image/svg+xml"
            />
            {!isNew &&
              selectedNode.svgIcon &&
              selectedNode.svgIcon.url &&
              selectedNode.svgIcon.originalname && (
                <img
                  style={{ maxWidth: '250px' }}
                  className="img"
                  src={`${SERVER_URL}/${selectedNode.svgIcon.url}`}
                  alt={selectedNode.svgIcon.originalname}
                />
              )}
            {!isNew &&
              selectedNode.svgIcon &&
              selectedNode.svgIcon.url &&
              selectedNode.svgIcon.originalname && (
                <Button
                  icon={<DeleteOutlined />}
                  type="primary"
                  style={{
                    borderRadius: '90px',
                    height: '45px',
                    width: '45px',
                    margin: '0 0 0 15px'
                  }}
                  danger
                  onClick={() => handleRemoveSVG(selectedNode._id)}
                />
              )}
          </div>

          {!isNew && (
            <div>
              <Form.Item>
                <Checkbox
                  onChange={e => {
                    setHighlightedCategory(e.target.checked);
                  }}
                  checked={highlightedCategory}
                >
                  Istaknuta kategorija
                </Checkbox>
              </Form.Item>

              {highlightedCategory && (
                <div className="highlightedImageDiv">
                  Slika istaknute kategorije
                  <img src={highlightedImage.url}></img>
                  <Button
                    style={{
                      minHeight: '10px',
                      borderRadius: '10px',
                      margin: '0 0 15px 0'
                    }}
                    onClick={() => {
                      handleShowModal(true, 'highlighted');
                    }}
                    type="primary"
                  >
                    Otvori galeriju
                  </Button>
                </div>
              )}

              {/* {selectedNode.highlightedCategoryImage && selectedNode.highlightedCategoryImage.url && selectedNode.highlightedCategoryImage.originalname && <img style={{ maxWidth: '250px' }} className="img" src={`${SERVER_URL}/${selectedNode.highlightedCategoryImage.url}`} alt={selectedNode.highlightedCategoryImage.originalname} />} */}
            </div>
          )}
          {!isNew && (
            <div>
              <Form.Item>
                <Checkbox
                  onChange={e => {
                    setPopularCategory(e.target.checked);
                  }}
                  checked={popularCategory}
                >
                  Popularna kategorija
                </Checkbox>
              </Form.Item>

              {popularCategory && (
                <div className="highlightedImageDiv">
                  Slika popularne kategorije
                  {/* {console.log(popularImage)} */}
                  <img src={popularImage.url}></img>
                  <Button
                    style={{
                      minHeight: '10px',
                      borderRadius: '10px',
                      margin: '0 0 15px 0px'
                    }}
                    onClick={() => {
                      handleShowModal(true, 'popular');
                    }}
                    type="primary"
                  >
                    Otvori Galeriju
                  </Button>
                </div>
              )}

              {/* {selectedNode.popularCategoryImage && selectedNode.popularCategoryImage.url && selectedNode.popularCategoryImage.originalname && <img style={{ maxWidth: '250px' }} className="img" src={`${SERVER_URL}/${selectedNode.popularCategoryImage.url}`} alt={selectedNode.popularCategoryImage.originalname} />} */}
            </div>
          )}
          {selectedNode &&
            selectedNode.attributes &&
            selectedNode.attributes.map((item, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    style={{ width: '370px' }}
                    disabled
                    defaultValue={item.type.name}
                    type="text"
                    placeholder="Attribute"
                    className="input-blog-page"
                  ></Select>
                  <Select
                    style={{ width: '370px', marginLeft: '15px' }}
                    value={item.values.map((ite, indextwo) => {
                      return `${ite._id}`;
                    })}
                    onChange={e => {
                      handleChangeForExistingAttributes(e, index);
                    }}
                    type="text"
                    mode="multiple"
                    placeholder="Attribute"
                    className="attributesSelect"
                  >
                    {item &&
                      item.attributesList &&
                      item.attributesList.attributes &&
                      item.attributesList.attributes.length > 0 &&
                      item.attributesList.attributes.map(
                        (attitem, attindex) => {
                          return (
                            <Option value={attitem._id}>{attitem.name}</Option>
                          );
                        }
                      )}
                  </Select>

                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{
                      borderRadius: '90px',
                      height: '45px',
                      width: '45px',
                      margin: '0 0 0 15px'
                    }}
                    danger
                    onClick={() => handleRemoveAttributeCategory(index)}
                  />
                </div>
              );
            })}
          <Button
            onClick={() => {
              setFieldsArr(() => [...fieldsArr, {}]);
            }}
            style={{
              borderRadius: '90px',
              height: '45px',
              width: '45px',
              margin: '0 0 20px 0'
            }}
            type="primary"
          >
            <PlusCircleOutlined />
          </Button>
          {fieldsArr &&
            fieldsArr.length > 0 &&
            fieldsArr.map((item, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item
                    key={index}
                    name={['attributeTypes', index]}
                    label="Tip"
                  >
                    <Select
                      style={{ width: '370px' }}
                      onChange={e => {
                        handleChangeAttributeType(e, index);
                      }}
                      type="text"
                      placeholder="Atribut"
                      className="input-blog-page"
                    >
                      {attributeTypes &&
                        attributeTypes.length > 0 &&
                        attributeTypes.map((item, index) => {
                          return <Option value={item._id}>{item.name}</Option>;
                        })}
                    </Select>
                  </Form.Item>

                  {item &&
                    item.type &&
                    item.type.attributes &&
                    item.type.attributes.length > 0 && (
                      <Form.Item
                        style={{ marginLeft: '15px' }}
                        key={index}
                        name={['attributeValues', index]}
                        label="Vrednost"
                      >
                        <Select
                          className="attributesSelect"
                          style={{ width: '370px' }}
                          type="text"
                          mode="multiple"
                          placeholder="Atribut"
                        >
                          {item.type.attributes &&
                            item.type.attributes.length > 0 &&
                            item.type.attributes.map((item, index) => {
                              return (
                                <Option value={item._id}>{item.name}</Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    )}

                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{
                      borderRadius: '90px',
                      height: '45px',
                      width: '45px',
                      margin: '0 0 0 15px'
                    }}
                    danger
                    onClick={() => handleRemoveFromAddingCategory(index)}
                  />
                </div>
              );
            })}
          <Button
            htmlType="submit"
            className="btn btn-primary pl-4 pr-4 d-flex items-center align-center"
          >
            {isNew ? 'Kreiraj' : 'Izmeni'} Kategoriju
          </Button>
        </Form>
      </Drawer>

      <Drawer
        width={900}
        style={{ color: 'black' }}
        title={`${isNew ? 'Kreiraj' : 'Izmeni'} Pod-Kategoriju`}
        placement="right"
        onClose={onCloseTierTwoCategory}
        open={openTierTwoCategory}
      >
        <Form
          onFinish={values => onFinishTierTwoCategory(values)}
          layout="vertical"
          form={formTierTwoCategory}
        >
          <Form.Item name="name" label="Naziv">
            <Input
              type="text"
              placeholder="Naziv"
              className="input-blog-page"
            />
          </Form.Item>

          <Form.Item name="metaTitle" label="Meta Naziv">
            <Input
              type="text"
              placeholder="Meta Naziv"
              className="input-blog-page"
            />
          </Form.Item>

          <Form.Item name="metaDesc" label="Meta Opis">
            <Input
              type="text"
              placeholder="Meta Opis"
              className="input-blog-page"
            />
          </Form.Item>

          {!isNew && (
            <div>
              <Form.Item>
                <Checkbox
                  onChange={e => {
                    setPopularCategory(e.target.checked);
                  }}
                  checked={popularCategory}
                >
                  Popularna kategorija
                </Checkbox>
              </Form.Item>

              {popularCategory && (
                <div className="highlightedImageDiv">
                  Slika popularne kategorije
                  {/* {console.log(popularImage)} */}
                  <img src={popularImage.url}></img>
                  <Button
                    style={{
                      minHeight: '10px',
                      borderRadius: '10px',
                      margin: '0 0 15px 0px'
                    }}
                    onClick={() => {
                      handleShowModal(true, 'popular');
                    }}
                    type="primary"
                  >
                    Otvori galeriju
                  </Button>
                </div>
              )}

              {/* {selectedNode.popularCategoryImage && selectedNode.popularCategoryImage.url && selectedNode.popularCategoryImage.originalname && <img style={{ maxWidth: '250px' }} className="img" src={`${SERVER_URL}/${selectedNode.popularCategoryImage.url}`} alt={selectedNode.popularCategoryImage.originalname} />} */}
            </div>
          )}

          {!isNew &&
            selectedNode &&
            selectedNode.attributes &&
            selectedNode.attributes.map((item, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    style={{ width: '370px' }}
                    disabled
                    defaultValue={item.type.name}
                    type="text"
                    placeholder="Atribut"
                    className="input-blog-page"
                  ></Select>

                  <Select
                    style={{ width: '370px', marginLeft: '15px' }}
                    onChange={e => {
                      handleChangeForExistingAttributes(e, index);
                    }}
                    value={item.values.map((ite, indextwo) => {
                      return `${ite._id}`;
                    })}
                    type="text"
                    mode="multiple"
                    placeholder="Atribut"
                    className="attributesSelect"
                  >
                    {item &&
                      item.attributesList &&
                      item.attributesList.attributes &&
                      item.attributesList.attributes.length > 0 &&
                      item.attributesList.attributes.map(
                        (attitem, attindex) => {
                          return (
                            <Option value={attitem._id}>{attitem.name}</Option>
                          );
                        }
                      )}
                  </Select>

                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{
                      borderRadius: '90px',
                      height: '45px',
                      width: '45px',
                      margin: '0 0 0 15px'
                    }}
                    danger
                    onClick={() => handleRemoveAttributeTierTwoCategory(index)}
                  />
                </div>
              );
            })}

          <Button
            onClick={() => {
              setFieldsArr(() => [...fieldsArr, {}]);
            }}
            style={{
              borderRadius: '90px',
              height: '45px',
              width: '45px',
              margin: '0 0 20px 0'
            }}
            type="primary"
          >
            <PlusCircleOutlined />
          </Button>

          {fieldsArr &&
            fieldsArr.length > 0 &&
            fieldsArr.map((item, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item
                    key={index}
                    name={['attributeTypes', index]}
                    label="Tip"
                  >
                    <Select
                      style={{ width: '370px' }}
                      onChange={e => {
                        handleChangeAttributeType(e, index);
                      }}
                      type="text"
                      placeholder="Atribut"
                      className="input-blog-page"
                    >
                      {attributeTypes &&
                        attributeTypes.length > 0 &&
                        attributeTypes.map((item, index) => {
                          return <Option value={item._id}>{item.name}</Option>;
                        })}
                    </Select>
                  </Form.Item>

                  {item &&
                    item.type &&
                    item.type.attributes &&
                    item.type.attributes.length > 0 && (
                      <Form.Item
                        style={{ marginLeft: '15px' }}
                        key={index}
                        name={['attributeValues', index]}
                        label="Vrednost"
                      >
                        <Select
                          className="attributesSelect"
                          style={{ width: '370px' }}
                          type="text"
                          mode="multiple"
                          placeholder="Atribut"
                        >
                          {item.type.attributes &&
                            item.type.attributes.length > 0 &&
                            item.type.attributes.map((item, index) => {
                              return (
                                <Option value={item._id}>{item.name}</Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    )}

                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{
                      borderRadius: '90px',
                      height: '45px',
                      width: '45px',
                      margin: '0 0 0 15px'
                    }}
                    danger
                    onClick={() => handleRemoveFromAddingTierTwoCategory(index)}
                  />
                </div>
              );
            })}

          <Button
            htmlType="submit"
            className="btn btn-primary pl-4 pr-4 d-flex items-center align-center"
          >
            {isNew ? 'Kreiraj' : 'Izmeni'} Pod-Kategoriju
          </Button>
        </Form>
      </Drawer>

      <Drawer
        key={reRenderDrawer}
        width={900}
        style={{ color: 'black' }}
        title={`${isNew ? 'Create' : 'Edit'} Sub-Category`}
        placement="right"
        onClose={onCloseTierThreeCategory}
        open={openTierThreeCategory}
      >
        <Form
          onFinish={values => onFinishTierThreeCategory(values)}
          layout="vertical"
          form={formTierThreeCategory}
        >
          <Form.Item name="name" label="Naziv">
            <Input
              type="text"
              placeholder="Naziv"
              className="input-blog-page"
            />
          </Form.Item>

          <Form.Item name="metaTitle" label="Meta Naziv">
            <Input
              type="text"
              placeholder="Meta Naziv"
              className="input-blog-page"
            />
          </Form.Item>

          <Form.Item name="metaDesc" label="Meta Opis">
            <Input
              type="text"
              placeholder="Meta Opis"
              className="input-blog-page"
            />
          </Form.Item>

          {!isNew && (
            <div>
              <Form.Item>
                <Checkbox
                  onChange={e => {
                    setPopularCategory(e.target.checked);
                  }}
                  checked={popularCategory}
                >
                  Popularna kategorija
                </Checkbox>
              </Form.Item>

              {popularCategory && (
                <div className="highlightedImageDiv">
                  Slika popularne kategorije
                  {/* {console.log(popularImage)} */}
                  <img src={popularImage.url}></img>
                  <Button
                    style={{
                      minHeight: '10px',
                      borderRadius: '10px',
                      margin: '0 0 15px 0px'
                    }}
                    onClick={() => {
                      handleShowModal(true, 'popular');
                    }}
                    type="primary"
                  >
                    Otvori Galeriju
                  </Button>
                </div>
              )}

              {/* {selectedNode.popularCategoryImage && selectedNode.popularCategoryImage.url && selectedNode.popularCategoryImage.originalname && <img style={{ maxWidth: '250px' }} className="img" src={`${SERVER_URL}/${selectedNode.popularCategoryImage.url}`} alt={selectedNode.popularCategoryImage.originalname} />} */}
            </div>
          )}

          {!isNew &&
            selectedNode &&
            selectedNode.attributes &&
            selectedNode.attributes.map((item, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    style={{ width: '370px' }}
                    disabled
                    value={item.type.name}
                    type="text"
                    placeholder="Attribute"
                    className="input-blog-page"
                  ></Select>

                  <Select
                    style={{ width: '370px', marginLeft: '15px' }}
                    value={item.values.map((ite, indextwo) => {
                      return `${ite._id}`;
                    })}
                    onChange={e => {
                      handleChangeForExistingAttributes(e, index);
                    }}
                    type="text"
                    mode="multiple"
                    placeholder="Attribute"
                    className="attributesSelect"
                  >
                    {' '}
                    {item &&
                      item.attributesList &&
                      item.attributesList.attributes &&
                      item.attributesList.attributes.length > 0 &&
                      item.attributesList.attributes.map(
                        (attitem, attindex) => {
                          return (
                            <Option value={attitem._id}>{attitem.name}</Option>
                          );
                        }
                      )}
                  </Select>

                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{
                      borderRadius: '90px',
                      height: '45px',
                      width: '45px',
                      margin: '0 0 0 15px'
                    }}
                    danger
                    onClick={() =>
                      handleRemoveAttributeTierThreeCategory(index)
                    }
                  />
                </div>
              );
            })}

          <Button
            onClick={() => {
              setFieldsArr(() => [...fieldsArr, {}]);
            }}
            style={{
              borderRadius: '90px',
              height: '45px',
              width: '45px',
              margin: '0 0 20px 0'
            }}
            type="primary"
          >
            <PlusCircleOutlined />
          </Button>

          {fieldsArr &&
            fieldsArr.length > 0 &&
            fieldsArr.map((item, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item
                    key={index}
                    name={['attributeTypes', index]}
                    label="Type"
                  >
                    <Select
                      style={{ width: '370px' }}
                      onChange={e => {
                        handleChangeAttributeType(e, index);
                      }}
                      type="text"
                      placeholder="Atribut"
                      className="input-blog-page"
                    >
                      {attributeTypes &&
                        attributeTypes.length > 0 &&
                        attributeTypes.map((item, index) => {
                          return <Option value={item._id}>{item.name}</Option>;
                        })}
                    </Select>
                  </Form.Item>

                  {item &&
                    item.type &&
                    item.type.attributes &&
                    item.type.attributes.length > 0 && (
                      <Form.Item
                        style={{ marginLeft: '15px' }}
                        key={index}
                        name={['attributeValues', index]}
                        label="Vrednost"
                      >
                        <Select
                          className="attributesSelect"
                          style={{ width: '370px' }}
                          type="text"
                          mode="multiple"
                          placeholder="Attribute"
                        >
                          {item.type.attributes &&
                            item.type.attributes.length > 0 &&
                            item.type.attributes.map((item, index) => {
                              return (
                                <Option value={item._id}>{item.name}</Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    )}

                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{
                      borderRadius: '90px',
                      height: '45px',
                      width: '45px',
                      margin: '0 0 0 15px'
                    }}
                    danger
                    onClick={() =>
                      handleRemoveFromAddingTierThreeCategory(index)
                    }
                  />
                </div>
              );
            })}

          <Button
            htmlType="submit"
            className="btn btn-primary pl-4 pr-4 d-flex items-center align-center"
          >
            {isNew ? 'Create' : 'Edit'} Pod-Kategorija
          </Button>
        </Form>
      </Drawer>

      <Modal
        open={isVisible}
        onCancel={handleCancel}
        footer={[]}
        title={'Select Image'}
      >
        <div className="image-grid">
          {images &&
            images.length > 0 &&
            images.map((item, index) => (
              <div key={item._id} className="image-container">
                <img
                  onClick={() => handleSelectImage(item)}
                  src={item.url}
                  alt={item.originalname}
                  className="image-item"
                />
              </div>
            ))}
        </div>
      </Modal>

      <Modal
        open={isConfDelModalVisible}
        onCancel={handleCancelDelConfModal}
        footer={[]}
        title={'Izbriši Kategoriju'}
      >
        <div className="image-grid">
          <Button primary onClick={handleDeleteCategory}>
            Da
          </Button>
          <Button onClick={handleCancelDelConfModal}>Ne</Button>
        </div>
      </Modal>

      <Tree
        showLine
        switcherIcon={<DownOutlined />}
        defaultExpandedKeys={['0-0-0']}
        onSelect={onSelect}
        treeData={data}
      />
    </div>
  );
};

export default Table;
