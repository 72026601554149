import { Input, Form, Button, notification, Select } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useRef, useMemo } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { DeleteOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';

const AboutUsForm = ({ data, isNew }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [steps, setSteps] = useState([]);
  const [titles, setTitles] = useState([]);
  const [description, setDescription] = useState();
  const [contents, setContents] = useState([]);
  const [form] = Form.useForm();
  const initialValues = data && data.length > 0 ? { ...data[0] } : null;
  const editor = useRef(null);

  const config = useMemo(() => {
    return {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      height: '300px',
      placeholder: 'Počnite kucati...'
    };
  }, []);

  const onFinish = async values => {
    if (data && data.steps && data.steps.length > 0) {
      values.steps = [];
    } else {
      values.steps = initialValues ? initialValues.steps : [];
    }

    for (let i = 0; i < steps.length; i++) {
      let obj = {
        title: titles[i],
        content: contents[i]
      };

      values.steps.push(obj);
    }

    values.desc = description;

    if (isNew) {
      values.type = 'about';
      values.url = 'o-nama';

      await Axios.post(`${SERVER_URL}/single-pages`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      });
    } else {
      await Axios.put(
        `${SERVER_URL}/single-page/${initialValues._id}`,
        values,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        }
      );
    }
    notification.success({
      message: 'About us is updated.',
      placement: 'topRight'
    });

    navigate('/admin/grocery');
  };

  const deleteStep = index => {
    let dataTitles = titles;
    let dataContents = contents;

    dataTitles.splice(index, 1); // 2nd parameter means remove one item only
    dataContents.splice(index, 1); // 2nd parameter means remove one item only

    setTitles(dataTitles);
    setContents(dataContents);
    setSteps(steps.slice(0, -1));
  };

  const handleChangeContents = (e, index) => {
    let con = contents;

    con[index] = e.target.value;

    setContents(con);
  };

  const handleChangeTitles = (e, index) => {
    let titlesArr = titles;

    titlesArr[index] = e.target.value;

    setTitles(titlesArr);
  };

  const handleDeleteStep = async index => {
    let steps = initialValues.steps;

    steps.splice(index, 1); // 2nd parameter means remove one item only

    await Axios.put(
      `${SERVER_URL}/single-page/${initialValues._id}`,
      { steps },
      { withCredentials: false, headers: { Authorization: `Bearer ${token}` } }
    );

    notification.success({
      message: 'Step is deleted.',
      placement: 'topRight'
    });

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <div>
      <Form
        initialValues={initialValues}
        onFinish={values => onFinish(values)}
        layout="vertical"
        form={form}
      >
        <div className="aboutus-title">
          <p>{isNew ? 'KREIRAJ' : 'AŽURIRAJ'} STRANICU O NAMA</p>
        </div>

        <div className="aboutus-row">
          <label>Naslov:</label>
          <Form.Item name="title">
            <Input type="text" placeholder="title" />
          </Form.Item>
        </div>

        <div className="aboutus-row">
          <label>Meta Naslov: </label>
          <Form.Item name="metaTitle">
            <Input type="text" placeholder="metaTitle" />
          </Form.Item>
        </div>

        <div className="aboutus-row">
          <label>Meta Opis:</label>
          <Form.Item name="metaDesc">
            <Input type="text" placeholder="metaDesc" />
          </Form.Item>
        </div>

        <div className="aboutus-row">
          <label>Stavke o nama:</label>
          <Button
            style={{ margin: '10px', borderRadius: '10px' }}
            type="primary"
            onClick={() => setSteps(curr => [...curr, {}])}
          >
            Dodaj stavku
          </Button>
        </div>

        {steps &&
          steps.length > 0 &&
          steps?.map((item, index) => {
            return (
              <div className="aboutus-step-row">
                <label>Naslov:</label>
                <Form.Item style={{ marginRight: '5px' }}>
                  <Input
                    onChange={e => {
                      handleChangeTitles(e, index);
                    }}
                    value={titles[index]}
                    type="text"
                    className="input-blog-page"
                  />
                </Form.Item>
                <label>Sadržaj:</label>
                <Form.Item style={{ marginRight: '5px' }}>
                  <TextArea
                    onChange={e => {
                      handleChangeContents(e, index);
                    }}
                    value={contents[index]}
                    type="text"
                    className="input-blog-page"
                  />
                </Form.Item>
                <Button
                  onClick={() => deleteStep(index)}
                  icon={<DeleteOutlined />}
                  type="primary"
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    height: '38px',
                    marginRight: '5px',
                    marginTop: '3px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '5px'
                  }}
                  danger
                ></Button>
              </div>
            );
          })}

        {initialValues &&
          initialValues.steps.length > 0 &&
          initialValues.steps.map((item, index) => {
            return (
              <div className="aboutus-step-row">
                <label>Naslov:</label>
                <Form.Item>
                  <Input disabled value={item.title} type="text" />
                </Form.Item>
                <label>Sadržaj:</label>
                <Form.Item>
                  <TextArea disabled value={item.content} type="text" />
                </Form.Item>
                <Button
                  onClick={() => handleDeleteStep(index)}
                  icon={<DeleteOutlined />}
                  type="primary"
                  style={{
                    padding: '0px 0px 0px 20px',
                    width: '60px',
                    height: '38px',
                    marginRight: '5px',
                    marginTop: '3px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '5px'
                  }}
                  danger
                ></Button>
              </div>
            );
          })}

        <div className="aboutus-row">
          <label>Sadržaj:</label>
          <Form.Item name="desc">
            <JoditEditor
              ref={editor}
              value={description}
              config={config}
              tabIndex={`5`} // tabIndex of textarea
              onBlur={newContent => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={newContent => {}}
            />
          </Form.Item>
        </div>

        <Button
          htmlType="submit"
          className="btn btn-primary pl-4 pr-4 d-flex items-center align-center addnewbutton"
        >
          {isNew ? 'KREIRAJ' : 'AŽURIRAJ'} STRANICU
        </Button>
      </Form>
    </div>
  );
};

export default AboutUsForm;
