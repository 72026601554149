import { Input, Form, Button, notification, Modal, Upload, Select } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useRef } from 'react';

const CategoriesForm = props => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();

  const [tierTwoCategories, setTierTwoCategories] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async values => {
    await Axios.post(`${SERVER_URL}/create-tierthree-category`, values, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    notification.success({
      message: 'Category is created.',
      placement: 'topRight'
    });

    navigate('/admin/grocery/categories');
  };

  const fetchData = async () => {
    const cat = await Axios.get(`${SERVER_URL}/categories-tiertwo-get-all`);

    setTierTwoCategories(cat.data);
  };

  return (
    <div className="title-container">
      <Form onFinish={values => onFinish(values)} layout="vertical" form={form}>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <p>CREATE NEW TIER THREE CATEGORY</p>
        </div>
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <Link to={'/admin/grocery/categories'} style={{ color: 'black' }}>
            ALL CATEGORIES
          </Link>
        </div>
        <Form.Item name="name" label="Name">
          <Input type="text" placeholder="name" className="input-blog-page" />
        </Form.Item>

        <Form.Item name="metaTitle" label="Meta Title">
          <Input
            type="text"
            placeholder="Meta Title"
            className="input-blog-page"
          />
        </Form.Item>

        <Form.Item name="metaDesc" label="Meta Description">
          <Input
            type="text"
            placeholder="Meta Description"
            className="input-blog-page"
          />
        </Form.Item>

        <Form.Item name="category" label="Category">
          <Select
            type="text"
            placeholder="category"
            className="input-blog-page"
          >
            {tierTwoCategories &&
              tierTwoCategories.length > 0 &&
              tierTwoCategories.map((item, index) => {
                return <Option value={item._id}>{item.name}</Option>;
              })}
          </Select>
        </Form.Item>

        <Button
          htmlType="submit"
          className="btn btn-primary pl-4 pr-4 d-flex items-center align-center"
        >
          Create Tier Three Category
        </Button>
      </Form>
    </div>
  );
};

export default CategoriesForm;
