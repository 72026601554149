import React from 'react';
import { Popover } from 'antd';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { formatTime, formatDate } from '../../../helpers/helpers';
import { Link } from 'react-router-dom';

const Table = props => {
  // TABLE
  const { data, handleToggle } = props;
  // PAGINATION
  const { pagination } = props;
  // CUSTOM FOR ACTIONS
  const {
    updateActions,
    rescheduledActions,
    deleteRescheduledActions,
    location,
    groceryPermissions,
    getGroceryPermissions
  } = props;
  // CUSTOM FOR FILTERS
  const { handleStatusFilter } = props;

  const checkType = item => {
    if (!Array.isArray(item)) {
      return 'obj';
    } else {
      return 'arr';
    }
  };

  const checkObjectProperty = item => {
    if (!item) {
      return;
    }

    const isArray = checkType(item);

    if (isArray) {
      const city = item[0].hasOwnProperty('city');
      const address = item[0].hasOwnProperty('address');
      const zipCode = item[0].hasOwnProperty('zip_code');
      const state = item[0].hasOwnProperty('state');

      if (city && address && zipCode && state) {
        return `${item[0].city}, ${item[0].zip_code}, ${item[0].address}, ${item[0].state}`;
      }
    }
  };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Broj Porudžbine</th>
            <th>Vreme/Datum Poručivanja</th>
            {/* <th>Odloženo</th> */}
            <th>Adresa isporuke</th>
            <th>
              <Popover
                className="thead-actions"
                placement="bottom"
                trigger="click"
                content={() => (
                  <div className="th-actions">
                    <button
                      className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0"
                      type="button"
                      onClick={() => handleStatusFilter('')}
                    >
                      <label className="text" htmlFor="all">
                        <span>Svi Statusi</span>
                      </label>
                    </button>

                    <button
                      className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0"
                      type="button"
                      onClick={() => handleStatusFilter('Ordered')}
                    >
                      <label className="text" htmlFor="ordered">
                        <span>Poručeno</span>
                      </label>
                    </button>

                    <button
                      className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0"
                      type="button"
                      onClick={() => handleStatusFilter('In Progress')}
                    >
                      <label className="text" htmlFor="in-progress">
                        <span>U Toku</span>
                      </label>
                    </button>

                    <button
                      className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0"
                      type="button"
                      onClick={() => handleStatusFilter('On Delivery')}
                    >
                      <label className="text" htmlFor="on-delivery">
                        <span>Na Isporuci</span>
                      </label>
                    </button>

                    <button
                      className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0"
                      type="button"
                      onClick={() => handleStatusFilter('Delivered')}
                    >
                      <label className="text" htmlFor="delivered">
                        <span>Isporučeno</span>
                      </label>
                    </button>

                    <button
                      className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0"
                      type="button"
                      onClick={() => handleStatusFilter('Canceled')}
                    >
                      <label className="text" htmlFor="canceled">
                        <span>Otkazano</span>
                      </label>
                    </button>
                  </div>
                )}
              >
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                  className="btn block"
                  type="button"
                >
                  <strong>Status</strong>
                  <span
                    className="icon"
                    style={{ display: 'block', width: '30px', height: '30px' }}
                  >
                    <img
                      className="d-block pl-1 pr-1"
                      style={{
                        display: 'block',
                        width: '30px',
                        height: '30px'
                      }}
                      alt="Dots"
                      src="/assets/icons/arrow-down-table-filters.svg"
                    />
                  </span>
                </button>
              </Popover>
            </th>
            <th>Cena</th>
            <th>Radnje</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              return (
                <tr
                  key={item._id}
                  className={item.status === 'Ordered' ? 'status-ordered' : ''}
                >
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span
                        className={item.status === 'Ordered' ? 'dot' : ''}
                      ></span>
                      <span>{item.orderNumber}</span>
                    </div>
                  </td>
                  <td>
                    {formatTime(item)} &nbsp;&nbsp; {formatDate(item)}
                  </td>
                  {/* <td className="rescheduled">
                    {item.hasOwnProperty('rescheduled') && item.rescheduled ? (
                      item.status === 'Ordered' || item.status === 'In Progress' ? (
                        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {item.rescheduled} <img style={{ cursor: 'pointer' }} alt="Update" src="/assets/icons/edit.svg" onClick={() => handleToggle({ name: 'delete-rescheduled', state: deleteRescheduledActions, data: { id: item._id, status: item.status } })} />
                        </span>
                      ) : (
                        item.rescheduled
                      )
                    ) : item.status !== 'Ordered' && item.status !== 'In Progress' ? (
                      '/'
                    ) : (
                      <button onClick={() => handleToggle({ name: 'rescheduled', state: rescheduledActions, data: { id: item._id, status: item.status, canceled_reason: item.canceled_reason && item.canceled_reason, rescheduled: '' } })}>Odloži Isporuku</button>
                    )}
                  </td> */}
                  <td>{item?.address}</td>
                  <td className="status-cell">
                    {item.status === 'Canceled' ? (
                      <Popover
                        className="thead-actions"
                        placement="bottom"
                        trigger="click"
                        content={() => (
                          <div>
                            <p
                              style={{
                                wordWrap: 'break-word',
                                minWidth: '100px',
                                maxWidth: '600px'
                              }}
                            >
                              {item.canceled_reason}
                            </p>
                          </div>
                        )}
                      >
                        <button
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%'
                          }}
                          className="btn block"
                          type="button"
                        >
                          <span className="status-canceled">Otkazano</span>
                          <img
                            className="icon"
                            alt="arrow"
                            src="/assets/icons/arrow-down-table-filters.svg"
                          />
                        </button>
                      </Popover>
                    ) : item.status === 'Ordered' ? (
                      'Poručeno'
                    ) : item.status === 'In Progress' ? (
                      'U Toku'
                    ) : item.status === 'On Delivery' ? (
                      'Na Isporuci'
                    ) : item.status === 'Delivered' ? (
                      'Isporučeno'
                    ) : (
                      item.status
                    )}
                  </td>
                  <td>{item.totalPrice} RSD</td>
                  <td className="y-top">
                    {Object.values(groceryPermissions).some(key =>
                      [
                        PERMISSIONS.readOrders,
                        PERMISSIONS.updateOrders
                      ].includes(key.name)
                    ) && (
                      <Popover
                        className="tbody-actions"
                        placement="left"
                        trigger="click"
                        content={() => (
                          <div
                            className="actions"
                            data-cy="actions-popover-customers"
                          >
                            {location &&
                              location.pathname.includes(
                                `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`
                              ) && (
                                <>
                                  {Object.values(groceryPermissions).some(key =>
                                    [PERMISSIONS.readOrders].includes(key.name)
                                  ) && (
                                    <div className="actions-group">
                                      {getGroceryPermissions(
                                        groceryPermissions,
                                        PERMISSIONS.readOrders
                                      ) && (
                                        <Link
                                          to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}/${item._id}`}
                                          className="btn btn-actions"
                                          type="button"
                                          data-cy="actions-read-customers-details"
                                        >
                                          <span className="icon">
                                            <img
                                              alt="details"
                                              src="/assets/icons/details.svg"
                                            />
                                          </span>
                                          <span className="text">
                                            <strong>DETALJI PORUDŽBINE</strong>
                                          </span>
                                        </Link>
                                      )}
                                    </div>
                                  )}
                                  {/* 
                                  {getGroceryPermissions(
                                    groceryPermissions,
                                    PERMISSIONS.updateOrders
                                  ) && (
                                    <button
                                      className="btn btn-actions"
                                      type="button"
                                      data-cy="actions-delete-customer"
                                      onClick={() =>
                                        handleToggle({
                                          name: 'update',
                                          state: updateActions,
                                          data: {
                                            id: item._id,
                                            status: item.status,
                                            canceled_reason:
                                              item.canceled_reason,
                                            rescheduled: item.rescheduled
                                          }
                                        })
                                      }
                                    >
                                      <span className="icon">
                                        <img
                                          alt="Update"
                                          src="/assets/icons/edit.svg"
                                        />
                                      </span>
                                      <span className="text">
                                        <strong>AŽURIRAJ PORUDŽBINU</strong>
                                      </span>
                                    </button>
                                  )} */}
                                </>
                              )}
                          </div>
                        )}
                      >
                        <button
                          className="btn"
                          type="button"
                          data-cy="actions-toggle-customers"
                        >
                          <span className="icon">
                            <img
                              className="d-block pl-1 pr-1"
                              alt="Dots"
                              src="/assets/icons/dots-y.svg"
                            />
                          </span>
                        </button>
                      </Popover>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={20}
                style={{
                  backgroundColor: '#fff',
                  height: '200px',
                  border: '0'
                }}
              >
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>
                    {contentMessage.dataNotFound}
                  </h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
