import React from 'react';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { Link } from 'react-router-dom';

const Table = ({ data, pagination }) => {
  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Broj pretraga</th>
            <th>Korisnik</th>
            <th>Radnja</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map(item => {
              return (
                <tr key={item?.userId?._id}>
                  <td>{item?.searchListLength}</td>
                  <td>
                    {item?.userId?.firstName} {item?.userId?.lastName}
                    {item?.userId?.companyName}
                  </td>
                  <td className="y-top">
                    <button
                      className="btn btn-actions"
                      type="button"
                      data-cy="actions-edit-employee"
                    >
                      <Link to={`${item?.userId?._id}`}>
                        <span className="icon">
                          <img
                            alt="preview"
                            src="/assets/icons/edit-user.svg"
                          />
                        </span>
                      </Link>
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>
                    {contentMessage.dataNotFound}
                  </h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data?.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
