import React from 'react';
import { stateUS } from '../../../../../helpers/state';
import Loading from '../../../../../components/loading/Loading';
import SidebarModal from '../../../../../components/sidebarModal/SidebarModal';
import { Select, Input, Button, Form, Row, Col, Checkbox } from 'antd';

export const UpdateFormAllSettings = ({ updateProps, loadingEmployee, updateModalFormRef, updateFormFields, onChange, toggleNewPassword, roles, editBtnLoader, handleOnFinish, setToggleUpdate }) => {
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Izmeni Zaposlenog">
      {!loadingEmployee ? (
        <Form name="update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', data: data })} fields={updateFormFields}>
          <Form.Item
            name="firstName"
            label="Ime:"
            rules={[
              {
                required: true,
                message: 'Molimo vas da unesete Ime!'
              }
            ]}
          >
            <Input placeholder="Ime:" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Prezime:"
            rules={[
              {
                required: true,
                message: 'Molimo vas da unesete Prezime!'
              }
            ]}
          >
            <Input placeholder="Prezime:" />
          </Form.Item>

          <Form.Item name="email" label="Email:" rules={[{ required: true, message: 'Molimo vas da unesete email!' }]}>
            <Input placeholder="Email:" value="" />
          </Form.Item>

          {/* <Form.Item name="state" label="Odaberi državu:" rules={[{ required: false, message: 'Molimo vas da unesete državu!' }]}>
            <Select>
              <Option value="">Odaberi</Option>

              {stateUS.map((item) => {
                return (
                  <Option key={item.name} value={item.abbreviation}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="address" label="Adresa:" rules={[{ required: false, message: 'Molimo vas da unesete Adresu!' }]}>
            <Input placeholder="Adresa:" />
          </Form.Item>

          <Form.Item name="city" label="Grad:" rules={[{ required: false, message: 'Molimo vas da unesete Grad!' }]}>
            <Input placeholder="Grad:" />
          </Form.Item>

          <Form.Item
            name="zip_code"
            label="Zip Kod:"
            rules={[
              {
                required: false,
                message: 'Molimo vas da unesete Zip Kod!'
              }
            ]}
          >
            <Input placeholder="Zip Kod:" />
          </Form.Item> */}

          <Form.Item name="phone" label="Telefon:" rules={[{ required: false, message: 'Molimo vas da unesete broj telefona!' }]}>
            <Input placeholder="Telefon:" />
          </Form.Item>

          <Form.Item className="initial-height">
            <Checkbox onChange={onChange} checked={toggleNewPassword}>
              Kreiraj novu lozinku
            </Checkbox>
          </Form.Item>

          {toggleNewPassword === true && (
            <Form.Item
              name="newPassword"
              label="Lozinka:"
              initialValue={''}
              rules={[
                {
                  required: true,
                  message: 'Molimo vas da unesete novu lozinku!'
                }
              ]}
            >
              <Input.Password placeholder="Lozinka:" />
            </Form.Item>
          )}

          <Form.Item name="role" label="Odaberi Uloge:" rules={[{ required: true, message: 'Molimo vas da unesete Uloge!' }]}>
            <Select>
              <Option value="">Odaberi</Option>

              {roles.map((item) => {
                return (
                  <Option key={item.name} value={item._id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="updateUserId" style={{ display: 'none' }}>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item className="ant-col-btn" wrapperCol={{ offset: 8 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)}>
                  <span>Otkaži</span>
                </Button>
              </Col>
              <Col span={12}>
                {!editBtnLoader ? (
                  <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                    <span>Izmeni Zaposlenog</span>
                  </Button>
                ) : (
                  <Button type="button" className="btn btn-primary-outline btn-block">
                    <span>Izmena...</span>
                  </Button>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
