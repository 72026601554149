import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../config/index';
import { useAuth } from '../../context/useAuth';
import jwt_decode from 'jwt-decode';
import { Select, Input, Button, Form, Row, Col, Checkbox, notification } from 'antd';
import SidebarModal from '../../components/sidebarModal/SidebarModal';
import Loading from '../../components/loading/Loading';
import LoadingPlaceholder from '../../components/loadingPlaceholder/LoadingPlaceholder';
import { Section, Main, Wrapper } from '../../components/content';
import { stateUS } from '../../helpers/state';
import Table from './Table';
import SelectCustom from '../../components/customSelect/CustomSelect';
import { notificationMessages } from '../../helpers/notificationMessages';
import { AXIOS_API_CALL } from '../../utils/endpoint';
import { PERMISSIONS } from '../../utils/permissions';

const { Option } = Select;
const options = [5, 10, 20, 50, 100];

const Profile = () => {
  const { user } = useAuth();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [listActivity, setListActivity] = useState([]);
  const [listRefetch, setListRefetch] = useState(false);
  const [loadingActivity, setLoadingActivity] = useState(true);
  const [listDownloadId, setListDownloadId] = useState({ id: '' });

  // READ USER ROLE NAME FROM STORAGE - DECODE
  const [userRoleName, setUserRoleName] = useState('');

  // GET USER ROLE NAME FROM STORAGE - DECODE
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      setUserRoleName(decodeToken.roleData.roleName);
    }
  }, [user, location]);

  // LOADER PROTECTION
  const [editBtnLoading, setEditBtnLoading] = useState(false);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setListRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setListRefetch((prevState) => !prevState);
  };

  // READ
  const [userData, setUserData] = useState([]);
  const [loadingUserData, setLoadingUserData] = useState(true);

  // UPDATE
  const updateModalFormRef = useRef(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateUserData, setUpdateUserData] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(true);
  const [updateRefecth, setUpdateRefetch] = useState(false);
  const [updateUserId, setUpdateUserId] = useState({ id: '' });

  // UPDATE TOGGLE PASSWORD
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const onChange = (e) => {
    if (e.target.checked) {
      setToggleNewPassword(true);
    } else {
      setToggleNewPassword(false);
    }
  };

  // READ - USER DATA
  useEffect(() => {
    const userId = user.id;
    const { token } = user;

    if (!!userId) {
      try {
        setLoadingUserData(true);
        axios
          .post(`${SERVER_URL}/${AXIOS_API_CALL.usersGetEmoloyee}/${userId}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            setUserData(res.data);
            setTimeout(() => {
              setLoadingUserData(false);
            }, 700);
          })
          .catch((err) => console.error(err))
          .finally(() => {});
      } catch (err) {
        console.error(err);
        setLoadingUserData(false);
      }
    }
  }, [user, setUserData, setLoadingUserData, updateRefecth]);

  // UPDATE - USER DATA
  useEffect(() => {
    const userId = updateUserId.id;
    const { token } = user;

    if (!!userId) {
      try {
        setLoadingUpdate(true);
        axios
          .post(`${SERVER_URL}/${AXIOS_API_CALL.usersGetEmoloyee}/${userId}`, { department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            setUpdateUserData(res.data);
            setTimeout(() => {
              setLoadingUpdate(false);
            }, 700);
          })
          .catch((err) => console.error(err))
          .finally(() => {});
      } catch (err) {
        console.error(err);
        setLoadingUpdate(false);
      }
    }
  }, [user, setLoadingUpdate, setUpdateUserData, updateUserId]);

  // READ - ACTIVITY OF USER
  useEffect(() => {
    const userId = user.id;
    const { token } = user;

    if (!!userId) {
      try {
        setLoadingActivity(true);
        axios
          .post(`${SERVER_URL}/${AXIOS_API_CALL.employeeLogs}/${currentPage}/${limitPage}/${search || null}`, { id: userId, department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            setListActivity(res.data);
            setTimeout(() => {
              setLoadingActivity(false);
            }, 700);
          })
          .catch((err) => console.error(err))
          .finally(() => {});
      } catch (err) {
        console.error(err);
        setLoadingActivity(false);
      }
    }
  }, [user, currentPage, limitPage, search, listRefetch, updateRefecth]);

  useEffect(() => {
    const userId = user.id;
    if (!!userId) {
      setCurrentPage(1);
    }
  }, [user, updateRefecth]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search]);

  const [downloadFileUrl, setDownloadFileUrl] = useState({
    url: ''
  });

  useEffect(() => {
    const listId = listDownloadId.id;
    const { token } = user;

    if (!!listId) {
      // prepare all for download
      try {
        axios
          .post(`${SERVER_URL}/${AXIOS_API_CALL.logsCreateFile}`, { id: listId, department: PERMISSIONS.grocery }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            setDownloadFileUrl({ url: res.data.message.downloadPath });
          })
          .finally(setTimeout(() => {}, 700));
      } catch (err) {}
    }
  }, [user, listDownloadId]);

  useEffect(() => {
    const url = downloadFileUrl.url;
    const { token } = user;

    if (!!url) {
      try {
        axios
          .get(`${SERVER_URL}/${AXIOS_API_CALL.logsDownload}`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `activity-log-name.txt`);

            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);

            setDownloadFileUrl({ url: '' });
          })
          .finally(setTimeout(() => {}, 700));
      } catch (err) {}
    }
  }, [user, downloadFileUrl]);

  // UPDATE - TOGGLE MODAL
  const handleToggle = (props) => {
    const { name, state, data } = props;

    switch (name) {
      case 'update':
        setUpdateUserId({ id: user.id });
        state.onToggle((prevState) => !prevState);
        break;
      case 'download':
        setListDownloadId({ id: data });
        break;
      default:
        console.error('handleToggle ---- error');
    }
  };

  // UPDATE - SUBMIT
  const handleOnFinish = async (props) => {
    const { name, data } = props;

    const { token } = user;

    switch (name) {
      case 'update':
        setEditBtnLoading(true);
        try {
          const newPassword = Object.keys(data).includes('newPassword');
          const userId = updateUserId.id;

          let updateData = [];

          // Date to backend --
          if (!newPassword) {
            updateData = updateData = {
              firstName: data?.firstName || '',
              lastName: data?.lastName || '',
              address: [
                {
                  address: data?.address || '',
                  zip_code: data?.zip_code || '',
                  state: data?.state || '',
                  city: data?.city || ''
                }
              ],
              phone: data?.phone || '',
              email: data?.email || '',
              department: PERMISSIONS.grocery
            };
          } else {
            updateData = updateData = {
              firstName: data?.firstName || '',
              lastName: data?.lastName || '',
              password: data?.newPassword || '',
              address: [
                {
                  address: data?.address || '',
                  zip_code: data?.zip_code || '',
                  state: data?.state || '',
                  city: data?.city || ''
                }
              ],
              phone: data?.phone || '',
              email: data?.email || '',
              department: PERMISSIONS.grocery
            };
          }

          await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.updateEmployee}/${userId}`, { ...updateData }, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

          setUpdateRefetch((prevState) => !prevState);
          setToggleUpdate(false);
          setUpdateUserData([]);
          setUpdateUserId({ id: '' });

          setTimeout(() => {
            updateModalFormRef.current.resetFields();
          }, 750);

          // notification
          notification.success({
            message: notificationMessages.successUpdateProfile,
            placement: 'topRight'
          });
          setTimeout(() => {
            setEditBtnLoading(false);
          }, 700);
        } catch (err) {}
        break;
      default:
        console.error('handleOnFinish ---- error');
    }
  };

  // UPDATE
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    loading: loadingUpdate
  };

  // TABLE
  const tableActionsProps = {
    handleToggle: handleToggle
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: listActivity.data?.pagesCount,
    totalCount: listActivity.data?.totalCount
  };

  // FORMS
  const updateFormFields = [
    {
      name: 'firstName',
      value: (updateUserData && updateUserData?.firstName) || ''
    },
    {
      name: 'lastName',
      value: (updateUserData && updateUserData?.lastName) || ''
    },
    {
      name: 'email',
      value: (updateUserData && updateUserData?.email) || ''
    },
    // {
    //   name: 'address',
    //   value: (!!updateUserData?.address?.[0] && updateUserData.address?.[0].address) || ''
    // },
    // {
    //   name: 'zip_code',
    //   value: (!!updateUserData?.address?.[0] && updateUserData.address?.[0].zip_code) || ''
    // },
    // {
    //   name: 'state',
    //   value: (!!updateUserData?.address?.[0] && updateUserData.address?.[0].state) || ''
    // },
    // {
    //   name: 'city',
    //   value: (!!updateUserData?.address?.[0] && updateUserData.address?.[0].city) || ''
    // },
    {
      name: 'phone',
      value: (!!updateUserData?.phone?.[0] && updateUserData.phone?.[0]) || ''
    },
    {
      name: 'newPassword',
      value: ''
    }
  ];

  return (
    <>
      <h2 className="page-title">Nalog</h2>
      <Section className="section__wrapper section__profile">
        <Main className="section__content">
          {/* User profile information */}
          <Wrapper className="section__wrapper section__wrapper--account">
            <header className="section__header section__header--account">
              {!loadingUserData ? (
                <h2 data-cy="profile-user-name">
                  {userData.firstName} {userData.lastName}
                </h2>
              ) : (
                <LoadingPlaceholder />
              )}

              {!loadingUserData ? (
                <button data-cy="profile-update-btn-icon" type="button" className="btn" onClick={() => handleToggle({ name: 'update', state: updateProps })}>
                  <span className="icon">
                    <img src="/assets/icons/edit-user.svg" alt="Edit" />
                  </span>
                </button>
              ) : (
                <LoadingPlaceholder />
              )}
            </header>

            <Main className="relative min-h-account-content content--account">
              {!loadingUserData ? (
                <Wrapper>
                  <div className="group">
                    <span className="label">Email:</span>
                    <span className="text" data-cy="profile-email">
                      {userData.email}
                    </span>
                  </div>

                  {/* <div className="group-address">
                    <div className="group">
                      <span className="label">Adresa:</span>
                      <span className="text" data-cy="profile-address">
                        {!!userData?.address?.[0] && userData.address?.[0].address}
                      </span>
                    </div>

                    <div className="group">
                      <span className="label">Zip Kod:</span>
                      <span className="text" data-cy="profile-zip-code">
                        {!!userData?.address?.[0] && userData.address?.[0].zip_code}
                      </span>
                    </div>

                    <div className="group">
                      <span className="label">Država:</span>
                      <span className="text" data-cy="profile-state">
                        {!!userData?.address?.[0] && userData.address?.[0].state && stateUS.filter((state) => state.abbreviation.includes(userData.address?.[0].state)).map((item) => item.name)}
                      </span>
                    </div>

                    <div className="group">
                      <span className="label">Grad:</span>
                      <span className="text" data-cy="profile-city">
                        {!!userData?.address?.[0] && userData.address?.[0].city}
                      </span>
                    </div>
                  </div> */}

                  <div className="group-numbers">
                    <div className="group">
                      <span className="label">Telefon:</span>
                      <span className="text" data-cy="profile-phone">
                        {!!userData?.phone?.[0] && userData.phone?.[0]}
                      </span>
                    </div>
                  </div>

                  <div className="group">
                    <span className="label">Uloge:</span>
                    <span className="text" data-cy="profile-role">
                      {userRoleName && userRoleName}
                    </span>
                  </div>

                  <div className="group">
                    <span className="label">Status:</span>
                    <span className={`text badge ${userData && userData.status === 'ACTIVE' ? 'active' : 'inactive'}`} data-cy="profile-status">
                      {userData && userData.status}
                    </span>
                  </div>

                  <div className="group">
                    <span className="label">Lozinka:</span>
                    <span className="text">********</span>
                  </div>

                  <button type="button" className="btn btn-primary btn-block" onClick={() => handleToggle({ name: 'update', state: updateProps })} data-cy="profile-update-btn">
                    Izmeni Nalog
                  </button>
                </Wrapper>
              ) : (
                <Loading />
              )}
            </Main>
          </Wrapper>

          {/* User activity log information */}
          <Wrapper className="section__wrapper section__wrapper--activity">
            <header className="section__header section__header--activity">
              {!loadingActivity ? (
                <div className="filter">
                  <span style={{ color: '#333', fontWeight: '500' }}>Prikaži:</span>
                  <SelectCustom options={options} label={'Stavki'} limit={limitPage} setLimit={setLimitPage} setRefetch={setListRefetch} loading={loadingActivity} />
                </div>
              ) : (
                <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
              )}

              {!loadingActivity ? (
                <div className="search">
                  <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                    <div className="form-group m-0">
                      <span className="icon icon-search">
                        <img src="/assets/icons/search.svg" alt="Search" title="Search" data-cy="search-input-field" />
                      </span>
                      <input value={searchForm.query || ''} type="text" className="input" placeholder="Pretraži" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                      <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                        <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                      </span>
                    </div>
                    <button className="form-submit" data-cy="search-submit-btn">
                      Pretraži
                    </button>
                  </form>
                </div>
              ) : (
                <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
              )}
            </header>

            <Main className="relative min-h-table-content content--activity">{loadingActivity ? <Loading /> : <Table data={listActivity.data?.Data} {...tableActionsProps} pagination={paginationProps} />}</Main>
          </Wrapper>
        </Main>

        {/* Update Profile - all settings */}
        <SidebarModal {...updateProps} className="sidebar__modal--right" title="Izmeni Nalog">
          {!loadingUpdate ? (
            <Form name="update" className="form form-update" ref={updateModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'update', data: data })} fields={updateFormFields}>
              <Form.Item name="firstName" label="Ime:" rules={[{ required: true, message: 'Molimo vas da unesete svoje Ime!' }]}>
                <Input placeholder="Ime:" data-cy="modal-profile-user-firstName" />
              </Form.Item>

              <Form.Item name="lastName" label="Prezime:" rules={[{ required: true, message: 'Molimo vas da unesete svoje prezime!' }]}>
                <Input placeholder="Prezime:" data-cy="modal-profile-user-lastName" />
              </Form.Item>

              <Form.Item name="email" label="Email:" rules={[{ required: true, message: 'Molimo vas da unesete svoj email!' }]}>
                <Input placeholder="Email:" value="" data-cy="modal-profile-user-email" />
              </Form.Item>

              {/* <Form.Item name="address" label="Adresa" rules={[{ required: false, message: 'Molimo vas da unesete svoju adresu!' }]}>
                <Input placeholder="Adresa:" data-cy="modal-profile-user-address" />
              </Form.Item>

              <Form.Item name="zip_code" label="Zip Kod" rules={[{ required: false, message: 'Molimo vas da unesete svoj zip kod!' }]}>
                <Input placeholder="Zip Kod:" data-cy="modal-profile-user-zipCode" />
              </Form.Item>

              <Form.Item name="state" label="Država" rules={[{ required: false, message: 'Molimo vas da unesete svoju državu!' }]}>
                <Select data-cy="modal-profile-user-state">
                  <Option value="">Odaberi državu</Option>

                  {stateUS.map((item) => {
                    return (
                      <Option key={item.name} value={item.abbreviation}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item name="city" label="Grad" rules={[{ required: false, message: 'Molimo vas da unesete svoj grad!' }]}>
                <Input placeholder="Grad:" data-cy="modal-profile-user-city" />
              </Form.Item> */}

              <Form.Item name="phone" label="Telefon" rules={[{ required: false, message: 'Molimo vas da unesete svoj telefon!' }]}>
                <Input placeholder="Telefon:" data-cy="modal-profile-user-phone" />
              </Form.Item>

              <Form.Item className="initial-height">
                <Checkbox onChange={onChange} checked={toggleNewPassword} data-cy="modal-profile-user-toggleNewPassword">
                  Kreiraj novu lozinku
                </Checkbox>
              </Form.Item>

              {toggleNewPassword === true && (
                <Form.Item name="newPassword" label="Nova lozinka:" rules={[{ required: true, message: 'Molimo vas da unesete svoju lozinku!' }]}>
                  <Input.Password placeholder="Lozinka:" value="" data-cy="modal-profile-user-newPassword" />
                </Form.Item>
              )}

              <span className="fake-label">
                <b>Uloge:</b>
              </span>

              <div className="group">
                <span className="label">Uloga:</span>
                <span className="text" data-cy="modal-profile-user-role">
                  {userRoleName}
                </span>
              </div>

              <span className="fake-label">
                <b>Status:</b>
              </span>

              <div className="group">
                <span className="label">Status:</span>
                <span className={`text badge ${userData && userData.status === 'ACTIVE' ? 'aktivan' : 'deaktiviran'}`} data-cy="modal-profile-user-status">
                  {userData && userData.status === 'ACTIVE' ? 'AKTIVAN' : 'DEAKTIVIRAN'}
                </span>
              </div>

              <Form.Item wrapperCol={{ offset: 8 }}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Button type="primary" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleUpdate(false)} data-cy="modal-profile-cancel-btn">
                      <span>Otkaži</span>
                    </Button>
                  </Col>
                  <Col span={12}>
                    {!editBtnLoading ? (
                      <Button type="submit" htmlType="submit" className="btn btn-primary btn-block" data-cy="modal-profile-update-btn">
                        <span>Izmeni Nalog</span>
                      </Button>
                    ) : (
                      <Button type="button" className="btn btn-primary-outline btn-block">
                        <span>Ažuriranje...</span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          ) : (
            <Loading />
          )}
        </SidebarModal>
      </Section>
    </>
  );
};

export default Profile;
