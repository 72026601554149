import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, Button, notification } from 'antd';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';
import { formatTime, formatDate } from '../../../helpers/helpers';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import Axios from 'axios';
import moment from 'moment';

const Table = props => {
  const { user } = useAuth();
  const { token } = user;
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await Axios.get(`${SERVER_URL}/products-get-all`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    setData(res.data.data);
  };

  const handleDelete = async id => {
    await Axios.delete(`${SERVER_URL}/product/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    notification.success({
      message: 'Artikal obrisan.',
      placement: 'topRight'
    });

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const handleSuspend = async id => {
    await Axios.put(`${SERVER_URL}/suspend-product/${id}`, id, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    notification.success({
      message: 'Artikal suspendovan.',
      placement: 'topRight'
    });

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const handleActivate = async id => {
    await Axios.put(`${SERVER_URL}/activate-product/${id}`, id, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    notification.success({
      message: 'Artikal aktivan.',
      placement: 'topRight'
    });

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Broj Artikla</th>
            <th>Naziv Artikla</th>
            <th>Cena</th>
            <th>Datum Isteka</th>
            <th>Status</th>
            <th>Radnje</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              return (
                <tr
                  key={item._id}
                  className={item.status === 'Ordered' ? 'status-ordered' : ''}
                >
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>{item.numOfArticle}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span className='truncate'>{item?.title}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>{item.price}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>
                        {moment(item.expireDate).format('DD.MM.YYYY.')}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>{item.listingStatus}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      {/* <Link
                        to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateProduct}/${item._id}`}
                        className="link btn-actions link-details"
                      >
                        <span
                          style={{ cursor: 'pointer' }}
                          className="icon d-flex"
                        >
                          <img
                            alt="EDIT"
                            src="/assets/icons/edit-user.svg"
                            style={{ display: 'block' }}
                          />
                        </span>
                      </Link> */}
                      {item &&
                        item.listingStatus &&
                        item.listingStatus !== 'suspended' && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleSuspend(item._id);
                            }}
                            className="icon d-flex"
                          >
                            <img
                              alt="DELETE"
                              src="/assets/icons/suspend.svg"
                              style={{ display: 'block' }}
                            />
                          </span>
                        )}
                      {item &&
                        item.listingStatus &&
                        item.listingStatus === 'suspended' && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleActivate(item._id);
                            }}
                            className="icon d-flex"
                          >
                            <img
                              alt="DELETE"
                              src="/assets/icons/activateproduct.svg"
                              style={{ display: 'block' }}
                            />
                          </span>
                        )}
                      {item &&
                        item.listingStatus &&
                        (item.listingStatus === 'suspended' ||
                          item.listingStatus === 'expired') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleDelete(item._id);
                            }}
                            className="icon d-flex"
                          >
                            <img
                              alt="DELETE"
                              src="/assets/icons/delete.svg"
                              style={{ display: 'block' }}
                            />
                          </span>
                        )}
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={20}
                style={{
                  backgroundColor: '#fff',
                  height: '200px',
                  border: '0'
                }}
              >
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>
                    {contentMessage.dataNotFound}
                  </h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Table;
