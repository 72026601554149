import React from 'react';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
import { stateUS } from '../../../../helpers/state';
import Loading from '../../../../components/loading/Loading';

export const UpdateForm = ({ updateProps, updateLoading, updateModalFormRef, handleOnSubmit, handleOnChange, handleOnBlur, sleep, updateFormError, updateAddress, handleOnRemoveUpdate, toggleUpdateAddress, sleepAddress, toggleNewPassword, setToggleUpdateAddress, handleOnSaveUpdate, updatePasswordProps, editBtnLoader, setToggleUpdate, setToggleNewPassword, TogglePasswordChecker }) => {
  return (
    <SidebarModal {...updateProps} className="sidebar__modal--right" title="Izmeni Korisnika">
      {!updateLoading ? (
        <form
          name="update"
          ref={updateModalFormRef}
          onSubmit={(event) =>
            handleOnSubmit({
              event: event,
              name: 'update',
              state: updateProps
            })
          }
          className="ant-form ant-form-horizontal"
        >
          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateFirstName">
              <b className="required">Ime:</b>
            </label>

            <input name="updateFirstName" id="updateFirstName" value={sleep.updateFirstName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="First Name" data-cy="" className={`input ${updateFormError.updateFirstNameError ? 'input-error' : ''}`} />
            <p data-cy="" className={`error__onblur ${updateFormError.updateFirstNameError ? 'error' : ''}`}>
              Ovo polje je obavezno!
            </p>
          </div>

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateLastName">
              <b className="required">Prezime:</b>
            </label>

            <input name="updateLastName" id="updateLastName" value={sleep.updateLastName || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Last Name" data-cy="" className={`input ${updateFormError.updateLastNameError ? 'input-error' : ''}`} />
            <p data-cy="" className={`error__onblur ${updateFormError.updateLastNameError ? 'error' : ''}`}>
              Ovo polje je obavezno!
            </p>
          </div>

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateEmail">
              <b className="required">Email:</b>
            </label>

            <input name="updateEmail" id="updateEmail" value={sleep.updateEmail || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="email" placeholder="Email" data-cy="" className={`input ${updateFormError.updateEmailError ? 'input-error' : ''}`} />
            <p data-cy="" className={`error__onblur ${updateFormError.updateEmailError ? 'error' : ''}`}>
              Ovo polje je obavezno!
            </p>
          </div>

          <span className="fake-label">
            <b className="required">Adresa:</b>
          </span>

          <div className="form-group form-group-address relative">
            <div className="addresses" data-cy="">
              {updateAddress &&
                updateAddress.length > 0 &&
                updateAddress.map((item, idx) => {
                  const { address, zip_code, city, state } = item;
                  return (
                    <div key={`address_update_${idx}`} className="item" data-cy="">
                      <p>
                        {address}, {zip_code}, {city}, {state}
                      </p>
                      <div className="d-flex gap-x-1">
                        <button
                          className="btn"
                          type="button"
                          onClick={() =>
                            handleOnRemoveUpdate({
                              _id: item._id,
                              index: idx
                            })
                          }
                        >
                          <span className="icon">
                            <img alt="Delete" src="/assets/icons/delete.svg" />
                          </span>
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>

            <button className="btn btn-default" type="button" onClick={() => setToggleUpdateAddress((prevState) => !prevState)}>
              <span className="icon">
                <img alt="Create new Address" src="/assets/icons/plus.svg" />
              </span>
              <span className="text"></span>
            </button>
          </div>

          {toggleUpdateAddress && (
            <div className="wrapper-address">
              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="updateAddress">
                  <b className="required">Adresa:</b>
                </label>

                <input name="updateAddress" id="updateAddress" value={sleepAddress.updateAddress || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Adresa" data-cy="" className={`input ${updateFormError.updateAddressError ? 'input-error' : ''}`} />
                <p data-cy="" className={`error__onblur ${updateFormError.updateAddressError ? 'error' : ''}`}>
                  Ovo polje je obavezno!
                </p>
              </div>

              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="updateZipCode">
                  <b className="required">Zip Kod:</b>
                </label>

                <input name="updateZipCode" id="updateZipCode" value={sleepAddress.updateZipCode || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="number" placeholder="Zip Kod" data-cy="" className={`input ${updateFormError.updateZipCodeError ? 'input-error' : ''}`} />
                <p data-cy="" className={`error__onblur ${updateFormError.updateZipCodeError ? 'error' : ''}`}>
                  Ovo polje je obavezno!
                </p>
              </div>

              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="updateCity">
                  <b className="required">Grad:</b>
                </label>

                <input name="updateCity" id="updateCity" value={sleepAddress.updateCity || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="text" placeholder="Grad" data-cy="" className={`input ${updateFormError.updateCityError ? 'input-error' : ''}`} />
                <p data-cy="" className={`error__onblur ${updateFormError.updateCityError ? 'error' : ''}`}>
                  Ovo polje je obavezno!
                </p>
              </div>

              <div className="form-group relative">
                <label className="label mb-1 d-block" htmlFor="updateState">
                  <b className="required">Država:</b>
                </label>

                <select name="updateState" id="updateState" value={sleepAddress.updateState || 'none'} onChange={handleOnChange} onBlur={handleOnBlur} data-cy="" className={`select select-full ${updateFormError.updateStateError ? 'input-error' : ''}`}>
                  <option value="none">Odaberi državu</option>
                  {stateUS &&
                    stateUS.length > 0 &&
                    stateUS.map((item) => {
                      return (
                        <option key={`update_${item.name.toLowerCase()}`} value={item.abbreviation}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>

                <p data-cy="" className={`error__onblur ${updateFormError.updateStateError ? 'error' : ''}`}>
                  Ovo polje je obavezno!
                </p>
              </div>

              <div className="form-group form-group-sidebar mt-6">
                <button data-cy="" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdateAddress(false)}>
                  <span className="text">Otkaži</span>
                </button>

                <button data-cy="" type="button" className="btn btn-primary" onClick={handleOnSaveUpdate}>
                  <span className="text">Kreiraj Adresu</span>
                </button>
              </div>
            </div>
          )}

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updatePhone">
              <b className="required">Telefon:</b>
            </label>

            <input name="updatePhone" id="updatePhone" value={sleep.updatePhone || ''} onChange={handleOnChange} onBlur={handleOnBlur} type="number" placeholder="Telefon" data-cy="" className={`input ${updateFormError.updatePhoneError ? 'input-error' : ''}`} />
            <p data-cy="" className={`error__onblur ${updateFormError.updatePhoneError ? 'error' : ''}`}>
              Ovo polje je obavezno!
            </p>
          </div>

          <div className="form-group relative">
            <div className="toggle-new-password">
              <input type="checkbox" id="toggleNewPassword" className="input-checkbox" onClick={() => setToggleNewPassword((prevState) => !prevState)} />
              <label htmlFor="toggleNewPassword">Kreiraj novu lozinku</label>
            </div>
          </div>

          {toggleNewPassword === true && <TogglePasswordChecker {...updatePasswordProps} />}

          <div className="form-group relative">
            <label className="label mb-1 d-block" htmlFor="updateStatus">
              <b className="required">Status:</b>
            </label>

            <select name="updateStatus" id="updateStatus" value={sleep.updateStatus} onChange={handleOnChange} onBlur={handleOnBlur} data-cy="" className={`select select-full ${updateFormError.updateStatusError ? 'input-error' : ''}`}>
              <option value="none">Odaberi</option>
              <option value="active">Aktivan</option>
              <option value="inactive">Deaktiviran</option>
            </select>

            <p data-cy="" className={`error__onblur ${updateFormError.updateStatusError ? 'error' : ''}`}>
              Ovo polje je obavezno!
            </p>
          </div>

          <div className="form-group form-group-sidebar">
            <button data-cy="modal-update-cancel-btn" type="button" className="btn btn-primary-link" onClick={() => setToggleUpdate(false)}>
              <span className="text">Otkaži</span>
            </button>

            {!editBtnLoader ? (
              <button data-cy="modal-update-submit-btn" type="submit" className="btn btn-primary">
                <span className="text">Sačuvaj Izmene</span>
              </button>
            ) : (
              <button type="button" className="btn btn-primary-outline">
                <span className="text">Čuvanje...</span>
              </button>
            )}
          </div>
        </form>
      ) : (
        <Loading />
      )}
    </SidebarModal>
  );
};
