import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import Checkbox from '../../../components/customCheckbox/CustomCheckbox';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import Pagination from '../../../components/pagination/Pagination';

const Table = (props) => {
  // TABLE
  const { data, handleCheckedRows, handleDisabledButton, handleToggle } = props;
  // PAGINATION
  const { pagination } = props;
  // CUSTOM FOR ACTIONS
  const { updateActions, statusActions, deleteActions, location, groceryPermissions, getGroceryPermissions } = props;
  // CUSTOM FOR FILTERS
  const { handleStatusFilter } = props;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  // HANDLE CHECKED ALL
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data.map((li) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // HANDLE CHECKED CURRENT
  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheckAll(true);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // UNTOGGLE CHECKED TOP
  useEffect(() => {
    if (isCheck.length < 1) {
      setIsCheckAll(false);
    }
  }, [isCheck, setIsCheckAll]);

  useEffect(() => {
    handleDisabledButton(isCheckAll);
  }, [isCheckAll, handleDisabledButton]);

  useEffect(() => {
    handleCheckedRows(isCheck);
  }, [isCheck, handleCheckedRows]);

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateEmployees].includes(key.name)) && <Checkbox type="checkbox" name="select-all-employees" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckAll} />}</th>

            <th>Ime</th>
            <th>Prezime</th>
            <th>Email</th>
            <th>Telefon</th>
            <th>
              <Popover
                className="thead-actions"
                placement="bottom"
                trigger="click"
                content={() => (
                  <div className="th-actions">
                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('')}>
                      {/* <input type="checkbox" id="all" name="all-status" /> */}
                      <label className="text" htmlFor="all">
                        <span>Svi Statusi</span>
                      </label>
                    </button>

                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('ACTIVE')}>
                      {/* <input type="checkbox" id="active" name="status-active" /> */}
                      <label className="text" htmlFor="active">
                        <span>Aktivan</span>
                      </label>
                    </button>

                    <button className="btn d-flex align-center pl-2 pr-2 pt-1 pb-1 text-left m-0" type="button" onClick={() => handleStatusFilter('INACTIVE')}>
                      {/* <input type="checkbox" id="inactive" name="status-inactive" /> */}
                      <label className="text" htmlFor="inactive">
                        <span>Deaktiviran</span>
                      </label>
                    </button>
                  </div>
                )}
              >
                <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }} className="btn block" type="button">
                  <strong>Status</strong>
                  <span className="icon" style={{ display: 'block', width: '30px', height: '30px' }}>
                    <img className="d-block pl-1 pr-1" style={{ display: 'block', width: '30px', height: '30px' }} alt="Dots" src="/assets/icons/arrow-down-table-filters.svg" />
                  </span>
                </button>
              </Popover>
            </th>
            <th>Uloga</th>
            <th>Radnja</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              return (
                <tr key={item._id}>
                  <td data-cy={`checkbox-item-employees-${idx}`}>{Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateEmployees].includes(key.name)) && <Checkbox key={item._id} type="checkbox" name={item._id} id={item._id} handleClick={handleClick} isChecked={isCheck.includes(item._id)} />}</td>

                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>
                    <a href={`mailto:${item.email}`} className="link">
                      {item.email}
                    </a>
                  </td>
                  <td>
                    {item.phone && item.phone.length > 0 && (
                      <a href={`tel:${item.phone}`} className="link">
                        {item.phone}
                      </a>
                    )}
                  </td>
                  <td>{item.status === 'ACTIVE' ? <span className="active">AKTIVAN</span> : <span className="inactive">DEAKTIVIRAN</span>}</td>
                  <td>{item && item.user_role ? item.user_role : null}</td>

                  <td className="y-top">
                    {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateEmployees, PERMISSIONS.deleteEmployees].includes(key.name)) && (
                      <Popover
                        className="tbody-actions"
                        placement="left"
                        trigger="click"
                        content={() => (
                          <div className="actions" data-cy="actions-popover-employees">
                            {location && location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`) && (
                              <>
                                {Object.values(groceryPermissions).some((key) => [PERMISSIONS.updateEmployees].includes(key.name)) && (
                                  <div className="actions-group">
                                    {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
                                      <button className="btn btn-actions" type="button" data-cy="actions-edit-employee" onClick={() => handleToggle({ name: 'update', state: updateActions, data: item._id })}>
                                        <span className="icon">
                                          <img alt="Delete" src="/assets/icons/edit-user.svg" />
                                        </span>
                                        <span className="text">
                                          <strong>IZMENI</strong>
                                        </span>
                                      </button>
                                    )}

                                    {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateEmployees) && (
                                      <button className="btn btn-actions" type="button" data-cy={`${item?.status === 'ACTIVE' ? 'actions-deactivate-employee' : 'actions-activate-employee'}`} onClick={() => handleToggle({ name: 'status', state: statusActions, data: { id: item._id, status: item.status } })}>
                                        {item?.status === 'ACTIVE' ? (
                                          <>
                                            <span className="icon">
                                              <img alt="Delete" src="/assets/icons/deactivate-user.svg" />
                                            </span>
                                            <span className="text">
                                              <strong>DEAKTIVIRAJ</strong>
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <span className="icon">
                                              <img alt="Delete" src="/assets/icons/activate-user.svg" />
                                            </span>
                                            <span className="text">
                                              <strong>AKTIVIRAJ</strong>
                                            </span>
                                          </>
                                        )}
                                      </button>
                                    )}
                                  </div>
                                )}

                                {getGroceryPermissions(groceryPermissions, PERMISSIONS.deleteEmployees) && (
                                  <button className="btn btn-actions" type="button" data-cy="actions-delete-employee" onClick={() => handleToggle({ name: 'delete', state: deleteActions, data: item._id })}>
                                    <span className="icon">
                                      <img alt="Delete" src="/assets/icons/delete.svg" />
                                    </span>
                                    <span className="text">
                                      <strong>IZBRIŠI</strong>
                                    </span>
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      >
                        <button className="btn" type="button" data-cy="actions-toggle-employees">
                          <span className="icon">
                            <img className="d-block pl-1 pr-1" alt="Dots" src="/assets/icons/dots-y.svg" />
                          </span>
                        </button>
                      </Popover>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data && data.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
