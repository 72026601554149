import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback
} from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../config/index';
import { useAuth } from '../context/useAuth';
import ProtectedRoute from './ProtectedRouter';
import jwt_decode from 'jwt-decode';

// Auth
import Login from '../pages/auth/Login';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';

// Catch page
import Home from '../pages/home-page/Home';
import Profile from '../pages/profile-page/Profile';
import NotFoundError from '../pages/error-page/NotFoundError';

// Component pages - GROCERY
import RolesGrocery from '../pages/grocery/roles-page/Roles';
import EmployeesGrocery from '../pages/grocery/employees-page/Employees';
import CustomersGrocery from '../pages/grocery/customers-page/Customers';
import ActivityGrocery from '../pages/grocery/activity-page/Activity';
import CategoriesGrocery from '../pages/grocery/categories-page/Categories';
import AttributesGrocery from '../pages/grocery/attributes-page/Attributes';
import OrdersGrocery from '../pages/grocery/orders-page/Orders';
import OrderGroceryDetails from '../pages/grocery/order-details-page/OrderDetails';
import GalleryGrocery from '../pages/grocery/gallery-page/Gallery';
import ProductsGrocery from '../pages/grocery/products-page/Products';
import ProductGroceryCreate from '../pages/grocery/products-page/create-product/CreateProduct';
import ProductGroceryUpdate from '../pages/grocery/products-page/update-product/UpdateProduct';
import Blogs from '../pages/grocery/blog/Blog';
import CreateBlog from '../pages/grocery/blog/CreateBlog';
import CreateCategory from '../pages/grocery/categories-page/CategoriesForm';
import CreateTierTwoCategory from '../pages/grocery/categories-page/TierTwoCategoryForm';
import CreateTierThreeCategory from '../pages/grocery/categories-page/TierThreeCategoryForm';
import UpdateBlog from '../pages/grocery/blog/UpdateBlog/UpdateBlog';
import AboutUs from '../pages/grocery/about-us/AboutUs';
import Terms from '../pages/grocery/terms-of-service/TermsOfService';
import Delivery from '../pages/grocery/delivery/Delivery';
import Cookies from '../pages/grocery/cookies/Cookies';
import Newsletter from '../pages/grocery/newsletter-page/Newsletter';
import SearchPage from '../pages/grocery/search-page/SearchPage';

import Requests from '../pages/grocery/shopwindow/Requests';
import AnswerRequest from '../pages/grocery/shopwindow/AnswerRequest/AnswerRequest';

// AUTH VERIFICATION APP
import { getGroceryPermissions } from '../middleware';
import { AXIOS_API_CALL } from '../utils/endpoint';
import { PERMISSIONS, USER } from '../utils/permissions';
import EmployeeEmailVerification from '../pages/auth/EmployeeEmailVerification';
import PrivacyPolicy from '../pages/grocery/privacy-policy/PrivacyPolicy';
import HeroBaners from '../pages/grocery/hero-baners/HeroBaners';
import UserSearchLogs from '../pages/grocery/search-page/userId';

const Router = props => {
  const { pathname } = props;
  const { user, refetchToken } = useAuth();
  const location = useLocation();

  const getUserTokenStatus = async () => {
    const checkUser = window.sessionStorage.getItem(USER);

    if (
      !checkUser ||
      checkUser.includes('null') ||
      checkUser.includes('undefined')
    ) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      return false;
    }

    const jsonToken = JSON.parse(checkUser);

    if (!jsonToken) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;

      return false;
    }

    try {
      const fetchToken = await axios.post(
        `${SERVER_URL}/${AXIOS_API_CALL.userCheckToken}`,
        null,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${jsonToken.token}` }
        }
      );

      if (fetchToken.status !== 200) {
        window.sessionStorage.setItem(USER, JSON.stringify(null));
        window.location.href = `/${PERMISSIONS.login}`;
      }
    } catch (err) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
    }
  };

  // TOKEN VALIDATION
  useEffect(() => {
    if (user && user.token) {
      getUserTokenStatus();
    }
  }, [user, location, refetchToken]);

  // CHECK USER SESSION STORAGE
  useEffect(() => {
    const checkUser = window.sessionStorage.getItem(USER);
    if (!checkUser) {
      window.sessionStorage.setItem(USER, JSON.stringify(null));
      window.location.href = `/${PERMISSIONS.login}`;
      window.location.reload();
    }
  }, [location]);

  // CHANGE OFFFICAL COLOR
  useLayoutEffect(() => {
    if (
      location.pathname.includes(
        `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`
      )
    ) {
      document.documentElement.style.setProperty('--official-color', '#6ad4a6'); // 59%
    }

    if (location.pathname.includes(`/${PERMISSIONS.login}`)) {
      document.documentElement.style.setProperty('--official-color', '#6ad4a6'); // 59%
    }
  }, [location]);

  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    if (user && user.token) {
      const token = user.token;
      const decodeToken = jwt_decode(token);

      const isCheckRoleData =
        decodeToken && decodeToken.hasOwnProperty('roleData');
      if (isCheckRoleData) {
        const permissions = decodeToken.roleData?.permissions;

        if (
          location.pathname.includes(
            `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`
          )
        ) {
          if (
            Object.keys(permissions).some(permission =>
              permission.includes(PERMISSIONS.grocery)
            )
          ) {
            setGroceryPermissions(permissions.grocery);
          }
        }
      }
    }
  }, [user, location]);

  const getLocationPathname = useCallback(
    path => {
      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${path}`))
        return true;
      return false;
    },
    [location]
  );

  return (
    <Routes>
      {/* public routes */}
      <Route
        index
        element={<Navigate to={`/${PERMISSIONS.login}`} replace />}
      />
      <Route path={`/${PERMISSIONS.login}`} element={<Login />} />
      <Route
        path={`/${PERMISSIONS.forgotPassword}`}
        element={<ForgotPassword />}
      />
      <Route
        path={`/${PERMISSIONS.passwordReset}/:id`}
        element={<ResetPassword />}
      />
      <Route
        path={`/${PERMISSIONS.employeeEmailVerification}/:token`}
        element={<EmployeeEmailVerification />}
      />

      {/* protected routes */}
      <Route
        element={
          <ProtectedRoute
            pathname={pathname}
            redirectPath={`/${PERMISSIONS.login}`}
          />
        }
      >
        <Route
          index
          path={`/${PERMISSIONS.dashboard}`}
          element={
            <Navigate
              to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`}
              replace
            />
          }
        />

        {/* GROCERY */}
        {getLocationPathname(PERMISSIONS.grocery) ? (
          <>
            <Route
              index
              path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`}
              element={<Home />}
            />
            <Route
              path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.profile}`}
              element={<Profile />}
            />
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readRoles
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.roles}`}
                element={<RolesGrocery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readCustomers
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.customers}`}
                element={<CustomersGrocery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readEmployees
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.employees}`}
                element={<EmployeesGrocery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.searchPage}`}
                element={<SearchPage />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.searchPage}/:userId`}
                element={<UserSearchLogs />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readActivity
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.activity}`}
                element={<ActivityGrocery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readCategories
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.categories}`}
                element={<CategoriesGrocery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readAttributes
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.attributes}`}
                element={<AttributesGrocery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`}
                element={<OrdersGrocery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}/:id`}
                element={<OrderGroceryDetails />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readGallery
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.gallery}`}
                element={<GalleryGrocery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readProducts
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.products}`}
                element={<ProductsGrocery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.createProducts
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createProduct}`}
                element={<ProductGroceryCreate />}
              />
            )}

            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.blogs}`}
                element={<Blogs />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createBlog}`}
                element={<CreateBlog />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createCategories}`}
                element={<CreateCategory />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createTierTwoCategories}`}
                element={<CreateTierTwoCategory />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createTierThreeCategories}`}
                element={<CreateTierThreeCategory />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.updateProducts
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateBlog}/:id`}
                element={<UpdateBlog />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.updateProducts
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.aboutUs}`}
                element={<AboutUs />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.updateProducts
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.terms}`}
                element={<Terms />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.updateProducts
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.privacy}`}
                element={<PrivacyPolicy />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.updateProducts
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.delivery}`}
                element={<Delivery />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.updateProducts
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.cookies}`}
                element={<Cookies />}
              />
            )}

            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.baners}`}
                element={<HeroBaners />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.updateProducts
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.updateProduct}/:id`}
                element={<ProductGroceryUpdate />}
              />
            )}

            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.shopwindowrequests}`}
                element={<Requests />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.newsletter}`}
                element={<Newsletter />}
              />
            )}
            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.readOrders
            ) && (
              <Route
                path={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.answerShopWindowRequest}/:id`}
                element={<AnswerRequest />}
              />
            )}
          </>
        ) : null}

        {/* catch all */}
        <Route path="*" element={<NotFoundError />} />
      </Route>
    </Routes>
  );
};

export default Router;
