import { Input, Form, Button, notification, Upload, Modal } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { PERMISSIONS } from '../../../utils/permissions';

const HeroBanersForm = ({ data, isNew }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  let transformedData;
  if (data) {
    transformedData = data.images.map((image, index) => ({
      image,
      altText: data.altText[index]
    }));
  }
  const [herobaners, setHeroBaners] = useState(data ? transformedData : []);
  const [images, setImages] = useState([]);
  const [reRender, setReRender] = useState(false);
  const [isVisible, setIsVisibleModal] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState();

  const initialValues = data && data.length > 0 ? { ...data[0] } : null;

  const fetchImages = async () => {
    await Axios.get(`${SERVER_URL}/images/`, {
      withCredentials: false,
      headers: {
        department: PERMISSIONS.grocery,
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      if (res.status === 200) {
        setImages(res?.data);
      }
    });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const onFinish = async values => {
    if (herobaners && herobaners.length > 0) {
      for (let i = 0; i < herobaners.length; i++) {
        if (!herobaners[i].image) {
          notification.error({
            message: 'Item must have an image.',
            placement: 'topRight'
          });
          return;
        }
      }
    }
    if (isNew) {
      const res = await Axios.post(`${SERVER_URL}/hero-baners`, herobaners, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      const res = await Axios.put(`${SERVER_URL}/hero-baners`, herobaners, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    notification.success({
      message: `Hero baners successfully ${isNew ? 'created.' : 'updated.'}`,
      placement: 'topRight'
    });

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const handleOpenModal = index => {
    setIsVisibleModal(true);
    setSelectedItemIndex(index);
  };

  const handleCancel = () => {
    setIsVisibleModal(false);
  };

  const handleSelectImage = item => {
    let arr = herobaners;

    arr[selectedItemIndex].image = item;

    setHeroBaners(arr);
    setIsVisibleModal(false);
  };

  const handleDeleteItemFromAdding = index => {
    let arr = herobaners;

    arr.splice(index, 1);

    setHeroBaners(arr);

    setReRender(!reRender);
  };

  const handleChangeInput = (e, index) => {
    let arr = herobaners;

    arr[index].altText = e.target.value;

    setHeroBaners(arr);
    setReRender(!reRender);
  };

  return (
    <div className="title-container">
      <Form
        initialValues={initialValues}
        onFinish={values => onFinish(values)}
        layout="vertical"
        form={form}
      >
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <p>{isNew ? 'KREIRAJ' : 'AŽURIRAJ'} BANERE</p>
        </div>

        <Button
          onClick={() => {
            setHeroBaners([...herobaners, { altText: '' }]);
          }}
          disabled={herobaners?.length > 6 ? true : false}
          className="btn btn-primary pl-4 pr-4 d-flex items-center align-center"
        >
          Dodaj baner
        </Button>

        <div className="hero-baners-row">
          {herobaners &&
            herobaners.length > 0 &&
            herobaners.map((item, index) => {
              return (
                <div className="heroBanerItem">
                  <Button
                    onClick={() => {
                      handleOpenModal(index);
                    }}
                  >
                    Izaberi sliku
                  </Button>
                  {item && item.image && item.image.url && (
                    <img
                      src={item.image.url}
                      className="item-hero-baner"
                      alt="item-image"
                    ></img>
                  )}
                  {item && !item.image && (
                    <img
                      className="item-hero-baner"
                      src="/assets/icons/add-image.svg"
                      alt="item-image"
                    ></img>
                  )}

                  <Input
                    onChange={e => {
                      handleChangeInput(e, index);
                    }}
                    placeholder="alt text"
                    value={item.altText}
                  ></Input>
                  <Button
                    onClick={() => {
                      handleDeleteItemFromAdding(index);
                    }}
                  >
                    Izbriši stavku
                  </Button>
                </div>
              );
            })}
        </div>

        <Modal
          width={'50%'}
          title="Select image from gallery"
          open={isVisible}
          footer={null}
          onCancel={handleCancel}
          className="image-gallery-modal"
        >
          <div className="image-grid">
            {images &&
              images.length > 0 &&
              images.map((item, index) => (
                <div key={item._id} className="image-container">
                  <img
                    onClick={() => handleSelectImage(item)}
                    src={item.url}
                    alt={item.originalname}
                    className="image-item"
                  />
                </div>
              ))}
          </div>
        </Modal>

        <Button
          style={{ marginTop: '15px' }}
          htmlType="submit"
          className="btn btn-primary pl-4 pr-4 d-flex items-center align-center addnewbutton"
        >
          {isNew ? 'KREIRAJ' : 'AŽURIRAJ'} BANERE
        </Button>
      </Form>
    </div>
  );
};

export default HeroBanersForm;
