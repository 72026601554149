import { Input, Form, Button, notification, Modal, Upload } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '../../../utils/permissions';

const CreateBlog = props => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  const [desc, setDescription] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blogImageId, setBlogImageId] = useState(undefined);
  const [image, setBlogImage] = useState({});
  const [images, setImages] = useState([]);

  const getTextValue = value => {
    if (value !== desc) {
      setDescription(value);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async values => {
    values.desc = desc;
    values.image = blogImageId;

    await Axios.post(`${SERVER_URL}/blogs`, values, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    notification.success({
      message: 'Blog is created.',
      placement: 'topRight'
    });

    navigate('/admin/grocery/blogs');
  };

  const handleSelectImage = async item => {
    try {
      setBlogImageId(item._id);
      setBlogImage(item);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const fetchImages = async () => {
    await Axios.get(`${SERVER_URL}/images/`, {
      withCredentials: false,
      headers: {
        department: PERMISSIONS.grocery,
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      if (res.status === 200) {
        setImages(res?.data);
      }
    });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleDeleteSelectedImage = () => {
    setBlogImage(null);
    setBlogImageId(null);
  };

  return (
    <div
      className="title-container"
      style={{ margin: '20px auto', padding: '20px' }}
    >
      <Form onFinish={values => onFinish(values)} layout="vertical" form={form}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
          DODAJ NOVI BLOG
        </h2>

        <div
          className="nav-links"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px'
          }}
        >
          <p>DODAJ NOVI BLOG</p>
          <Link to={'/admin/grocery/blogs'}>SVI BLOGOVI</Link>
        </div>

        <div className="blog-row">
          <label>Naslov:</label>
          <Form.Item name="title">
            <Input type="text" placeholder="title" />
          </Form.Item>
        </div>

        <div className="blog-row">
          <label>Meta Naslov:</label>
          <Form.Item name="metaTitle">
            <Input type="text" placeholder="metaTitle" />
          </Form.Item>
        </div>

        <div className="blog-row">
          <label>Meta Opis:</label>
          <Form.Item name="metaDesc">
            <Input type="text" placeholder="metaDesc" />
          </Form.Item>
        </div>

        <div className="blog-row">
          <label>Odaberi sliku:</label>
          <Form.Item name="image">
            <Button
              style={{ minHeight: '10px', borderRadius: '10px' }}
              onClick={() => {
                setIsModalVisible(true);
              }}
              type="primary"
            >
              Otvori Galeriju
            </Button>
          </Form.Item>
        </div>

        <div className="selected-blogimage-container">
          {image && image.url && image.originalname && (
            <div
              onClick={() => {
                handleDeleteSelectedImage();
              }}
              className="blogimage-container"
            >
              <label>Odabrana Slika:</label>
              <img
                className="blogimage"
                src={image.url}
                alt={image.originalname}
              />
              <p className="blogimage-deleteicon">
                <DeleteOutlined></DeleteOutlined>
              </p>
            </div>
          )}
        </div>

        <div className="blog-row">
          <label>Sadržaj:</label>
          <Editor
            apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
            onEditorChange={(newValue, editor) =>
              getTextValue(editor.getContent())
            }
            init={{
              height: 600,
              menubar: true,
              plugins: 'table'
            }}
          />
        </div>

        <Button
          htmlType="submit"
          className="btn btn-primary addnewbutton"
          style={{ marginTop: '20px' }}
        >
          Dodaj Blog
        </Button>

        <Modal
          width={'50%'}
          title="Select image from gallery"
          open={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          className="image-gallery-modal"
        >
          <div className="image-grid">
            {images &&
              images.length > 0 &&
              images.map((item, index) => (
                <div key={item._id} className="image-container">
                  <img
                    onClick={() => handleSelectImage(item)}
                    src={item.url}
                    alt={item.originalname}
                    className="image-item"
                  />
                </div>
              ))}
          </div>
        </Modal>
      </Form>
    </div>
  );
};

export default CreateBlog;
