import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import { Section, Main } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import { PERMISSIONS } from '../../../utils/permissions';
import { notification } from 'antd';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import Table from './Table';
import Loading from '../../../components/loading/Loading';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import {
  emailIsValid,
  numberIsValid,
  upperCaseIsValid,
  specialCharIsValid,
  uuidv4
} from '../../../helpers/helpers';
import { getGroceryPermissions } from '../../../middleware';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { CreateForm } from './create-form/CreateForm';
import { DeleteForm } from './delete-form/DeleteForm';
import { SingleStatusDeactivate } from './single-status-deactivate/SingleStatusDeactivate';
import { BulkStatusDeactivate } from './bulk-status-deactivate/BulkStatusDeactivate';
import { UpdateForm } from './update-form/UpdateForm';
import { DeleteInUpdateAddress } from './delete-in-update-address/DeleteInUpdateAddress';

const options = [5, 10, 20, 50, 100];

const Customers = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (
        location.pathname.includes(
          `/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`
        )
      ) {
        if (
          Object.keys(permissions).some(permission =>
            permission.includes(PERMISSIONS.grocery)
          )
        ) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [user, location]);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [filterStatus, setFilterStatus] = useState(null);

  const handleOnSubmitSearch = e => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch(prevState => !prevState);
  };

  const handleOnChangeSearch = value => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setReadDataRefetch(prevState => !prevState);
  };

  // LOADER PROTECTION
  const [editBtnLoader, setEditBtnLoader] = useState(false);
  const [deactivateBtnLoader, setDeactivateBtnLoader] = useState(false);
  const [deactivateBlkBtnLoader, setDeactivateBlkBtnLoader] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  const [deleteAddressBtnLoader, setDeleteAddressBtnLoader] = useState(false);

  // PAGE
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);

  // FILTER STATUS
  const handleStatusFilter = value => {
    setFilterStatus(value);
    setReadDataRefetch(prevState => !prevState);
  };

  // FILTERS
  const [disabledButton, setDisabledButton] = useState(false);
  const handleDisabledButton = status => {
    setDisabledButton(status);
  };

  // Get selected rows id one or more
  const [checkedRow, setCheckedRow] = useState([]);
  const handleCheckedRows = data => {
    setCheckedRow(data);
  };

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(async () => {
    const { token } = user;

    try {
      const response = await axios
        .post(
          `${SERVER_URL}/${
            AXIOS_API_CALL.getCustomers
          }/${currentPage}/${limitPage}/${search || null}/${filterStatus ||
            null}`,
          { department: PERMISSIONS.grocery },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        )
        .then(res => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);
          return res;
        })
        .catch(err => {
          setReadDataLoading(false);
          console.error(err);
        });

      return response;
    } catch (err) {}
  }, [user, limitPage, search, currentPage, filterStatus]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then(res => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search, setCurrentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage, setCurrentPage]);

  // CREATE
  const createModalFormRef = useRef(null);
  const [toggleCreate, setToggleCreate] = useState(false);
  const [toggleCreateAddress, setToggleCreateAddress] = useState(false);
  const [toggleCreatePassword, setToggleCreatePassword] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [createForm, setCreateForm] = useState({
    createFirstName: '',
    createLastName: '',
    createEmail: '',
    createAddress: '',
    createZipCode: '',
    createCity: '',
    createState: 'none',
    createPhone: '',
    createPassword: '',
    createStatus: 'none'
  });

  const [createFormCheckPassword, setCreateFormCheckPassword] = useState({
    passLength: false,
    passUppercase: false,
    passNumber: false,
    passSpecialChar: false
  });

  const [createFormError, setCreateFormError] = useState({
    createFirstNameError: false,
    createLastNameError: false,
    createEmailError: false,
    createAddressError: false,
    createZipCodeError: false,
    createCityError: false,
    createStateError: false,
    createPhoneError: false,
    createPasswordError: false,
    createStatusError: false
  });

  const [createFormEmail, setCreateFormEmail] = useState(false);

  const [addresses, setAddresses] = useState([]);
  const [createFormAddress, setCreateFormAddress] = useState({
    address: '',
    zip_code: '',
    city: '',
    state: ''
  });

  useEffect(() => {
    if (!!toggleCreate) {
      setToggleCreateAddress(false);
    }
  }, [toggleCreate]);

  // BULK DEACTIVATE
  const deactiveModalFormRef = useRef(null);
  const [toggleBulkDeactive, setToggleBulkDeactive] = useState(false);

  // SINGLE DEACTIVE
  const deactiveSingleModalFormRef = useRef(null);
  const [toggleSingleDeactive, setToggleSingleDeactive] = useState(false);
  const [singleUpdateId, setSingleUpdateId] = useState({ id: '', status: '' });

  // UPDATE
  const updateModalFormRef = useRef(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [toggleUpdateAddress, setToggleUpdateAddress] = useState(false);
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const [toggleUpdatePassword, setToggleUpdatePassword] = useState(false);
  const [updateId, setUpdateId] = useState({ id: '' });
  const [updateData, setUpdateData] = useState([]);
  const [updateFormError, setUpdateFormError] = useState({
    updateFirstNameError: false,
    updateLastNameError: false,
    updateEmailError: false,
    updateAddressError: false,
    updateZipCodeError: false,
    updateCityError: false,
    updateStateError: false,
    updatePhoneError: false,
    updatePasswordError: false,
    updateStatusError: false
  });

  const [updateFormCheckPassword, setUpdateFormCheckPassword] = useState({
    passLength: false,
    passUppercase: false,
    passNumber: false,
    passSpecialChar: false
  });

  useEffect(() => {
    if (!!toggleUpdate) {
      setToggleUpdateAddress(false);
    }
  }, [toggleUpdate]);

  useEffect(() => {
    const id = updateId.id;

    if (!!id) {
      const { token } = user;

      try {
        setUpdateLoading(true);
        axios
          .post(
            `${SERVER_URL}/${AXIOS_API_CALL.getCustomers}/${id}`,
            { department: PERMISSIONS.grocery },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then(res => {
            setUpdateData(res.data?.data);
            setTimeout(() => {
              setUpdateLoading(false);
            }, 700);
          })
          .catch(err => {
            console.error(err);
            setUpdateLoading(false);
          });
      } catch (err) {
        console.error(err);
        setUpdateLoading(false);
      }
    }
  }, [user, setUpdateLoading, setUpdateData, updateId]);

  const [sleepAddress, setSleepAddress] = useState({
    updateAddress: '',
    updateZipCode: '',
    updateCity: '',
    updateState: ''
  });

  const [sleep, setSleep] = useState({
    updateFirstName: '',
    updateLastName: '',
    updateEmail: '',
    updatePhone: '',
    updateStatus: '',
    updatePassword: '',
    address: []
  });

  const [updateAddress, setUpdateAddress] = useState([]);

  useEffect(() => {
    if (
      updateData &&
      updateData[0]?.address &&
      updateData[0]?.address.length > 0
    ) {
      setUpdateAddress(updateData[0]?.address);
    } else {
      setUpdateAddress([]);
    }
  }, [updateData, setUpdateAddress]);

  useEffect(() => {
    if (updateData && updateData.length > 0) {
      let newData = {
        updateFirstName: updateData[0].firstName,
        updateLastName: updateData[0].lastName,
        updateEmail: updateData[0].email,
        updatePhone: updateData[0].phone.toString(),
        updateStatus: updateData[0].status.toLowerCase(),
        updatePassword: ''
      };

      if (updateData && updateData[0]?.address.length > 0) {
        const addresses = updateData[0].address.map(item => item);

        newData = {
          ...newData,
          address: addresses
        };
      }

      setSleep(newData);
    }
  }, [updateData]);

  // DELETE
  const deleteModalFormRef = useRef(null);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deleteId, setDeleteId] = useState({ id: '', email: '' });

  useEffect(() => {
    if (createForm.createPassword.length >= 8) {
      setCreateFormCheckPassword(prevState => {
        return { ...prevState, passLength: true };
      });
    } else {
      setCreateFormCheckPassword(prevState => {
        return { ...prevState, passLength: false };
      });
    }

    if (upperCaseIsValid(createForm.createPassword)) {
      setCreateFormCheckPassword(prevState => {
        return { ...prevState, passUppercase: true };
      });
    } else {
      setCreateFormCheckPassword(prevState => {
        return { ...prevState, passUppercase: false };
      });
    }

    if (numberIsValid(createForm.createPassword)) {
      setCreateFormCheckPassword(prevState => {
        return { ...prevState, passNumber: true };
      });
    } else {
      setCreateFormCheckPassword(prevState => {
        return { ...prevState, passNumber: false };
      });
    }

    if (specialCharIsValid(createForm.createPassword)) {
      setCreateFormCheckPassword(prevState => {
        return { ...prevState, passSpecialChar: true };
      });
    } else {
      setCreateFormCheckPassword(prevState => {
        return { ...prevState, passSpecialChar: false };
      });
    }
  }, [createForm]);

  useEffect(() => {
    if (toggleNewPassword) {
      if (sleep.updatePassword.length >= 8) {
        setUpdateFormCheckPassword(prevState => {
          return { ...prevState, passLength: true };
        });
      } else {
        setUpdateFormCheckPassword(prevState => {
          return { ...prevState, passLength: false };
        });
      }
      if (upperCaseIsValid(sleep.updatePassword)) {
        setUpdateFormCheckPassword(prevState => {
          return { ...prevState, passUppercase: true };
        });
      } else {
        setUpdateFormCheckPassword(prevState => {
          return { ...prevState, passUppercase: false };
        });
      }
      if (numberIsValid(sleep.updatePassword)) {
        setUpdateFormCheckPassword(prevState => {
          return { ...prevState, passNumber: true };
        });
      } else {
        setUpdateFormCheckPassword(prevState => {
          return { ...prevState, passNumber: false };
        });
      }
      if (specialCharIsValid(sleep.updatePassword)) {
        setUpdateFormCheckPassword(prevState => {
          return { ...prevState, passSpecialChar: true };
        });
      } else {
        setUpdateFormCheckPassword(prevState => {
          return { ...prevState, passSpecialChar: false };
        });
      }
    }
  }, [sleep, toggleNewPassword]);

  /**
   * Handle toggle function
   * @param {*} props
   */
  function handleToggle(props) {
    const { name, state, data } = props;

    switch (name) {
      case 'create':
        state.onToggle(true);
        break;
      case 'bulk-deactivate':
        state.onToggle(true);
        break;
      case 'status':
        state.onToggle(true);
        state.onSetId({ id: data.id, status: data.status });
        break;
      case 'update':
        state.onToggle(true);
        state.onSetId({ id: data });
        break;
      case 'delete':
        state.onToggle(true);
        state.onSetId({ id: data.id, email: data.email });
        break;
      default:
        console.warn('Default of: handleToggle function');
    }
  }

  function handleOnValidation(props) {
    const { name, form } = props;

    let checkCase = false;

    // create
    let checkCreateFirstName = false;
    let checkCreateLastName = false;
    let checkCreateEmail = false;
    let checkCreatePhone = false;
    let checkCreatePassword = false;
    let checkCreateStatus = false;

    // update
    let checkUpdateFirstName = false;
    let checkUpdateLastName = false;
    let checkUpdateEmail = false;
    let checkUpdatePhone = false;
    let checkUpdatePassword = false;
    let checkUpdateStatus = false;
    let checkToggleUpdatePassword = false;

    switch (name) {
      case 'create':
        if (form.createFirstName.length >= 2) {
          checkCreateFirstName = true;
          setCreateFormError(prevState => {
            return { ...prevState, createFirstNameError: false };
          });
        } else {
          checkCreateFirstName = false;
          setCreateFormError(prevState => {
            return { ...prevState, createFirstNameError: true };
          });
        }

        if (form.createLastName.length >= 2) {
          checkCreateLastName = true;
          setCreateFormError(prevState => {
            return { ...prevState, createLastNameError: false };
          });
        } else {
          checkCreateLastName = false;
          setCreateFormError(prevState => {
            return { ...prevState, createLastNameError: true };
          });
        }

        if (toggleCreate) {
          if (emailIsValid(form.createEmail)) {
            checkCreateEmail = true;
            setCreateFormError(prevState => {
              return { ...prevState, createEmailError: false };
            });
          } else {
            checkCreateEmail = false;
            setCreateFormError(prevState => {
              return { ...prevState, createEmailError: true };
            });
          }
        }

        if (form.createPhone.length >= 3 && numberIsValid(form.createPhone)) {
          checkCreatePhone = true;
          setCreateFormError(prevState => {
            return { ...prevState, createPhoneError: false };
          });
        } else {
          checkCreatePhone = false;
          setCreateFormError(prevState => {
            return { ...prevState, createPhoneError: true };
          });
        }

        if (form.createStatus !== 'none') {
          checkCreateStatus = true;
          setCreateFormError(prevState => {
            return { ...prevState, createStatusError: false };
          });
        } else {
          checkCreateStatus = false;
          setCreateFormError(prevState => {
            return { ...prevState, createStatusError: true };
          });
        }

        if (toggleCreate) {
          if (
            form.createPassword.length >= 8 &&
            upperCaseIsValid(form.createPassword) &&
            numberIsValid(form.createPassword) &&
            specialCharIsValid(form.createPassword)
          ) {
            checkCreatePassword = true;
            setCreateFormError(prevState => {
              return { ...prevState, createPasswordError: false };
            });
          } else {
            checkCreatePassword = false;
            setCreateFormError(prevState => {
              return { ...prevState, createPasswordError: true };
            });
          }
        }

        if (
          !checkCreateFirstName &&
          !checkCreateLastName &&
          !checkCreateEmail &&
          !checkCreatePhone &&
          !checkCreateStatus &&
          !checkCreatePassword
        ) {
          checkCase = false;
        }

        if (
          checkCreateFirstName &&
          checkCreateLastName &&
          checkCreateEmail &&
          checkCreatePhone &&
          checkCreateStatus &&
          checkCreatePassword
        ) {
          checkCase = true;
        }

        break;
      case 'update':
        if (form.updateFirstName.length >= 2) {
          checkUpdateFirstName = true;
          setUpdateFormError(prevState => {
            return { ...prevState, updateFirstNameError: false };
          });
        } else {
          checkUpdateFirstName = false;
          setUpdateFormError(prevState => {
            return { ...prevState, updateFirstNameError: true };
          });
        }

        if (form.updateLastName.length >= 2) {
          checkUpdateLastName = true;
          setUpdateFormError(prevState => {
            return { ...prevState, updateLastNameError: false };
          });
        } else {
          checkUpdateLastName = false;
          setUpdateFormError(prevState => {
            return { ...prevState, updateLastNameError: true };
          });
        }

        if (toggleUpdate) {
          if (emailIsValid(form.updateEmail)) {
            checkUpdateEmail = true;
            setUpdateFormError(prevState => {
              return { ...prevState, updateEmailError: false };
            });
          } else {
            checkUpdateEmail = false;
            setUpdateFormError(prevState => {
              return { ...prevState, updateEmailError: true };
            });
          }
        }

        if (form.updatePhone.length >= 3 && numberIsValid(form.updatePhone)) {
          checkUpdatePhone = true;
          setUpdateFormError(prevState => {
            return { ...prevState, updatePhoneError: false };
          });
        } else {
          checkUpdatePhone = false;
          setUpdateFormError(prevState => {
            return { ...prevState, updatePhoneError: true };
          });
        }

        if (form.updateStatus !== '' && form.updateStatus !== 'none') {
          checkUpdateStatus = true;
          setUpdateFormError(prevState => {
            return { ...prevState, updateStatusError: false };
          });
        } else {
          checkUpdateStatus = false;
          setUpdateFormError(prevState => {
            return { ...prevState, updateStatusError: true };
          });
        }

        if (toggleNewPassword) {
          checkToggleUpdatePassword = true;
        } else {
          checkToggleUpdatePassword = false;
        }

        if (checkToggleUpdatePassword) {
          if (
            form.updatePassword.length >= 8 &&
            upperCaseIsValid(form.updatePassword) &&
            numberIsValid(form.updatePassword) &&
            specialCharIsValid(form.updatePassword)
          ) {
            checkUpdatePassword = true;
            setUpdateFormError(prevState => {
              return { ...prevState, updatePasswordError: false };
            });
          } else {
            checkUpdatePassword = false;
            setUpdateFormError(prevState => {
              return { ...prevState, updatePasswordError: true };
            });
          }
        } else {
          checkUpdatePassword = true;
        }

        if (
          !checkUpdateFirstName &&
          !checkUpdateLastName &&
          !checkUpdateEmail &&
          !checkUpdatePhone &&
          !checkUpdateStatus &&
          !checkUpdatePassword
        ) {
          checkCase = false;
        }

        if (
          checkUpdateFirstName &&
          checkUpdateLastName &&
          checkUpdateEmail &&
          checkUpdatePhone &&
          checkUpdateStatus &&
          checkUpdatePassword
        ) {
          checkCase = true;
        } else {
          setEditBtnLoader(false);
        }

        break;
      default:
        console.warn('Dafault of: handleOnValidation function');
    }

    return checkCase;
  }

  function handleOnBlur(event) {
    const { name, value } = event.target;

    switch (name) {
      case 'createFirstName':
        if (value.length >= 2) {
          setCreateFormError({
            ...createFormError,
            createFirstNameError: false
          });
        } else {
          toggleCreate
            ? setCreateFormError({
                ...createFormError,
                createFirstNameError: true
              })
            : setCreateFormError({
                ...createFormError,
                createFirstNameError: false
              });
        }
        break;
      case 'createLastName':
        if (value.length >= 2) {
          setCreateFormError({
            ...createFormError,
            createLastNameError: false
          });
        } else {
          toggleCreate
            ? setCreateFormError({
                ...createFormError,
                createLastNameError: true
              })
            : setCreateFormError({
                ...createFormError,
                createLastNameError: false
              });
        }
        break;
      case 'createEmail':
        if (toggleCreate) {
          if (value.length >= 2 && emailIsValid(value)) {
            setCreateFormError({ ...createFormError, createEmailError: false });
          } else {
            toggleCreate
              ? setCreateFormError({
                  ...createFormError,
                  createEmailError: true
                })
              : setCreateFormError({
                  ...createFormError,
                  createEmailError: false
                });
          }
        }
        break;
      case 'createAddress':
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, createAddressError: false });
        } else {
          toggleCreate && toggleCreateAddress
            ? setCreateFormError({
                ...createFormError,
                createAddressError: true
              })
            : setCreateFormError({
                ...createFormError,
                createAddressError: false
              });
        }
        break;
      case 'createZipCode':
        if (value.length >= 2 && numberIsValid(value)) {
          setCreateFormError({ ...createFormError, createZipCodeError: false });
        } else {
          toggleCreate && toggleCreateAddress
            ? setCreateFormError({
                ...createFormError,
                createZipCodeError: true
              })
            : setCreateFormError({
                ...createFormError,
                createZipCodeError: false
              });
        }
        break;
      case 'createCity':
        if (value.length >= 2) {
          setCreateFormError({ ...createFormError, createCityError: false });
        } else {
          toggleCreate && toggleCreateAddress
            ? setCreateFormError({ ...createFormError, createCityError: true })
            : setCreateFormError({
                ...createFormError,
                createCityError: false
              });
        }
        break;
      case 'createState':
        if (value.length >= 2 && value !== 'none') {
          setCreateFormError({ ...createFormError, createStateError: false });
        } else {
          toggleCreate && toggleCreateAddress
            ? setCreateFormError({ ...createFormError, createStateError: true })
            : setCreateFormError({
                ...createFormError,
                createStateError: false
              });
        }
        break;
      case 'createPhone':
        if (value.length >= 2 && numberIsValid(value)) {
          setCreateFormError({ ...createFormError, createPhoneError: false });
        } else {
          toggleCreate
            ? setCreateFormError({ ...createFormError, createPhoneError: true })
            : setCreateFormError({
                ...createFormError,
                createPhoneError: false
              });
        }
        break;
      case 'createPassword':
        if (toggleCreate) {
          if (
            value.length >= 8 &&
            upperCaseIsValid(value) &&
            numberIsValid(value) &&
            specialCharIsValid(value)
          ) {
            setCreateFormError({
              ...createFormError,
              createPasswordError: false
            });
          } else {
            toggleCreate
              ? setCreateFormError({
                  ...createFormError,
                  createPasswordError: true
                })
              : setCreateFormError({
                  ...createFormError,
                  createPasswordError: false
                });
          }
        }
        break;
      case 'createStatus':
        if (value !== 'none') {
          setCreateFormError({ ...createFormError, createStatusError: false });
        } else {
          toggleCreate
            ? setCreateFormError({
                ...createFormError,
                createStatusError: true
              })
            : setCreateFormError({
                ...createFormError,
                createStatusError: false
              });
        }
        break;
      case 'updateFirstName':
        if (value.length >= 2) {
          setUpdateFormError({
            ...updateFormError,
            updateFirstNameError: false
          });
        } else {
          toggleUpdate
            ? setUpdateFormError({
                ...updateFormError,
                updateFirstNameError: true
              })
            : setUpdateFormError({
                ...updateFormError,
                updateFirstNameError: false
              });
        }
        break;
      case 'updateLastName':
        if (value.length >= 2) {
          setUpdateFormError({
            ...updateFormError,
            updateLastNameError: false
          });
        } else {
          toggleUpdate
            ? setUpdateFormError({
                ...updateFormError,
                updateLastNameError: true
              })
            : setUpdateFormError({
                ...updateFormError,
                updateLastNameError: false
              });
        }
        break;
      case 'updateEmail':
        if (toggleUpdate) {
          if (value.length >= 2 && emailIsValid(value)) {
            setUpdateFormError({ ...updateFormError, updateEmailError: false });
          } else {
            toggleUpdate
              ? setUpdateFormError({
                  ...updateFormError,
                  updateEmailError: true
                })
              : setUpdateFormError({
                  ...updateFormError,
                  updateEmailError: false
                });
          }
        }
        break;
      case 'updateAddress':
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, updateAddressError: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({
                ...updateFormError,
                updateAddressError: true
              })
            : setUpdateFormError({
                ...updateFormError,
                updateAddressError: false
              });
        }
        break;
      case 'updateZipCode':
        if (value.length >= 2 && numberIsValid(value)) {
          setUpdateFormError({ ...updateFormError, updateZipCodeError: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({
                ...updateFormError,
                updateZipCodeError: true
              })
            : setUpdateFormError({
                ...updateFormError,
                updateZipCodeError: false
              });
        }
        break;
      case 'updateCity':
        if (value.length >= 2) {
          setUpdateFormError({ ...updateFormError, updateCityError: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, updateCityError: true })
            : setUpdateFormError({
                ...updateFormError,
                updateCityError: false
              });
        }
        break;
      case 'updateState':
        if (value !== 'none') {
          setUpdateFormError({ ...updateFormError, updateStateError: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, updateStateError: true })
            : setUpdateFormError({
                ...updateFormError,
                updateStateError: false
              });
        }
        break;
      case 'updatePhone':
        if (value.length >= 2 && numberIsValid(value)) {
          setUpdateFormError({ ...updateFormError, updatePhoneError: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({ ...updateFormError, updatePhoneError: true })
            : setUpdateFormError({
                ...updateFormError,
                updatePhoneError: false
              });
        }
        break;
      case 'updatePassword':
        if (toggleUpdate && toggleNewPassword) {
          if (
            value.length >= 8 &&
            upperCaseIsValid(value) &&
            numberIsValid(value) &&
            specialCharIsValid(value)
          ) {
            setUpdateFormError({
              ...updateFormError,
              updatePasswordError: false
            });
          } else {
            toggleUpdate
              ? setUpdateFormError({
                  ...updateFormError,
                  updatePasswordError: true
                })
              : setUpdateFormError({
                  ...updateFormError,
                  updatePasswordError: false
                });
          }
        }
        break;
      case 'updateStatus':
        if (value !== 'none') {
          setUpdateFormError({ ...updateFormError, updateStatusError: false });
        } else {
          toggleUpdate
            ? setUpdateFormError({
                ...updateFormError,
                updateStatusError: true
              })
            : setUpdateFormError({
                ...updateFormError,
                updateStatusError: false
              });
        }
        break;
      default:
        console.warn('Default of: handleOnBlur function');
    }
  }

  function handleOnChange(event) {
    const { name, value } = event.target;

    if (toggleUpdate && name.startsWith('update')) {
      if (
        name.includes('updateAddress') ||
        name.includes('updateZipCode') ||
        name.includes('updateCity') ||
        name.includes('updateState')
      ) {
        setSleepAddress({ ...sleepAddress, [name]: value });
      } else {
        setSleep({ ...sleep, [name]: value });
      }
    }

    if (toggleCreate && name.startsWith('create')) {
      setCreateForm({ ...createForm, [name]: value });
    }
  }

  function handleOnSave() {
    let checkAddress = false;
    let checkZipCode = false;
    let checkCity = false;
    let checkState = false;

    if (createFormAddress.address.length >= 2) {
      checkAddress = true;
      setCreateFormError(prevState => {
        return { ...prevState, createAddressError: false };
      });
    } else {
      checkAddress = false;
      setCreateFormError(prevState => {
        return { ...prevState, createAddressError: true };
      });
    }

    if (createFormAddress.zip_code.length >= 2) {
      checkZipCode = true;
      setCreateFormError(prevState => {
        return { ...prevState, createZipCodeError: false };
      });
    } else {
      checkZipCode = false;
      setCreateFormError(prevState => {
        return { ...prevState, createZipCodeError: true };
      });
    }

    if (createFormAddress.city.length >= 2) {
      checkCity = true;
      setCreateFormError(prevState => {
        return { ...prevState, createCityError: false };
      });
    } else {
      checkCity = false;
      setCreateFormError(prevState => {
        return { ...prevState, createCityError: true };
      });
    }

    if (createFormAddress.state !== '' && createFormAddress.state !== 'none') {
      checkState = true;
      setCreateFormError(prevState => {
        return { ...prevState, createStateError: false };
      });
    } else {
      checkState = false;
      setCreateFormError(prevState => {
        return { ...prevState, createStateError: true };
      });
    }

    if (!checkAddress && !checkZipCode && !checkCity && !checkState) {
    }

    if (checkAddress && checkZipCode && checkCity && checkState) {
      setAddresses([...addresses, createFormAddress]);
      setToggleCreateAddress(false);
      setCreateFormAddress({ address: '', zip_code: '', city: '', state: '' });
      setCreateForm(prevState => {
        return {
          ...prevState,
          createAddress: '',
          createZipCode: '',
          createCity: '',
          createState: ''
        };
      });
    }
  }

  function handleOnRemove(id) {
    const removeAddress = addresses.filter(item => item.address_id !== id);
    setAddresses(removeAddress);
  }

  const deleteModalAddresFormRef = useRef(null);
  const [toggleDeleteAddress, setToggleDeleteAddress] = useState(false);
  const [deleteAddressIds, setToggleDeleteIds] = useState({ _id: '', idx: '' });

  function handleOnRemoveUpdate(props) {
    const { _id, index } = props;

    setToggleDeleteIds({ _id: _id, idx: index });
    setToggleDeleteAddress(true);
  }

  function handleOnSaveUpdate() {
    let checkAddress = false;
    let checkZipCode = false;
    let checkCity = false;
    let checkState = false;

    if (sleepAddress.updateAddress.length >= 2) {
      checkAddress = true;
      setUpdateFormError(prevState => {
        return { ...prevState, updateAddressError: false };
      });
    } else {
      checkAddress = false;
      setUpdateFormError(prevState => {
        return { ...prevState, updateAddressError: true };
      });
    }

    if (sleepAddress.updateZipCode.length >= 2) {
      checkZipCode = true;
      setUpdateFormError(prevState => {
        return { ...prevState, updateZipCodeError: false };
      });
    } else {
      checkZipCode = false;
      setUpdateFormError(prevState => {
        return { ...prevState, updateZipCodeError: true };
      });
    }

    if (sleepAddress.updateCity.length >= 2) {
      checkCity = true;
      setUpdateFormError(prevState => {
        return { ...prevState, updateCityError: false };
      });
    } else {
      checkCity = false;
      setUpdateFormError(prevState => {
        return { ...prevState, updateCityError: true };
      });
    }

    if (
      sleepAddress.updateState !== '' &&
      sleepAddress.updateState !== 'none'
    ) {
      checkState = true;
      setUpdateFormError(prevState => {
        return { ...prevState, updateStateError: false };
      });
    } else {
      checkState = false;
      setUpdateFormError(prevState => {
        return { ...prevState, updateStateError: true };
      });
    }

    if (!checkAddress && !checkZipCode && !checkCity && !checkState) {
      console.error('error', sleepAddress);
    }

    if (checkAddress && checkZipCode && checkCity && checkState) {
      const newAddressCreate = {
        address: sleepAddress.updateAddress,
        zip_code: sleepAddress.updateZipCode,
        city: sleepAddress.updateCity,
        state: sleepAddress.updateState
      };

      setUpdateAddress([...updateAddress, newAddressCreate]);
      setToggleUpdateAddress(false);
      setSleepAddress({
        updateAddress: '',
        updateZipCode: '',
        updateCity: '',
        updateState: ''
      });
    }
  }

  useEffect(() => {
    if (createForm.createAddress.length >= 2) {
      setCreateFormAddress(prevState => {
        return { ...prevState, address: createForm.createAddress };
      });
    } else {
      setCreateFormAddress(prevState => {
        return { ...prevState, address: '' };
      });
    }

    if (createForm.createZipCode.length >= 2) {
      setCreateFormAddress(prevState => {
        return { ...prevState, zip_code: createForm.createZipCode };
      });
    } else {
      setCreateFormAddress(prevState => {
        return { ...prevState, zip_code: '' };
      });
    }

    if (createForm.createCity.length >= 2) {
      setCreateFormAddress(prevState => {
        return { ...prevState, city: createForm.createCity };
      });
    } else {
      setCreateFormAddress(prevState => {
        return { ...prevState, city: '' };
      });
    }

    if (createForm.createState !== '' && createForm.createState !== 'none') {
      setCreateFormAddress(prevState => {
        return { ...prevState, state: createForm.createState };
      });
    } else {
      setCreateFormAddress(prevState => {
        return { ...prevState, state: '', address_id: uuidv4() };
      });
    }
  }, [createForm]);

  async function handleOnSubmit(props) {
    const { name, event, state } = props;

    event.preventDefault();

    const { token } = user;

    switch (name) {
      case 'create':
        const createValidate = handleOnValidation({
          name: 'create',
          form: createForm
        });

        if (createValidate) {
          const status =
            createForm.createStatus === 'active' ? 'ACTIVE' : 'INACTIVE';
          const addressDefault = addresses.map((item, idx) => {
            if (idx === 0) {
              return { ...item, isDefault: true };
            }
            return { ...item, isDefault: false };
          });

          let createPayload = {
            firstName: createForm.createFirstName,
            lastName: createForm.createLastName,
            email: createForm.createEmail,
            password: createForm.createPassword,
            phone: createForm.createPhone,
            status: status,
            userType: 'customer',
            authType: 'basic',
            department: PERMISSIONS.grocery
          };

          if (addresses.length !== 0) {
            createPayload = {
              ...createPayload,
              address: addressDefault
            };
          }

          try {
            setCreateLoading(true); // createLoading
            await axios
              .post(
                `${SERVER_URL}/${AXIOS_API_CALL.createCustomer}`,
                { ...createPayload },
                {
                  withCredentials: false,
                  headers: { Authorization: `Bearer ${token}` }
                }
              )
              .then(res => {
                if (res.status === 201) {
                  setReadDataRefetch(prevState => !prevState);
                  setCreateFormEmail(false);
                  state.onToggle(false);
                  // TODO: please check forward reference
                  // state.formRef.current.reset();
                  state.onData({
                    createFirstName: '',
                    createLastName: '',
                    createEmail: '',
                    createAddress: '',
                    createZipCode: '',
                    createCity: '',
                    createState: '',
                    createPhone: '',
                    createPassword: '',
                    createStatus: 'none'
                  });

                  setCreateLoading(false);

                  // notification
                  notification.success({
                    message: res.data.message,
                    placement: 'topRight'
                  });
                }
                setTimeout(() => {
                  setCreateLoading(false);
                }, 700);
              })
              .catch(err => {
                console.error(err);
                setCreateLoading(false);
                setCreateFormEmail(true);
              });
          } catch (err) {
            setCreateFormEmail(true);
            setCreateLoading(false);
          }
        }
        break;
      case 'bulk-deactivate':
        setDeactivateBlkBtnLoader(true);
        const checkedPayload = checkedRow;

        const bulkDeactivePayload = {
          department: PERMISSIONS.grocery,
          customers: checkedPayload
        };

        try {
          let responseBulkDeactiveStatus = await axios.post(
            `${SERVER_URL}/${AXIOS_API_CALL.bulkUpdateStatus}`,
            { ...bulkDeactivePayload },
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${token}` }
            }
          );

          if (responseBulkDeactiveStatus.status === 201) {
            state.onToggle(false);
            state.formRef.current.reset();
            setCheckedRow([]);
            setReadDataRefetch(prevState => !prevState);

            // notification
            notification.success({
              message: `Successfully deactivated.`,
              placement: 'topRight'
            });
          }
          setTimeout(() => {
            setDeactivateBlkBtnLoader(false);
          }, 700);
        } catch (err) {
          console.error(err);
          setDeactivateBlkBtnLoader(false);
        }
        break;
      case 'status':
        setDeactivateBtnLoader(true);
        const statusID = singleUpdateId.id;
        const statusSinglePayload = {
          status:
            singleUpdateId.status === 'INACTIVE' ||
            singleUpdateId.status === 'WAITING_FOR_ACTIVATION'
              ? 'ACTIVE'
              : 'INACTIVE'
        };

        try {
          let responseChangeSingleStatus = await axios.post(
            `${SERVER_URL}/${AXIOS_API_CALL.updateCustomers}/${statusID}`,
            { ...statusSinglePayload },
            {
              withCredentials: false,
              headers: {
                department: PERMISSIONS.grocery,
                Authorization: `Bearer ${token}`
              }
            }
          );

          if (responseChangeSingleStatus.status === 200) {
            state.onToggle(false);
            state.onSetId({ id: '', status: '' });
            state.formRef.current.reset();
            setReadDataRefetch(prevState => !prevState);

            // notification
            notification.success({
              message: responseChangeSingleStatus.data.message.message,
              placement: 'topRight'
            });
          }
          setTimeout(() => {
            setDeactivateBtnLoader(false);
          }, 700);
        } catch (err) {
          console.error(err);
          setDeactivateBtnLoader(false);
        }
        break;
      case 'update':
        setEditBtnLoader(true);
        const updateValidate = handleOnValidation({
          name: 'update',
          form: sleep
        });

        if (updateValidate) {
          const updateID = updateId.id;

          let updatePayload = {
            firstName: sleep.updateFirstName,
            lastName: sleep.updateLastName,
            email: sleep.updateEmail,
            phone: sleep.updatePhone,
            status: sleep.updateStatus.toUpperCase(),
            userType: 'customer',
            authType: 'basic',
            department: PERMISSIONS.grocery
          };

          if (toggleNewPassword) {
            updatePayload = {
              ...updatePayload,
              password: sleep.updatePassword
            };
          }

          const addressDefault = updateAddress.map((item, idx) => {
            if (idx === 0) {
              return { ...item, isDefault: true };
            }
            return { ...item, isDefault: false };
          });

          const addressPayload = {
            address: [...addressDefault]
          };

          const responseAddress = await axios
            .post(
              `${SERVER_URL}/${AXIOS_API_CALL.createDashboardAddresses}/${updateID}`,
              { ...addressPayload },
              {
                withCredentials: false,
                headers: {
                  department: PERMISSIONS.grocery,
                  Authorization: `Bearer ${token}`
                }
              }
            )
            .then(res => {
              return res;
            })
            .catch(err => {
              return err;
            })
            .finally(setTimeout(() => {}, 700));

          if (responseAddress.status === 200) {
            try {
              let responseUpdate = await axios.post(
                `${SERVER_URL}/${AXIOS_API_CALL.updateCustomers}/${updateID}`,
                { ...updatePayload },
                {
                  withCredentials: false,
                  headers: {
                    department: PERMISSIONS.grocery,
                    Authorization: `Bearer ${token}`
                  }
                }
              );
              if (responseUpdate.status === 200) {
                state.onToggle(false);
                state.onSetId({ id: '' });
                state.formRef.current.reset();
                setReadDataRefetch(prevState => !prevState);

                // notification
                notification.success({
                  message: responseUpdate.data.message.message,
                  placement: 'topRight'
                });
              }
              setTimeout(() => {
                setEditBtnLoader(false);
              }, 700);
            } catch (err) {
              console.error(err);
              setEditBtnLoader(false);
            }
          }
        }

        break;
      case 'deleteAddress':
        setDeleteAddressBtnLoader(true);
        const updateID = updateId.id;
        const addressId = deleteAddressIds._id;
        const addressIdx = deleteAddressIds.idx;

        if (typeof addressId !== 'undefined') {
          let responseDeleteAddress = await axios.post(
            `${SERVER_URL}/${AXIOS_API_CALL.deleteDashboardAddresses}/${addressId}/${updateID}`,
            null,
            {
              withCredentials: false,
              headers: {
                department: PERMISSIONS.grocery,
                Authorization: `Bearer ${token}`
              }
            }
          );
          if (responseDeleteAddress.status === 200) {
            state.onToggle(false);
            state.formRef.current.reset();
            setReadDataRefetch(prevState => !prevState);
            const newAddress =
              updateAddress &&
              updateAddress.length > 0 &&
              updateAddress.filter((item, idx) => idx !== addressIdx);
            setUpdateAddress(newAddress);
            setTimeout(() => {
              setDeleteAddressBtnLoader(false);
            }, 700);
          }
        } else {
          state.onToggle(false);
          state.formRef.current.reset();
          setReadDataRefetch(prevState => !prevState);
          const newAddress =
            updateAddress &&
            updateAddress.length > 0 &&
            updateAddress.filter((item, idx) => idx !== addressIdx);
          setUpdateAddress(newAddress);
          setDeleteAddressBtnLoader(false);
        }

        break;
      case 'delete':
        setDeleteBtnLoader(true);
        const deleteID = deleteId.id;

        const deletePayload = {
          email: deleteId.email
        };

        try {
          let responseDelete = await axios.post(
            `${SERVER_URL}/${AXIOS_API_CALL.deleteCustomers}/${deleteID}`,
            { ...deletePayload },
            {
              withCredentials: false,
              headers: {
                department: PERMISSIONS.grocery,
                Authorization: `Bearer ${token}`
              }
            }
          );

          if (responseDelete.status === 200) {
            state.onToggle(false);
            state.onSetId({ id: '', email: '' });
            state.formRef.current.reset();
            setReadDataRefetch(prevState => !prevState);

            // notification
            notification.success({
              message: responseDelete.data.message,
              placement: 'topRight'
            });
          }
          setTimeout(() => {
            setDeleteBtnLoader(false);
          }, 500);
        } catch (err) {
          setDeleteBtnLoader(false);
          console.error(err);
        }
        break;
      default:
        console.warn('Default of: handleOnSubmit function');
    }
  }

  const createProps = {
    active: toggleCreate,
    onToggle: setToggleCreate,
    formRef: createModalFormRef,
    onData: setCreateForm
  };

  const bulkDeactivateProps = {
    active: toggleBulkDeactive,
    onToggle: setToggleBulkDeactive,
    formRef: deactiveModalFormRef
  };

  // CURRENT
  const singleDeactiveProps = {
    active: toggleSingleDeactive,
    onToggle: setToggleSingleDeactive,
    formRef: deactiveSingleModalFormRef,
    onSetId: setSingleUpdateId,
    getId: singleUpdateId
  };

  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    onSetId: setUpdateId,
    getId: updateId,
    loading: updateLoading
  };

  const deleteProps = {
    active: toggleDelete,
    onToggle: setToggleDelete,
    formRef: deleteModalFormRef,
    onSetId: setDeleteId,
    getId: deleteId
  };

  const deleteAddressProps = {
    active: toggleDeleteAddress,
    onToggle: setToggleDeleteAddress,
    formRef: deleteModalAddresFormRef,
    zIndex: 2000
  };

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData.data?.pagesCount,
    totalCount: readData.data?.totalCount
  };

  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter
  };

  // TABLE PROPS
  const tableProps = {
    handleDisabledButton: handleDisabledButton,
    handleCheckedRows: handleCheckedRows
  };

  // TABLE ACTIONS PROPS
  const tableActionsProps = {
    handleToggle: handleToggle,

    updateActions: updateProps,
    statusActions: singleDeactiveProps,
    deleteActions: deleteProps,

    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions
  };

  const createPasswordProps = {
    name: 'createPassword',
    toggle: toggleCreatePassword,
    onToggle: setToggleCreatePassword,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
    inputValue: createForm.createPassword,
    warningMessage: createFormCheckPassword
  };

  const updatePasswordProps = {
    name: 'updatePassword',
    toggle: toggleUpdatePassword,
    onToggle: setToggleUpdatePassword,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
    inputValue: sleep.updatePassword,
    warningMessage: updateFormCheckPassword
  };

  const createFormProps = {
    createProps,
    createForm,
    createFormError,
    handleOnChange,
    handleOnBlur,
    createFormEmail,
    addresses,
    handleOnRemove,
    setToggleCreateAddress,
    toggleCreateAddress,
    TogglePasswordChecker,
    createPasswordProps,
    createLoading,
    handleOnSubmit
  };

  const deleteFormProps = {
    deleteProps,
    deleteModalFormRef,
    deleteBtnLoader,
    setToggleDelete,
    handleOnSubmit
  };

  const singleStatusDeactivateProps = {
    singleDeactiveProps,
    deactiveSingleModalFormRef,
    handleOnSubmit,
    setToggleSingleDeactive,
    deactivateBtnLoader
  };

  const bulkStatusDeactivateProps = {
    bulkDeactivateProps,
    deactiveModalFormRef,
    handleOnSubmit,
    deactivateBlkBtnLoader,
    setToggleBulkDeactive
  };

  const updateFormProps = {
    updateProps,
    updateLoading,
    updateModalFormRef,
    handleOnSubmit,
    handleOnChange,
    handleOnBlur,
    sleep,
    updateFormError,
    updateAddress,
    handleOnRemoveUpdate,
    toggleUpdateAddress,
    sleepAddress,
    toggleNewPassword,
    setToggleUpdateAddress,
    handleOnSaveUpdate,
    updatePasswordProps,
    editBtnLoader,
    setToggleUpdate,
    setToggleNewPassword,
    TogglePasswordChecker
  };

  const deleteInUpdateAddressProps = {
    deleteAddressProps,
    deleteModalAddresFormRef,
    handleOnSubmit,
    deleteAddressBtnLoader,
    setToggleDeleteAddress
  };

  return (
    <>
      <h2 className="page-title">Korisnici</h2>
      <Section className="section__wrapper section__customers">
        {/* Header */}
        <header className="section__header">
          {!readDataLoading ? (
            <div className="filter">
              <span style={{ color: '#333', fontWeight: '500' }}>Prikaži:</span>
              <SelectCustom
                options={options}
                label={'Stavki'}
                limit={limitPage}
                setLimit={setLimitPage}
                setRefetch={setReadDataRefetch}
                loading={readDataLoading}
              />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}

          {!readDataLoading ? (
            <div className="search">
              <form
                className="search-form"
                onSubmit={e => handleOnSubmitSearch(e)}
                data-cy="search-roles"
              >
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img
                      src="/assets/icons/search.svg"
                      alt="Search"
                      title="Pretraži"
                    />
                  </span>
                  <input
                    value={searchForm.query || ''}
                    type="text"
                    data-cy="search-input-field"
                    className="input"
                    placeholder="Pretraži"
                    onChange={e => handleOnChangeSearch(e.target.value)}
                  />
                  <span
                    className={`icon icon-close ${
                      !!searchForm.query && searchForm.query.length > 0
                        ? 'isActive'
                        : ''
                    }`}
                    onClick={() => handleOnClearSearch()}
                  >
                    <img
                      src="/assets/icons/times.svg"
                      alt="Clear"
                      title="Clear"
                    />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Pretraži
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder
              style={{ width: '100%', maxWidth: '850px', height: '50px' }}
            />
          )}

          <div className="actions">
            {/* {getGroceryPermissions(groceryPermissions, PERMISSIONS.createCustomers) && (
              <>
                {!readDataLoading ? (
                  <button type="button" onClick={() => handleToggle({ name: 'create', state: createProps })} data-cy="create-customer-btn" className="btn btn-primary pl-4 pr-4">
                    <span className="text">Kreiraj Korisnika</span>
                  </button>
                ) : (
                  <LoadingPlaceholder style={{ width: '197px', height: '50px' }} />
                )}
              </>
            )} */}

            {getGroceryPermissions(
              groceryPermissions,
              PERMISSIONS.updateCustomers
            ) && (
              <>
                {!readDataLoading ? (
                  // <button
                  //   type="button"
                  //   onClick={() =>
                  //     handleToggle({
                  //       name: 'bulk-deactivate',
                  //       state: bulkDeactivateProps,
                  //     })
                  //   }
                  //   disabled={disabledButton ? false : true}
                  //   data-cy="bulk-deactivate-customer-btn"
                  //   className="btn btn-primary-outline pl-4 pr-4"
                  // >
                  //   <span className="text">Deaktiviraj Korisnika</span>
                  // </button>
                  <></>
                ) : (
                  <LoadingPlaceholder
                    style={{ width: '227px', height: '50px' }}
                  />
                )}
              </>
            )}
          </div>
        </header>

        {/* Main Content */}
        <Main className="section__content section__content relative min-h-table-content">
          {readDataLoading ? (
            <Loading />
          ) : (
            <Table
              data={readData.data?.Data}
              {...tableProps}
              {...tableActionsProps}
              {...tableFiltersProps}
              pagination={paginationProps}
            />
          )}
        </Main>

        {/* Create */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.createCustomers
        ) && <CreateForm {...createFormProps} />}

        {/* Bulk Status Deactive */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.updateCustomers
        ) && <BulkStatusDeactivate {...bulkStatusDeactivateProps} />}

        {/* Status Deactive */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.updateCustomers
        ) && <SingleStatusDeactivate {...singleStatusDeactivateProps} />}

        {/* Update */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.updateCustomers
        ) && <UpdateForm {...updateFormProps} />}

        {/* Delete */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.deleteCustomers
        ) && <DeleteForm {...deleteFormProps} />}

        {/* Delete in Update Address */}
        {getGroceryPermissions(
          groceryPermissions,
          PERMISSIONS.updateCustomers
        ) && <DeleteInUpdateAddress {...deleteInUpdateAddressProps} />}
      </Section>
    </>
  );
};

export default Customers;

const TogglePasswordChecker = props => {
  const {
    name,
    toggle,
    onToggle,
    onChange,
    onBlur,
    inputValue,
    warningMessage
  } = props;

  const inputRef = useRef(null);
  const [toggleVisibility, setToggleVisibility] = useState(false);

  function handleOnToggle() {
    onToggle(prevState => !prevState);
  }

  const handleClickOutside = useCallback(
    event => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        onToggle(false);
      }
    },
    [inputRef, onToggle]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [handleClickOutside]);

  return (
    <div
      ref={inputRef}
      className="form-group relative"
      onClick={() => handleOnToggle()}
    >
      <div className={`check-password ${toggle ? 'isActive' : ''}`}>
        <p
          className={`error-text ${warningMessage.passLength &&
            'success-text'}`}
        >
          Barem 8 karaktera
        </p>
        <p
          className={`error-text ${warningMessage.passUppercase &&
            'success-text'}`}
        >
          Barem jedno veliko slovo
        </p>
        <p
          className={`error-text ${warningMessage.passNumber &&
            'success-text'}`}
        >
          Barem jedan broj
        </p>
        <p
          className={`error-text ${warningMessage.passSpecialChar &&
            'success-text'}`}
        >
          Barem jedan specijalni karakter
        </p>
      </div>

      <label className="label mb-1 d-block" htmlFor={name}>
        <b className="required">Lozinka:</b>
      </label>

      <div className="form-group">
        <div className="relative password-visibility">
          <input
            name={name}
            id={name}
            value={inputValue || ''}
            onChange={onChange}
            onBlur={onBlur}
            type={`${toggleVisibility ? 'text' : 'password'}`}
            placeholder="Lozinka"
            className={`input ${false ? 'input-error' : ''}`}
          />
          <button
            className="btn"
            type="button"
            onClick={() => setToggleVisibility(prevState => !prevState)}
          >
            {toggleVisibility ? <EyeFilled /> : <EyeInvisibleFilled />}
          </button>
        </div>

        <p data-cy="" className={`error__onblur ${false ? 'error' : ''}`}>
          Ovo polje je obavezno!
        </p>
      </div>
    </div>
  );
};
