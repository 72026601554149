import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { Radio, Input, Space } from 'antd';

export const UpdateOrderStatus = ({ updateId, updateProps, handleOnSubmit, updateModalFormRef, onStatusChange, selectedStatus, setCancelationReason, updateStatusBtnLoader, setToggleUpdate, radioArray }) => {
  return (
    <Modal {...updateProps} className="sidebar__modal--center order-update-modal">
      <form name="update" ref={updateModalFormRef} onSubmit={(event) => handleOnSubmit({ event: event })} className="ant-form ant-form-horizontal">
        <h2 className="text-center mb-4">Ažuriraj Status Porudžbine:</h2>
        <h3 className="text-center mb-4">Trenutni Status: {updateId.status==="Ordered"?"Poručeno":
                      updateId.status==="In Progress"?"U Toku":
                      updateId.status==="On Delivery"?"Na Isporuci":
                      updateId.status==="Canceled"?"Otkazano":
                      updateId.status==="Delivered"?"Isporučeno":updateId.status}</h3>
        <Radio.Group onChange={onStatusChange} value={selectedStatus}>
          <Space direction="vertical">
            {radioArray.map((radioItem) => {
              return (
                <Radio value={radioItem} key={radioItem}>
                  {radioItem==="Ordered"?"Poručeno":
                      radioItem==="In Progress"?"U Toku":
                      radioItem==="On Delivery"?"Na Isporuci":
                      radioItem==="Canceled"?"Otkazano":
                      radioItem==="Delivered"?"Isporučeno":radioItem}
                  {selectedStatus === 'Canceled' && radioItem === 'Canceled' ? <Input onChange={(e) => setCancelationReason(e.target.value)} className="cancel-reason required" placeholder="Razlog otkazivanja" /> : null}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
        <div className="form-group form-group-modal mb-4">
          {!updateStatusBtnLoader ? (
            <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link">
              <span className="text">Ažuriraj</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">Ažuriranje...</span>
            </button>
          )}

          <button
            data-cy="modal-delete-cancel-btn"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setToggleUpdate(false);
            }}
          >
            <span className="text">Otkaži</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
