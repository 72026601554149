import {
  Input,
  Form,
  Button,
  notification,
  Modal,
  Select,
  DatePicker,
  Upload,
  Checkbox
} from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import Axios from 'axios';
import { SERVER_URL } from '../../../../config/index';
import { useAuth } from '../../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import moment from 'moment';

const UpdateProductForm = ({ data, categories }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [newImages, setNewImages] = useState([]);
  const [images, setImages] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const initialValues = data;
  initialValues.expireDate = moment(initialValues.expireDate);
  const [description, setDescription] = useState();

  const onFinish = async values => {
    const formData = new FormData();

    formData.append('title', values.title);
    formData.append('status', values.status);
    formData.append('isFeatured', values.isFeatured);
    // Append the createdBy field if needed
    // formData.append('createdBy', props.user.id);
    formData.append('description', description);

    const imageIds = [...images.map(item => item._id)];
    for (let i = 0; i < imageIds.length; i++) {
      formData.append(`images.${i}`, imageIds[i]);
    }

    for (let i = 0; i < newImages.length; i++) {
      formData.append(`newImages.${i}`, newImages[i]);
    }

    await Axios.put(`${SERVER_URL}/product/${data._id}`, formData, {
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'x-rapidapi-host': 'file-upload8.p.rapidapi.com',
        'x-rapidapi-key': 'your-rapidapi-key-here'
      }
    });

    notification.success({
      message: 'Artikal ažuriran.',
      placement: 'topRight'
    });

    navigate('/admin/grocery/products');
  };

  useEffect(() => {
    // if (data.category) {
    //   form.setFieldValue('category', data.category._id);
    //   handleSelectChangeCategories(data.category._id);
    // }
    // if (data.tierTwoCategory) {
    //   form.setFieldValue('tierTwoCategory', data.tierTwoCategory._id);
    //   const selectedCategory = categories.filter((category) => category._id === data.category._id);
    //   const selectedSubCategory = selectedCategory[0].tierTwoCategories.filter((category) => category._id === data.tierTwoCategory._id);
    //   setTierThreeCategories(selectedSubCategory[0].tierThreeCategories);
    // }
    // if (data.tierThreeCategory) {
    //   form.setFieldValue('tierThreeCategory', data.tierThreeCategory._id);
    // }
    if (data.images) {
      const imageList = [...data.images];

      setImages(imageList);
      const fileList = imageList.map(image => ({
        uid: image.uid,
        name: image.originalname,
        thumbUrl: image.url,
        status: 'done',
        preview: image.url,
        _id: image._id
      }));
      setFileList(fileList);
    }
  }, []);

  const handleCancel = () => setPreviewOpen(false);

  const getTextValue = value => {
    if (value !== description) {
      setDescription(value);
    }
  };

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleUploadImage = async event => {
    const { fileList: newFileList } = event;
    setFileList(newFileList);

    let newImagesList = [];
    newFileList.forEach(element => {
      if (element.originFileObj) {
        newImagesList.push(element.originFileObj);
      }
    });
    setNewImages(newImagesList);
  };
  const uploadButton = (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3807 36.3555H13.3342C4.24207 36.3555 0.357422 32.4708 0.357422 23.3787V13.3322C0.357422 4.24012 4.24207 0.355469 13.3342 0.355469H20.0318C20.7184 0.355469 21.2877 0.924771 21.2877 1.61128C21.2877 2.29779 20.7184 2.8671 20.0318 2.8671H13.3342C5.6151 2.8671 2.86905 5.61314 2.86905 13.3322V23.3787C2.86905 31.0978 5.6151 33.8438 13.3342 33.8438H23.3807C31.0997 33.8438 33.8458 31.0978 33.8458 23.3787V15.0066C33.8458 14.3201 34.4151 13.7508 35.1016 13.7508C35.7881 13.7508 36.3574 14.3201 36.3574 15.0066V23.3787C36.3574 32.4708 32.4728 36.3555 23.3807 36.3555Z"
        fill="#1A1A1A"
      />
      <path
        d="M13.3341 16.263C10.789 16.263 8.72949 14.2035 8.72949 11.6584C8.72949 9.11325 10.789 7.05371 13.3341 7.05371C15.8793 7.05371 17.9388 9.11325 17.9388 11.6584C17.9388 14.2035 15.8793 16.263 13.3341 16.263ZM13.3341 9.56534C12.1788 9.56534 11.2411 10.503 11.2411 11.6584C11.2411 12.8137 12.1788 13.7514 13.3341 13.7514C14.4895 13.7514 15.4272 12.8137 15.4272 11.6584C15.4272 10.503 14.4895 9.56534 13.3341 9.56534Z"
        fill="#1A1A1A"
      />
      <path
        d="M33.846 7.89053H24.6367C23.9502 7.89053 23.3809 7.32123 23.3809 6.63472C23.3809 5.94821 23.9502 5.37891 24.6367 5.37891H33.846C34.5325 5.37891 35.1018 5.94821 35.1018 6.63472C35.1018 7.32123 34.5325 7.89053 33.846 7.89053Z"
        fill="#1A1A1A"
      />
      <path
        d="M29.2412 12.4944C28.5547 12.4944 27.9854 11.9251 27.9854 11.2386V2.02925C27.9854 1.34274 28.5547 0.773438 29.2412 0.773438C29.9277 0.773438 30.497 1.34274 30.497 2.02925V11.2386C30.497 11.9251 29.9277 12.4944 29.2412 12.4944Z"
        fill="#1A1A1A"
      />
      <path
        d="M2.73493 31.2477C2.33307 31.2477 1.93121 31.0468 1.69679 30.6952C1.31167 30.1259 1.46237 29.3389 2.03167 28.9538L10.2866 23.4115C12.0949 22.2059 14.5898 22.3398 16.2307 23.7296L16.7833 24.2152C17.6205 24.9352 19.0438 24.9352 19.8642 24.2152L26.8298 18.2375C28.6214 16.7138 31.401 16.7138 33.1926 18.2375L35.9219 20.5817C36.441 21.0338 36.5079 21.8208 36.0559 22.3566C35.6038 22.8757 34.8168 22.9426 34.281 22.4905L31.5517 20.1464C30.7145 19.4264 29.2912 19.4264 28.4707 20.1464L21.5052 26.124C19.7303 27.6477 16.934 27.6477 15.1424 26.124L14.5898 25.6384C13.8196 24.9854 12.547 24.9184 11.6931 25.5045L3.45493 31.0468C3.22051 31.1808 2.96935 31.2477 2.73493 31.2477Z"
        fill="#1A1A1A"
      />
    </svg>
  );

  const handleRemoveImage = imageUid => {
    // console.log(imageUid);
    // Filter out the image with the given uid
    const removedImage = fileList.filter(image => image.uid === imageUid);
    // console.log('obrisana slika', removedImage);
    if (removedImage[0]._id) {
      // console.log('id', removedImage[0]._id);
      const updatedImages = images.filter(
        image => image._id !== removedImage[0]._id
      );
      setImages(updatedImages);
    } else {
      setNewImages(newImages.filter(image => image.uid !== imageUid));
    }
    // setImages(updatedImages);
    // const index = fileList.findIndex((obj) => obj.uid === imageUid);
    // const arrayImages = images;
    // arrayImages.splice(index, 1);
    // setImages(arrayImages);
  };
  return (
    <div className="title-container">
      <Form
        initialValues={initialValues}
        onFinish={values => onFinish(values)}
        layout="vertical"
        form={form}
      >
        <div className="pl-4 pr-4 d-flex items-center align-center title-blog">
          <p>
            ARTIKAL: {initialValues.numOfArticle}{' '}
            {initialValues.title.toUpperCase()}
          </p>
        </div>
        <div className="productFormContainer">
          <div className="productFormColumn">
            <div className="updateproduct-row">
              <label>Naziv:</label>
              <Form.Item name="title">
                <Input
                  type="text"
                  placeholder="title"
                  className="input-updateproductform"
                />
              </Form.Item>
            </div>

            <div className="updateproduct-row">
              <label>Početna cena:</label>
              <Form.Item name="startingPrice">
                <Input
                  disabled
                  type="number"
                  min={0}
                  placeholder="startingPrice"
                  className="input-updateproductform"
                />
              </Form.Item>
            </div>

            <div className="updateproduct-row">
              <label>Trenutna cena:</label>
              <Form.Item name="price">
                <Input
                  disabled
                  type="number"
                  min={0}
                  placeholder="price"
                  className="input-updateproductform"
                />
              </Form.Item>
            </div>

            <div className="updateproduct-row">
              <label>Pregledi:</label>
              <Form.Item name="views">
                <Input
                  disabled
                  type="number"
                  min={0}
                  placeholder="views"
                  className="input-updateproductform"
                />
              </Form.Item>
            </div>

            <div className="updateproduct-row">
              <label>Istaknut proizvod:</label>
              <Form.Item name="isFeatured">
                <Checkbox
                  checked={initialValues.isFeatured}
                  className="input-updateproductform"
                />
              </Form.Item>
            </div>

            <div className="updateproduct-row">
              <label>Kategorije:</label>
              <Form.Item>
                <Select
                  value={data.category?._id}
                  className="select-updateproductform"
                >
                  {categories &&
                    categories.length > 0 &&
                    categories.map((item, index) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>

            <div className="updateproduct-row">
              <label>Datum isteka:</label>
              <Form.Item name="expireDate">
                <DatePicker
                  className="datepicker-updateproductform"
                  format="DD.MM.YYYY - HH:mm"
                  showTime={{
                    format: 'HH:mm'
                  }}
                />
              </Form.Item>
            </div>

            <div className="updateproduct-row">
              <label>Format:</label>
              <Form.Item name="format">
                <Select className="select-updateproductform">
                  <Option value="prodaja">Prodaja</Option>
                  <Option value="licitacija">Licitacija</Option>
                  <Option value="menjanje">Menjanje</Option>
                  <Option value="poklon">Poklon</Option>
                </Select>
              </Form.Item>
            </div>

            <div className="updateproduct-row">
              <label>Status:</label>
              <Form.Item name="status">
                <Select className="select-updateproductform">
                  <Option value="polovno">Polovno</Option>
                  <Option value="novo">Novo</Option>
                </Select>
              </Form.Item>
            </div>

            <div className="updateproduct-row">
              <label>Kreirao/la:</label>
              <Form.Item>
                <Input
                  disabled
                  value={`${data.createdBy.firstName} ${data.createdBy.lastName}`}
                  type="text"
                  min={0}
                  placeholder="createdBy"
                  className="input-updateproductform"
                />
              </Form.Item>
            </div>

            <div className="updateproduct-row" style={{ maxWidth: '436px' }}>
              <label htmlFor="description">Opis:</label>
              <Form.Item name="description">
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                  onEditorChange={(newValue, editor) =>
                    getTextValue(editor.getContent())
                  }
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | ' +
                      'bold italic  | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    // selector: 'text', // Replace 'textarea' with the selector for your textarea or div element
                    toolbar: 'undo redo | bold italic | link unlink | code'
                  }}
                />
              </Form.Item>
            </div>
          </div>

          <div className="productFormColumn">
            <div className="productFormFeatureImage">
              <label>SVE SLIKE (prva slika je glavna slika):</label>
            </div>

            <div className="productFormImage">
              <Form.Item name="images">
                <Upload
                  listType="picture-card"
                  onPreview={handlePreview}
                  onChange={handleUploadImage}
                  onRemove={file => {
                    handleRemoveImage(file.uid);
                  }}
                  fileList={fileList}
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
              </Form.Item>
            </div>

            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: '100%'
                }}
                src={previewImage}
              />
            </Modal>
          </div>
        </div>
        <Button
          htmlType="submit"
          className="btn btn-primary pl-4 pr-4 d-flex items-center align-center"
        >
          AŽURIRAJ ARTIKAL
        </Button>
      </Form>
    </div>
  );
};

export default UpdateProductForm;
