import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, Button, notification } from 'antd';
import { PERMISSIONS } from '../../../utils/permissions';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import Axios from 'axios';

const Table = (props) => {
  const { user } = useAuth();
  const { token } = user;
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [activeRequests, setActiveRequests] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await Axios.get(`${SERVER_URL}/shop-window-requests`, { withCredentials: false, headers: { Authorization: `Bearer ${token}` } });

    let count = 0;
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].status === 'requested') {
        count++;
      }
    }

    setActiveRequests(count);

    setData(res.data);
  };

  return (
    <>
      <p style={{ color: 'black' }}>Broj aktivnih zahteva: {activeRequests}</p>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Zahtev od strane</th>
            <th>Status</th>
            <th>Radnje</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              // console.log(item);
              return (
                <tr key={item?._id} className={item?.status === 'Ordered' ? 'status-ordered' : ''}>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>
                        {item?.user?.firstName} {item?.user?.lastName} {item?.user?.companyName}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>{item?.status === 'requested' ? 'Na čekanju' : item?.status === 'allowed' ? 'Prihvaceno' : 'Odbijeno'}</span>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <Link to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.answerShopWindowRequest}/${item._id}`} className="link btn-actions link-details">
                        <span style={{ cursor: 'pointer' }} className="icon d-flex">
                          <img alt="EDIT" src="/assets/icons/edit-user.svg" style={{ display: 'block' }} />
                        </span>
                      </Link>
                      {/* <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleDelete(item._id);
                        }}
                        className="icon d-flex"
                      >
                        <img alt="DELETE" src="/assets/icons/delete.svg" style={{ display: 'block' }} />
                      </span> */}
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20} style={{ backgroundColor: '#fff', height: '200px', border: '0' }}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>{contentMessage.dataNotFound}</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Table;
