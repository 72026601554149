import React, { useState, useEffect } from 'react';
import { Button, notification, Drawer, Form, Input } from 'antd';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import Axios from 'axios';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Message from '../../../components/message/Message';
import { Link } from 'react-router-dom';

const Table = props => {
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const { user } = useAuth();
  const { token } = user;
  const [data, setData] = useState([]);
  const [selectedNode, setSelectedNode] = useState();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [fieldsArr, setFieldsArr] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const showDrawerEdit = selectedId => {
    let selectedAttribute;
    for (let i = 0; i < data.length; i++) {
      if (selectedId.toString() === data[i]._id.toString()) {
        selectedAttribute = data[i];
      }
    }

    formEdit.setFieldsValue({
      name: selectedAttribute.name
    });

    setSelectedNode(selectedAttribute);

    setOpenEdit(true);
  };

  const onClose = () => {
    form.resetFields();
    setFieldsArr([]);
    setOpen(false);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
    formEdit.resetFields();
    setFieldsArr([]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let res = await Axios.get(`${SERVER_URL}/attribute-types`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    setData(res.data);
  };

  const onFinish = async values => {
    await Axios.post(`${SERVER_URL}/attribute-types`, values, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    notification.success({
      message: 'Atributi su kreirani.',
      placement: 'topRight'
    });

    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  const onFinishEdit = async values => {
    await Axios.put(
      `${SERVER_URL}/attribute-type/${selectedNode._id}`,
      { ...values },
      { withCredentials: false, headers: { Authorization: `Bearer ${token}` } }
    );

    notification.success({
      message: 'Atribut je izmenjen.',
      placement: 'topRight'
    });

    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  const handleDelete = async id => {
    try {
      await Axios.delete(`${SERVER_URL}/attribute-type/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      });

      notification.success({
        message: 'Atribut uspešno izbrisan.',
        placement: 'topRight'
      });
      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {
      notification.error({
        message: error.response.data,
        placement: 'topRight'
      });
      console.log(error);
    }
  };

  const handleRemoveFromAdding = index => {
    const data = form.getFieldsValue();
    let attArr = data.attributeName;

    attArr.splice(index, 1); // 2nd parameter means remove one item only

    form.setFieldsValue({ attributeName: attArr });

    setFieldsArr(fieldsArr.slice(0, -1));
  };

  const handleRemoveFromAddingEdit = index => {
    const data = formEdit.getFieldsValue();
    let attArr = data.attributeName;

    attArr.splice(index, 1); // 2nd parameter means remove one item only

    form.setFieldsValue({ attributeName: attArr });

    setFieldsArr(fieldsArr.slice(0, -1));
  };

  const handleRemoveAttributeItem = async id => {
    try {
      await Axios.delete(
        `${SERVER_URL}/attribute-type-item/${selectedNode._id}/${id}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      notification.success({
        message: 'Vrednost atributa je izbrisana.',
        placement: 'topRight'
      });

      setTimeout(() => {
        window.location.reload();
      }, 200);
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.response.data.message,
        placement: 'topRight'
      });
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          margin: '0px 0 20px 0'
        }}
      >
        <Button
          style={{ width: '33%' }}
          onClick={() => {
            setIsNew(true);
            showDrawer();
          }}
          className="btn btn-primary"
        >
          <span>Kreiraj Atribut</span>
        </Button>
      </div>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Tip</th>
            <th>Atributi</th>
            <th>Radnje</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data.length > 0 ? (
            data?.map((item, idx) => {
              return (
                <tr key={item._id}>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <span>{item.name}</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <span></span>
                      <div style={{ display: 'flex' }}>
                        {item.attributes.map((itemtwo, index) => {
                          return (
                            <p key={itemtwo._id}>
                              {`${itemtwo.name}`}
                              {index + 1 < item.attributes.length ? ', ' : ''}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex gap-x-2 align-center">
                      <Link
                        onClick={() => {
                          showDrawerEdit(item._id);
                        }}
                        className="link btn-actions link-details"
                      >
                        <span
                          style={{ cursor: 'pointer' }}
                          className="icon d-flex"
                        >
                          <img
                            alt="EDIT"
                            src="/assets/icons/edit-user.svg"
                            style={{ display: 'block' }}
                          />
                        </span>
                      </Link>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleDelete(item._id);
                        }}
                        className="icon d-flex"
                      >
                        <img
                          alt="DELETE"
                          src="/assets/icons/delete.svg"
                          style={{ display: 'block' }}
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={20}
                style={{
                  backgroundColor: '#fff',
                  height: '200px',
                  border: '0'
                }}
              >
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>Data not found.</h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Drawer
        style={{ color: 'black' }}
        title={`${isNew ? 'Kreiraj' : 'Izmeni'} Atribut`}
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Form
          onFinish={values => onFinish(values)}
          layout="vertical"
          form={form}
        >
          <Form.Item name="name" label="Naziv">
            <Input
              type="text"
              placeholder="Naziv Tipa"
              className="input-blog-page"
            />
          </Form.Item>
          <Button
            onClick={() => {
              setFieldsArr(() => [...fieldsArr, {}]);
            }}
            style={{
              borderRadius: '90px',
              height: '45px',
              width: '45px',
              margin: '0 0 20px 0'
            }}
            type="primary"
          >
            <PlusCircleOutlined />
          </Button>
          {fieldsArr &&
            fieldsArr.length > 0 &&
            fieldsArr.map((item, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item name={['attributeName', index]} label="Atribut">
                    <Input
                      type="text"
                      placeholder="Atribut"
                      className="input-blog-page"
                    />
                  </Form.Item>
                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{
                      borderRadius: '90px',
                      height: '45px',
                      width: '45px',
                      margin: '0 0 0 15px'
                    }}
                    danger
                    onClick={() => handleRemoveFromAdding(index)}
                  />
                </div>
              );
            })}

          <Button
            htmlType="submit"
            className="btn btn-primary pl-4 pr-4 d-flex items-center align-center"
          >
            {isNew ? 'Kreiraj' : 'Izmeni'} Atribut
          </Button>
        </Form>
      </Drawer>

      <Drawer
        style={{ color: 'black' }}
        title={`Izmena Atributa`}
        placement="right"
        onClose={onCloseEdit}
        open={openEdit}
      >
        <Form
          onFinish={values => onFinishEdit(values)}
          layout="vertical"
          form={formEdit}
        >
          <Form.Item name="name" label="Naziv">
            <Input
              type="text"
              placeholder="Naziv tipa"
              className="input-blog-page"
            />
          </Form.Item>

          <Button
            onClick={() => {
              setFieldsArr(() => [...fieldsArr, {}]);
            }}
            style={{
              borderRadius: '90px',
              height: '45px',
              width: '45px',
              margin: '0 0 20px 0'
            }}
            type="primary"
          >
            <PlusCircleOutlined />
          </Button>

          {fieldsArr &&
            fieldsArr.length > 0 &&
            fieldsArr.map((item, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item name={['attributeName', index]} label="Atribut">
                    <Input
                      type="text"
                      placeholder="Atribut"
                      className="input-blog-page"
                    />
                  </Form.Item>
                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{
                      borderRadius: '90px',
                      height: '45px',
                      width: '45px',
                      margin: '0 0 0 15px'
                    }}
                    danger
                    onClick={() => handleRemoveFromAddingEdit(index)}
                  />
                </div>
              );
            })}

          {selectedNode &&
            selectedNode.attributes.length > 0 &&
            selectedNode.attributes.map((item, index) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item label="Atribut">
                    <Input
                      disabled
                      value={item.name}
                      type="text"
                      className="input-blog-page"
                    />
                  </Form.Item>
                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    style={{
                      borderRadius: '90px',
                      height: '45px',
                      width: '45px',
                      margin: '0 0 0 15px'
                    }}
                    danger
                    onClick={() => handleRemoveAttributeItem(item._id)}
                  />
                </div>
              );
            })}

          <Button
            htmlType="submit"
            className="btn btn-primary pl-4 pr-4 d-flex items-center align-center"
          >
            Izmeni Atribut
          </Button>
        </Form>
      </Drawer>
    </div>
  );
};

export default Table;
