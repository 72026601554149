import { Input, Form, Button, notification, Select } from 'antd';

import { useAuth } from '../../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useRef, useMemo } from 'react';
import Axios from 'axios';
import { SERVER_URL } from '../../../../config';

const AnswerRequestForm = props => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = user;
  const [form] = Form.useForm();
  const [answer, setAnswer] = useState();
  const editor = useRef(null);
  const [data, setData] = useState(props.data);

  const handleChangeStatus = e => {
    setData({ ...data, status: e });
  };

  const onFinish = async values => {
    await Axios.put(`${SERVER_URL}/shop-window-request/${data._id}`, data, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${token}` }
    });

    notification.success({
      message: 'Zahtev je ažuriran.',
      placement: 'topRight'
    });

    navigate('/admin/grocery/shopwindow-requests');
  };

  const userAddress = data?.user?.address?.filter(
    address => address.isDefault
  )[0];

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={values => onFinish(values)}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
          ODGOVORI NA ZAHTEV
        </h2>

        <div className="faq-row">
          <label>Korisnik: </label>
          <Form.Item>
            <Input
              disabled
              value={`${data?.user?.firstName} ${data?.user?.lastName} ${data?.user?.companyName}`}
              type="text"
            />
          </Form.Item>
        </div>

        <div className="faq-row">
          <label>Email: </label>
          <Form.Item>
            <Input disabled value={`${data?.user?.email}`} type="text" />
          </Form.Item>
        </div>

        <div className="faq-row">
          <label>Phone: </label>
          <Form.Item>
            <Input disabled value={`${data?.user?.phone[0]}`} type="text" />
          </Form.Item>
        </div>

        <div className="faq-row">
          <label>Adresa: </label>
          <Form.Item>
            <Input
              disabled
              value={`${userAddress?.place?.name} ( ${userAddress?.place?.zip} ), ${userAddress?.street?.name} ${userAddress?.streetNumber}`}
              type="text"
            />
          </Form.Item>
        </div>

        <div className="faq-row">
          <label>Status: </label>
          <Form.Item>
            <Select onChange={e => handleChangeStatus(e)} value={data?.status}>
              <Option value="requested">Na čekanju</Option>
              <Option value="declined">Odbijeno</Option>
              <Option value="allowed">Prihvaćeno</Option>
            </Select>
          </Form.Item>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
            gap: '10px'
          }}
        >
          <Button className="btn btn-primary" htmlType="submit">
            Sačuvaj
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AnswerRequestForm;
