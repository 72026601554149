import React from 'react';
import Pagination from '../../../../components/pagination/Pagination';
import Message from '../../../../components/message/Message';
import { contentMessage } from '../../../../helpers/contentMessage';
import axios from 'axios';
import { SERVER_URL } from '../../../../config';
import { AXIOS_API_CALL } from '../../../../utils/endpoint';
import { useParams } from 'react-router-dom';

const Table = ({ data, pagination, token }) => {
  const formatDate = date => new Date(date).toLocaleString('sr');
  const { userId } = useParams();

  const handleDownload = async itemId => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/${AXIOS_API_CALL.search}/create-file/${userId}`,
        { id: itemId },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      const downloadLink = response?.data?.message?.downloadPath;

      const link = document.createElement('a');
      link.href = downloadLink;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            <th>Datum</th>
            <th>Broj pronađenih rezultata</th>
            <th>Sadržaj pretrage</th>
            <th>Radnja</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data?.length > 0 ? (
            data?.map(item => {
              return (
                <tr key={item?._id}>
                  <td> {formatDate(item?.dateOfSearch)}</td>
                  <td> {item?.numOfSearchResult}</td>
                  <td>{item?.searchWord}</td>
                  <td className="y-top">
                    <button
                      className="btn btn-actions"
                      type="button"
                      data-cy="download-user-search-log"
                      onClick={() => handleDownload(item._id)}
                    >
                      <span className="icon">
                        <img alt="Delete" src="/assets/icons/download.svg" />
                      </span>
                      <span className="text">
                        <strong> Preuzmi</strong>
                      </span>
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={20}>
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>
                    {contentMessage?.dataNotFound}
                  </h2>
                </Message>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {data?.length > 0 && <Pagination {...pagination} />}
    </>
  );
};

export default Table;
