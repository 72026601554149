import { Input, Form, Button, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../../config/index';
import { useAuth } from '../../../context/useAuth';
import { useNavigate, Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const DeliveryForm = ({ data, isNew }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [description, setDescription] = useState();
  const { token } = user;
  const [form] = Form.useForm();
  const initialValues = data && data.length > 0 ? { ...data[0] } : null;

  const getTextValue = value => {
    if (value !== description) {
      setDescription(value);
    }
  };

  const onFinish = async values => {
    values.desc = description;
    if (isNew) {
      values.type = 'delivery';
      values.url = 'dostava';

      await Axios.post(`${SERVER_URL}/single-pages`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      });
    } else {
      await Axios.put(
        `${SERVER_URL}/single-page/${initialValues._id}`,
        values,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        }
      );
    }
    notification.success({
      message: 'Delivery is updated.',
      placement: 'topRight'
    });

    navigate('/admin/grocery');
  };

  return (
    <div>
      <Form
        initialValues={initialValues}
        onFinish={values => onFinish(values)}
        layout="vertical"
        form={form}
      >
        <div className="singlepage-title">
          <p>{isNew ? 'KREIRAJ' : 'AŽURIRAJ'} STRANICU DOSTAVE</p>
        </div>

        <div className="singlepage-row">
          <label>Naslov:</label>
          <Form.Item name="title">
            <Input
              type="text"
              placeholder="title"
              className="input-blog-page"
            />
          </Form.Item>
        </div>

        <div className="singlepage-row">
          <label>Meta Naslov:</label>
          <Form.Item name="metaTitle">
            <Input
              type="text"
              placeholder="metaTitle"
              className="input-blog-page"
            />
          </Form.Item>
        </div>

        <div className="singlepage-row">
          <label>Meta Opis:</label>
          <Form.Item name="metaDesc">
            <Input
              type="text"
              placeholder="metaDesc"
              className="input-blog-page"
            />
          </Form.Item>
        </div>

        <div className="singlepage-row">
          <label>Sadržaj:</label>
          <Form.Item name="desc">
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
              onEditorChange={(newValue, editor) =>
                getTextValue(editor.getContent())
              }
              init={{
                height: 600,
                menubar: true,
                plugins: 'table'
              }}
            />
          </Form.Item>
        </div>

        <Button
          htmlType="submit"
          className="btn btn-primary pl-4 pr-4 d-flex items-center align-center addnewbutton"
        >
          {isNew ? 'KREIRAJ' : 'AŽURIRAJ'} STRANICU
        </Button>
      </Form>
    </div>
  );
};

export default DeliveryForm;
