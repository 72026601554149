import React, { useEffect, useState } from 'react';
import { ProductProvider, useProducts } from '../../../../context/products';
import { ProductBreadcrumb } from './product-breadcrumb/ProductBreadcrumb';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { useAuth } from '../../../../context/useAuth';
import { SERVER_URL } from '../../../../config/index';
import UpdateProductForm from './UpdateProductForm';

const UpdateProduct = () => {
  const { user } = useAuth();
  const { token } = user;

  const [data, setData] = useState();
  const [categories, setCategories] = useState();
  const [isDataFetched, setIsDataFetched] = useState(false);

  const lastIndex = window.location.pathname.lastIndexOf('/');
  const productId = window.location.pathname.slice(lastIndex + 1);

  useEffect(() => {
    fetchStartData();
  }, [productId]);

  const fetchStartData = async () => {
    try {
      const product = await Axios.get(`${SERVER_URL}/product/${productId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      const categoriesArr = await Axios.get(`${SERVER_URL}/categories-get-all`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      setData({ ...product.data.item, images: [{ ...product.data.item.featureImage }, ...product.data.item.images] });
      setCategories(categoriesArr.data);
      setIsDataFetched(true);
    } catch (error) {
      console.error('Error fetching the data:', error);
    }
  };

  return (
    // <ProductProvider>
    <div>
      <ProductBreadcrumb />
      <div className="title-container">{data && isDataFetched && <UpdateProductForm isNew={false} data={data} categories={categories} />}</div>;
    </div>
    // </ProductProvider>
  );
};

export default UpdateProduct;
