import React from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';
import SidebarModal from '../../../../components/sidebarModal/SidebarModal';
// import { stateUS } from '../../../../helpers/state';

const { Option } = Select;

export const CreateForm = ({ createProps, createModalFormRef, handleOnFinish, createFormFields, roles, createBtnLoader, setToggleCreate }) => {
  return (
    <SidebarModal {...createProps} className="sidebar__modal--right" title="Kreiraj Zaposlenog">
      <Form name="create" ref={createModalFormRef} wrapperCol={{ span: 24 }} initialValues={{ remember: false }} onFinish={(data) => handleOnFinish({ name: 'create', data: data })} fields={createFormFields}>
        <Form.Item name="firstName" label="Ime:" rules={[{ required: true, message: 'Molimo vas da unesete ime!' }]}>
          <Input placeholder="Ime:" />
        </Form.Item>
        <Form.Item name="lastName" label="Prezime:" rules={[{ required: true, message: 'Molimo vas da unesete prezime!' }]}>
          <Input placeholder="Prezime:" />
        </Form.Item>
        <Form.Item name="email" label="Email:" rules={[{ required: true, message: 'Molimo vas da unesete email!' }]}>
          <Input placeholder="Email:" />
        </Form.Item>
        {/* <Form.Item name="state" label="Odaberi državu:" rules={[{ required: false, message: 'Molimo vas da unesete  državu!' }]}>
          <Select>
            <Option key={''}>Odaberi</Option>

            {stateUS.map((item) => {
              return (
                <Option key={item.name} value={item.abbreviation}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="city" label="Grad:" rules={[{ required: false, message: 'Molimo vas da unesete Grad!' }]}>
          <Input placeholder="Grad:" />
        </Form.Item>
        <Form.Item name="address" label="Adresa:" rules={[{ required: false, message: 'Molimo vas da unesete Adresu!' }]}>
          <Input placeholder="Adresa:" />
        </Form.Item>
        <Form.Item name="zipCode" label="Zip Kod:" rules={[{ required: false, message: 'Molimo vas da unesete zip Kod!' }]}>
          <Input placeholder="Zip Kod:" />
        </Form.Item> */}
        <Form.Item name="phone" label="Telefon:" rules={[{ required: false, message: 'Molimo vas da unesete broj telefona!' }]}>
          <Input placeholder="Telefon:" />
        </Form.Item>
        <Form.Item name="password" label="Lozinka:" rules={[{ required: true, message: 'Molimo vas da unesete lozinku!' }]}>
          <Input.Password placeholder="Lozinka:" />
        </Form.Item>
        <Form.Item name="role" label="Odaberi uloge:" rules={[{ required: true, message: 'Molimo vas da unesete uloge!' }]}>
          <Select>
            <Option key={''}>Odaberi</Option>

            {roles.map((item) => {
              return (
                <Option key={item.name} value={item._id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="createUserId" style={{ display: 'none' }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="createUserType" style={{ display: 'none' }}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8 }} className="mt-4 mb-0">
          <Row gutter={16}>
            <Col span={12}>
              <Button type="button" htmlType="button" className="btn btn-primary-link btn-block" onClick={() => setToggleCreate(false)}>
                <span>Otkaži</span>
              </Button>
            </Col>
            <Col span={12}>
              {!createBtnLoader ? (
                <Button type="submit" htmlType="submit" className="btn btn-primary btn-block">
                  <span>Dodaj Zaposlenog</span>
                </Button>
              ) : (
                <Button type="button" className="btn btn-primary-outline btn-block">
                  <span>Dodavanje...</span>
                </Button>
              )}
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </SidebarModal>
  );
};
