import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../../context/useAuth';
import { SERVER_URL } from '../../../config/index';
import Table from './Table';
import Loading from '../../../components/loading/Loading';
import { Main, Section } from '../../../components/content';
import { AXIOS_API_CALL } from '../../../utils/endpoint';

const SearchPage = () => {
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const limitPage = 10;

  useEffect(() => {
    const getTableData = async () => {
      const { token } = user;
      setLoading(true);
      try {
        const response = await axios.get(
          `${SERVER_URL}/${AXIOS_API_CALL.search}/${currentPage}/${limitPage}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        setList(response?.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getTableData();
  }, [user, currentPage, limitPage]);

  // Table Pagination
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pageCount: list?.totalPages,
    totalCount: list?.totalSearchLogs
  };

  return (
    <>
      <h2 className="page-title">Pretrage</h2>
      <Section className="section section__wrapper section__employees">
        <Main className="section__content relative min-h-table-content">
          {loading ? (
            <Loading />
          ) : (
            <Table data={list.searchLogs} pagination={paginationProps} token={user.token}/>
          )}
        </Main>
      </Section>
    </>
  );
};

export default SearchPage;
