import React from 'react';
import Message from '../../../components/message/Message';
import { contentMessage } from '../../../helpers/contentMessage';

const Table = props => {
  const { data } = props;
  let subtotal = 0;
  console.log(data);

  // const isProductImage = (item) => {
  //   const isCheckFeaturedImage = item && item?.hasOwnProperty('featureImage');

  //   if (isCheckFeaturedImage) {
  //     const featureName = item && item?.featureImage.originalname;
  //     const featureImage = item && item?.featureImage && item?.featureImage.url;

  //     return <img src={featureImage} alt={featureName} />;
  //   }
  //   return <div>1</div>;
  // };

  return (
    <>
      <table className="table">
        <thead className="thead">
          <tr>
            {/* <th>Artikli</th>
            <th>Količina</th> */}
            <th>Isporuka</th>
            <th>Adresa Porudžbine</th>
            <th>Plaćanje</th>
            <th>Status</th>
            <th>Cena</th>
          </tr>
        </thead>
        <tbody className="tbody">
          {data && data?.salesman_id ? (
            // subtotal = subtotal + parseFloat(item?.totalPrice);
            // TODO !!!!!!!!!!
            <tr key={data?._id}>

              {/* <td>
                <div className="product-item">
                  <img src="/assets/images/fake-image.png" alt={item?.title} />
                  {isProductImage(item)}
                  <span>{data?.title}</span>
                </div>
              </td>
              <td>1</td> */}
              <td>{data?.status}</td>
              <td>{data?.address}</td>
              <td>
                {data?.paymentType === 'Payment On Delivery'
                  ? 'Plaćanje pouzećem'
                  : data?.paymentType}
              </td>
              <td>
                {data?.status === 'Ordered'
                  ? 'Poručeno'
                  : data?.status === 'In Progress'
                  ? 'U Toku'
                  : data?.status === 'On Delivery'
                  ? 'Na Isporuci'
                  : data?.status === 'Delivered'
                  ? 'Isporučeno'
                  : data?.status === 'Canceled'
                  ? 'Otkazano'
                  : data?.status}
              </td>
              <td>{parseFloat(data?.totalPrice).toFixed(2)} RSD</td>
            </tr>
          ) : (
            <tr>
              <td
                colSpan={20}
                style={{
                  backgroundColor: '#fff',
                  height: '200px',
                  border: '0'
                }}
              >
                <Message className="content-message">
                  <h2 style={{ color: '#666' }}>
                    {contentMessage.dataNotFound}
                  </h2>
                </Message>
              </td>
            </tr>
          )}
          {data && data?.order?.length > 0 ? (
            <tr className="subtotal-row">
              <td
                style={{ textAlign: 'right', paddingRight: '30px' }}
                colSpan={7}
              >
                Subtotal: {subtotal.toFixed(2)} RSD
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  );
};

export default Table;
